import { FormModel } from "../../../utils/FormModel";
import { DataPoint, TimeSeriesElement } from "./DataPoint";

export class Upload extends FormModel {
  public id: string = "";
  public sid: string = "";
  public dateUploaded: Date = new Date();
  public dateReceived: Date = new Date();
  public dataPoints: DataPoint[] = [];
}

export class TimeSeriesUpload extends FormModel {
  public id: string = "";
  public sid: string = "";
  public averagedStartDate: Date = new Date();
  public averagedEndDate: Date = new Date();
  public dateReceived: Date = new Date();
  public dataPoints: TimeSeriesElement[] = [];
}
