import { Typography } from "@mui/material";
import React, {
  Fragment,
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { AppStore } from "../../../../AppStore";
import { DrawerItem } from "../../../../components/drawer/components/DrawerItem";
import { DrawerSection } from "../../../../components/drawer/components/DrawerSection";
import Popup from "../../../../components/popup/Popup";
import { ObjectId } from "../../../../helpers/objectid";
import { FormatStringToDate } from "../../../../helpers/time";
import { NotificationContext } from "../../../../providers/Notifications";
import { RouterContext } from "../../../../providers/RouterProvider";
import { CompanyService } from "../../../../services/company/CompanyService";
import { Company } from "../../../../services/company/models/Company";
import { System } from "../../../../services/system/models/System";
import { AddSystemToProjectForm } from "../../../shared/components/AddSystemToProjectForm";
import { SmallStatusIndicator } from "../../../shared/components/SystemStatus";
import SystemGrid from "../../../shared/layouts/Grid";

interface Props {}

const Body: FunctionComponent<Props> = (props) => {
  const [companyState, companyActions] = AppStore.companies.use();
  const [, gridActions] = AppStore.grid.use();
  const [drawerState, drawerActions] = AppStore.drawer.use();
  const [state, actions] = AppStore.systems.use();
  const [, deviceActions] = AppStore.devices.use();
  const [account, accountActions] = AppStore.account.use();
  const [hasCompany, setHasCompany] = useState(true);
  const [downloading, setDownloading] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const systemReference = useRef<System>();
  const router = useContext(RouterContext);
  const { addNotifications, clearNotifications } =
    useContext(NotificationContext);

  const handleSelected = (system: any) => {
    actions.setHighlighted(system);
  };

  const handleDownload = () => {
    setDownloading(true);
  };

  const handleDismissDownloading = () => {
    setDownloading(false);
  };

  const showNotifications = () => {
    if (state.highlighted)
      addNotifications(
        state.highlighted.alerts.map((a) => a.value),
        "warning"
      );

    setShowWarning(false);
    setTimeout(() => setShowWarning(true), 5200);
  };

  const handleUpdateSystemNickname = (update) => {
    if (!companyState.highlighted || !systemReference.current) return;

    CompanyService.updateSystemNickname(
      companyState.highlighted.id,
      systemReference.current.id,
      update
    )
      .then(() => companyActions.updateCollection())
      .catch(console.log);
  };

  const updateDrawer = (company: Company) => {
    actions.getCachedParentCollection(company).then((systems) => {
      const sections: DrawerSection[] = [];
      let highlighted: DrawerItem | undefined;

      company.projects.forEach((project, index) => {
        const section = new DrawerSection();
        const items: DrawerItem[] = [];

        section.index = index;
        section.name = project.name;
        section.utility = <AddSystemToProjectForm project={project.name} />;

        project.systems.forEach((reference) => {
          const system = systems.find((s) => s.sid == reference.sid);

          if (!system) return;

          const utilities: JSX.Element[] = [];

          utilities.push(<SmallStatusIndicator system={system} />);

          const drawerItem = new DrawerItem();

          drawerItem.primary = reference.nickname
            ? reference.nickname
            : system.sid;
          drawerItem.secondary = FormatStringToDate(system.lastUploaded);
          drawerItem.model = system;
          drawerItem.children = utilities;
          drawerItem.onClick = handleSelected;

          items.push(drawerItem);

          if (state.highlighted && system.id == state.highlighted.id)
            highlighted = drawerItem;

          if (
            router.metadata["system"] &&
            router.metadata["system"].toLowerCase() == system.sid.toLowerCase()
          )
            highlighted = drawerItem;

          if (!highlighted && !state.highlighted) {
            highlighted = drawerItem;
          }
        });

        section.items = items;

        sections.push(section);
      });

      if (highlighted) actions.setHighlighted(highlighted.model as System);

      drawerActions.setHighlighted(highlighted);
      drawerActions.setSections(sections);
      drawerActions.setExpandedSection(undefined);

      router.clearState();
    });
  };

  useEffect(() => {
    clearNotifications();
    setShowWarning(false);

    gridActions.clear();
    gridActions.initGrid(SystemGrid);
    gridActions.setOnEditPrimaryTitle(handleUpdateSystemNickname);
    // gridActions.setUtilities([
    //   <DownloadUtility key={0} onDownload={handleDownload} />,
    // ]);
    drawerActions.setTitle("system");

    if (!account.user || ObjectId.isDefault(account.user?.companyId))
      setHasCompany(false);
    else companyActions.getCachedHighlighted(account.user?.companyId);
  }, []);

  useEffect(() => {
    if (companyState.highlighted)
      updateDrawer(companyState.highlighted);
  }, [companyState.highlighted, state.parentCollection]);

  useEffect(() => {
    if (!state.highlighted || !companyState.highlighted) return;

    systemReference.current = state.highlighted;

    deviceActions.updateParentCollection(state.highlighted);

    // if (state.highlighted.alerts.length > 0) setShowWarning(true);

    let nickname = state.highlighted.sid;

    companyState.highlighted.projects.find((p) => {
      const reference = p.systems.find((s) => s.sid == state.highlighted?.sid);

      if (reference) nickname = reference.nickname;
    });

    gridActions.setSecondaryTitle(companyState.highlighted?.name);
    gridActions.setPrimaryTitle(nickname);
    gridActions.setLoading(false);
  }, [state.highlighted, companyState.highlighted, state.parentCollection]);

  return (
    <Fragment>
      <Popup visible={!hasCompany}>
        <Typography align="center">
          You are not assigned to a company.
        </Typography>
        <Typography align="center">
          Please contact support at techsupport@2btech.io
        </Typography>
        <Typography align="center">to be assigned to a company.</Typography>
      </Popup>
      {/* <DownloadReportModal
        visible={downloading}
        onDismiss={handleDismissDownloading}
      /> */}
      {/* {showWarning && (
        <div onClick={showNotifications} className={styles.warningPopup}>
          <img
            style={{ marginBottom: "5px" }}
            src={"./icons/alert.png"}
            width={40}
            height={40}
          />
        </div>
      )} */}
    </Fragment>
  );
};

export default Body;
