import { User } from "../user/models/User";
import { BaseStore } from "../../utils/BaseStore";
import { Device } from "../device/models/Device";
import { IModelActions, IModelState, ModelStore } from "../../utils/ModelStore";
import { Correlation } from "./Models/Correlation";
import { AnalysisService } from "./AnalysisService";

export interface AnalysisState extends IModelState<Correlation> {
  graphedDevices: Device[];
  timeSeries: [Date, Date, number];
  currentGraph: Correlation | undefined;
}

export interface AnalysisActions extends IModelActions<Correlation> {
  setCurrentGraph(graph: Correlation | undefined): unknown;
  setGraphedDevices: (devices: Device[]) => void;
  setTimeSeries: (timeSeries: [Date, Date, number]) => void;
}

export class AnalysisStore
  extends ModelStore<Correlation, AnalysisState, AnalysisActions>
  implements AnalysisActions
{
  protected prototype = AnalysisStore.prototype;
  protected initialState = {
    collection: [],
    parentCollection: [],
    highlighted: undefined,
    highlightedParent: undefined,
    lastUpdatedCollection: undefined,
    lastUpdatedHighlighted: undefined,
    lastUpdatedParentCollection: undefined,
    cachedCapacity: 30,
    graphedDevices: [],
    timeSeries: <[Date, Date, number]>{},
    currentGraph: undefined,
    api: AnalysisService,
  };

  constructor() {
    super();
  }

  public setGraphedDevices(devices: Device[]) {
    this.setState({
      ...this.state,
      graphedDevices: devices,
    });
  }

  public setTimeSeries(timeSeries: [Date, Date, number]) {
    this.setState({
      ...this.state,
      timeSeries: timeSeries,
    });
  }

  public setCurrentGraph(graph: Correlation | undefined) {
    this.setState({
      ...this.state,
      currentGraph: graph,
      timeSeries: graph
        ? [
            new Date(graph.start),
            graph.toPresent ? new Date() : new Date(graph.end),
            graph.average,
          ]
        : this.state.timeSeries,
    });
  }
}
