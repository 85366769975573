import React, { FunctionComponent, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AppStore } from "../../../../AppStore";
import { RouterContext } from "../../../../providers/RouterProvider";
import styles from "../styles/MapDashboard.css";
import Body from "./Body";

interface Props {}

const MapDashboard: FunctionComponent<Props> = () => {
  

  return (
    <div className={styles.bodyContainer}>
      <Body />
    </div>
  );
};

export default MapDashboard;
