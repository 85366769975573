import { Button } from "@mui/material";
import React, { Fragment, FunctionComponent, useState } from "react";
import { AppStore } from "../../../../AppStore";
import { Form } from "../../../../components/form/FormElement";
import styles from "../styles/Components.css";
import {
  User,
  UserRole,
  UserRoleRender,
} from "../../../../services/user/models/User";
import { UserService } from "../../../../services/user/UserService";
import Loader from "react-spinners/BarLoader";
import Popup from "../../../../components/popup/Popup";
import { GridCardUtilityIcon } from "../../../../components/gridManager/components/GridCard";

interface Props {
  onSubmit?: () => void;
}

export const AddUserForm: FunctionComponent<Props> = (props) => {
  const [state, actions] = AppStore.users.use();
  const [companies, companyActions] = AppStore.companies.use();
  const [model] = useState(new User());
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

const handleCloseForm = () => {
    setShow(false);
  };

  const handleOpenForm = () => {
    setShow(true)
  }

  const handleSubmit = (user) => {

    setLoading(true);
    user.companyId = companies.highlighted?.id

    return UserService.addOne(user)
      .then(() => actions.updateParentCollection(companies.highlighted))
      .then(props.onSubmit)
      .catch(console.log)
      .finally(() => {
        setLoading(false)
        handleCloseForm();
      });
  };

  const form = () => (
    <Form model={model} onSubmit={handleSubmit}>
      <Form.Item field="name">
        <Form.Input fullWidth placeholder="John Doe" label="Name" />
      </Form.Item>
      <Form.Item field="email">
        <Form.Input fullWidth placeholder="john@doe.com" label="Email" />
      </Form.Item>
      <Form.Item field="role">
        <Form.Dropdown
          options={Object.values([UserRole.User, UserRole.CompanyAdmin])}
          valueModifier={(value) => UserRoleRender[value]}
          defaultValue={UserRole.User}
          label="Role"
          placeholder="John Doe"
        />
      </Form.Item>
      <Form.Item field="submit">
        <div className={styles.submitButtonContainer}>
          {loading ? <Loader /> : <Button variant="contained">Submit</Button>}
        </div>
      </Form.Item>
    </Form>
  );

  return (
    <Fragment>
      <Popup
        visible={show}
        onDismiss={handleCloseForm}
        title={`Add User`}
      >
        {form()}
      </Popup>
      <GridCardUtilityIcon
        src="./icons/plus.png"
        width={20}
        height={20}
        onClick={handleOpenForm}
      />
    </Fragment>
  );
};
