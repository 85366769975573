import React from "react";
import { Grid } from "../../../../components/gridManager/components/Grid";
import { GridCard } from "../../../../components/gridManager/components/GridCard";
import Graph from "../components/Graph";
import { GraphNavbar } from "../components/GraphNavbar";
import { PDFExample } from "../components/PDFExample";


const AnalysisGrid = new Grid();
AnalysisGrid.id = "Analysis"
AnalysisGrid.components = [
  <GraphNavbar />,
  <Graph />,
  // <PDFExample/>,
  // <GridCard icon="./icons/antena.png" title="placeholder" />
];
AnalysisGrid.layout = [
  {
    x: 0,
    y: 0,
    w: 12,
    h: 2.5,
    i: "0",
    static: true,
  },
  {
    x: 0,
    y: 2.5,
    w: 12,
    h: 20,
    i: "1",
    static: true,
  }
  // {
  //   x: 0,
  //   y: 10,
  //   w: 12,
  //   h: 8,
  //   i: "2",
  //   static: true,
  // },
  // {
  //   x: 0,
  //   y: 18,
  //   w: 12,
  //   h: 14,
  //   i: "3",
  //   static: true,
  // }
];

export default AnalysisGrid;