import React, { FunctionComponent, ComponentProps, useEffect } from "react";
import { FormItemProps } from "../FormElement";
import { Input, InputProps, TextField, Typography } from "@mui/material";
import styles from "../styles/Components.css"

interface Props
  extends Omit<InputProps, "value" | "defaultValue" | "size">,
    FormItemProps<string> {
  size?: "small" | "medium" | undefined;
  elevated?: number;
  borderless?: boolean;
  disabled?: boolean;
}

const FormDateSelect: FunctionComponent<Props> = (props) => {
  const size = props.size ?? undefined;
  const { label, errorText, helperText, style, onBlur, onFocus, onUpdate, _controlled, ...rest } =
    props;
  const [isFocused, setFocused] = React.useState(false);
  const [value, setValue] = React.useState("");
  const textFieldRef = React.useRef<any>(null);

  React.useEffect(() => {
    handleUpdate(props.defaultValue ?? "");
    setFocused(!!props.defaultValue);
  }, [props.defaultValue]);

  const handleChange = (event) => {
    const text = event.target.value

    if (text) {
      handleUpdate(text)
    }
  };

  const handleUpdate = (value) => {
    props.onUpdate?.(value);
    setValue(value);
  }

  return (
    <div>
      <TextField
            label={label}
            type="date"
            size={size}
            // {...rest}
            // defaultValue={props.defaultValue}
            disabled={props.disabled}
            ref={textFieldRef}
            value={value}
            onChange={handleChange}
            className={styles.dateSelect}
            InputLabelProps={{
              shrink: true,
            }}
          />
      <Typography variant="caption">
        {errorText ? "*" : ""}
      </Typography>
    </div>
  );
};

FormDateSelect.defaultProps = {
  _type: "FormItem",
};

export { FormDateSelect };
