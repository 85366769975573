import { Typography } from "@mui/material";
import React from "react";
import { Grid } from "../../../../components/gridManager/components/Grid";
import EventsList from "../components/EventsList";
import UserInfo from "../components/UserInfo";


const UsersGrid = new Grid();
UsersGrid.id = "Users"
UsersGrid.components = [
  <UserInfo />,
  // <EventsList />,
];
UsersGrid.layout = [
  {
    x: 0,
    y: 0,
    w: 5,
    h: 10,
    i: "0",
    static: true,
  },
  // {
  //   x: 6,
  //   y: 0,
  //   w: 7,
  //   h: 10,
  //   i: "1",
  //   static: true,
  // }
];

export default UsersGrid;