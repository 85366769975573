import { AxiosRequestConfig } from "axios";
import { API } from "../../api";
import { ApiClient } from "../../utils/ApiClient";
import { Device } from "./models/Device";
import { DeviceCategory } from "./models/DeviceGroup";

export class DeviceService {
  public static getAll() {
    const requestConfig: AxiosRequestConfig = {
      method: "GET",
      url: API.server.device.list,
    };

    return ApiClient.request(requestConfig).then((response) => {
      const systems: Device[] = [];
      response.model.forEach((model) => 
        {
          let dev = new Device().copy(model);
          dev.actualUniqueName = dev.uniqueName+dev.sid;
          systems.push(dev);
        })
      return systems;
    });
  }

  public static getById(id) {
    const requestConfig: AxiosRequestConfig = {
      method: "GET",
      url: API.server.device.single.replace("%id%", id),
    };

    return ApiClient.request(requestConfig).then((response) => {
      let dev = new Device().copy(response.model);
      dev.actualUniqueName = dev.uniqueName+dev.sid;
      return dev
    });
  }

  public static getByParentId(id: string) {
    return this.getBySystemId(id);
  }

  public static getBySystemId(sid: string) {
    const requestConfig: AxiosRequestConfig = {
      method: "GET",
      url: API.server.system.devices.replace("%id%", sid),
    };

    return ApiClient.request(requestConfig).then((response) => {
      const systems: Device[] = [];
      response.model.forEach((model) => 
        {
          let dev = new Device().copy(model);
          dev.actualUniqueName = dev.uniqueName+dev.sid;
          systems.push(dev);
        })
      return systems;
    });
  }

  static getByCompanyByGroup(companyId: string, group: DeviceCategory) {
    const requestConfig: AxiosRequestConfig = {
      method: "GET",
      url: API.server.company.devices.replace("%id%", companyId) + "/" + group,
    };

    return ApiClient.request(requestConfig).then((response) => {
      const devices: Device[] = [];
      response.model.forEach((model) => 
      {
        let dev = new Device().copy(model);
        dev.actualUniqueName = dev.uniqueName+dev.sid;
        devices.push(dev);
      })
      return devices;
    });
  }
}
