import { Box, Drawer as Container, Typography } from "@mui/material";
import React, { FunctionComponent, useEffect, useState } from "react";
import { AppStore } from "../../AppStore";
import Popup from "../popup/Popup";
import DrawerHeader from "./components/DrawerHeader";
import { DrawerItem, DrawerItemComponent } from "./components/DrawerItem";
import {
  DrawerSection,
  DrawerSectionComponent,
} from "./components/DrawerSection";
import styles from "./styles/Drawer.css";

interface DrawerProps {}

const Drawer: FunctionComponent<DrawerProps> = (props) => {
  const [state, actions] = AppStore.drawer.use();
  const [filter, setFilter] = useState("");
  const [showAddModelForm, setShowAddModelForm] = useState(false);

  const handleSelectItem = (item: DrawerItem) => {
    actions.setHighlighted(item);
    item.onClick?.(item.model);
  };

  const handleSelectSection = (section: DrawerSection) => {
    actions.setExpandedSection(section.index);
  };

  const createDrawerItem = (item: DrawerItem, index: number) => {
    if (item.primary.toLowerCase().includes(filter.toLowerCase())) {
      return (
        <DrawerItemComponent
          key={index}
          {...item}
          onClick={() => handleSelectItem(item)}
        />
      );
    }
  };

  const createDrawerSection = (section: DrawerSection, index: number) => {
    return (
      <DrawerSectionComponent
        key={index}
        {...section}
        onClick={() => handleSelectSection(section)}
        createDrawerItem={(item, sectionIndex) =>
          createDrawerItem(item, (index + sectionIndex + 1) * 100)
        }
      />
    );
  };

  const handleCloseAddModelForm = () => {
    setShowAddModelForm(false);
  };

  const handleShowAddModelForm = () => {
    setShowAddModelForm(true);
  };

  const handleForm = () => {
    if (state.addModelForm)
      return React.cloneElement(state.addModelForm, {
        onSubmit: handleCloseAddModelForm,
      });
  };

  useEffect(() => {
    if (state.items && !state.drawerToggled) actions.toggleDrawer(true);
  }, [state.items]);

  useEffect(() => {
    if (state.animating) setTimeout(() => actions.setAnimating(false), 300);
  }, [state.animating]);

  return (
    <Container
      variant="persistent"
      open={state.drawerToggled}
      className={styles.container}
      PaperProps={{ className: styles.paper }}
    >
      <DrawerHeader
        onAddModel={state.addModelForm ? handleShowAddModelForm : undefined}
        title={state.title}
        onSearch={setFilter}
      />
      {state.sections && state.sections.length > 0 ? (
        <Box>{state.sections.map(createDrawerSection)}</Box>
      ) : state.items && state.items.length > 0 ? (
        <Box>{state.items.map(createDrawerItem)}</Box>
      ) : (
        <div className={styles.loaderContainer}>
          <Typography>No items...</Typography>
        </div>
      )}

      {state.addModelForm && (
        <Popup
          visible={showAddModelForm}
          onDismiss={handleCloseAddModelForm}
          title={`Add ${state.addModelFormTitle}`}
        >
          {handleForm()}
        </Popup>
      )}
    </Container>
  );
};

export default Drawer;
