import React from "react";
import { Grid } from "../../../../components/gridManager/components/Grid";
import AlertsList from "./AlertsList";
import SystemUpTimeChart from "./UpTimeChart";


const SystemDetailGrid = new Grid();
SystemDetailGrid.components = [
  // <SystemUpTimeChart/>,
  <AlertsList />,
];
SystemDetailGrid.layout = [
  // {
  //   x: 0,
  //   y: 0,
  //   w: 5,
  //   h: 13,
  //   i: "0",
  //   static: true,
  // },
  {
    x: 0,
    y: 0,
    w: 12,
    h: 13,
    i: "0",
    static: true,
  }
];

export default SystemDetailGrid;