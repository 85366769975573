import { Help } from "@mui/icons-material";
import {
  AppBar,
  Avatar,
  Button,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { RouteRender, Routes } from "../../App";
import { AppStore } from "../../AppStore";
import { ENV } from "../../env";
import { AuthenticationContext } from "../../providers/AuthenticationProvider";
import Popup from "../popup/Popup";
import HamburgerToggle from "./HamburgerToggle";
import ProfileMenu from "./ProfileMenu";

import styles from "./styles/NavigationBar.css";

interface Props {
  sections: string[];
  helpMenu?: JSX.Element;
}

const NavigationBar: FunctionComponent<Props> = (props) => {
  const [state, actions] = AppStore.drawer.use();
  const [gridState, gridActions] = AppStore.grid.use();
  const [showHelpModal, setShowHelpModal] = useState(false);
  const authContext = useContext(AuthenticationContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { location, push } = useHistory();

  const toSection = (section: string) => {
    push(section);
  };

  const handleShowHelpModal = () => {
    setShowHelpModal(true);
  }

  const handleHideHelpModal = () => {
    setShowHelpModal(false);
  }

  const handleOpenProfileMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseProfileMenu = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleCloseProfileMenu();
    authContext.logout();
  };

  const handleToggleDrawer = () => {
    actions.toggleDrawer(!state.drawerToggled);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer - 1,
        height: "250px",
        justifyContent: "flex-start",
        backgroundColor: "#055D9C",
      }}
    >
      <Toolbar
        className={`${styles.bar} ${
          gridState.scrolling ? styles.scrolling : ""
        }`}
      >
        <div className={styles.columnLeft}>
          <HamburgerToggle onToggle={handleToggleDrawer} />
          <Typography variant="h6">{RouteRender[location.pathname]}</Typography>
        </div>
        <div className={styles.columnRight}>
          {props.sections.map((section, index) => (
            <Button
              color="inherit"
              key={index}
              onClick={() => toSection(section)}
            >
              {RouteRender[section]}
            </Button>
          ))}
          <ProfileMenu
            open={open}
            anchor={anchorEl}
            onOpen={handleOpenProfileMenu}
            onClose={handleCloseProfileMenu}
            onLogout={handleLogout}
          />
          {props.helpMenu && (
            <>
              <IconButton onClick={handleShowHelpModal} size="small" sx={{ ml: 2 }}>
                <Help
                  sx={{ width: 32, height: 32, color: "rgb(192, 192, 192)" }}
                ></Help>
              </IconButton>
              <Popup title="Help" onDismiss={handleHideHelpModal} visible={showHelpModal} >
                {props.helpMenu}
              </Popup>
            </>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default NavigationBar;
