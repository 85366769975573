import { AxiosRequestConfig } from "axios";
import { API } from "../../api";
import { ApiClient, ModelApi } from "../../utils/ApiClient";
import { User } from "../user/models/User";
import { Company, Project } from "./models/Company";

export class CompanyService {

  public static addProject(id: string, project: Project) {
    const requestConfig: AxiosRequestConfig = {
      method: "POST",
      url: API.server.company.projects.replace("%id%", id),
      data: project,
    };

    return ApiClient.request(requestConfig);
  }

  static removeProject(id: string, project: Project) {
    const requestConfig: AxiosRequestConfig = {
      method: "DELETE",
      url: API.server.company.projects.replace("%id%", id),
      data: project,
    };

    return ApiClient.request(requestConfig);
  }

  public static getUsers(id: string) {
    const requestConfig: AxiosRequestConfig = {
      method: "GET",
      url: API.server.company.users.replace("%id%", id),
    };

    return ApiClient.request(requestConfig).then((response) => {
      const users: User[] = [];
      response.model.forEach((model) => users.push(new User().copy(model)));
      return users;
    });
  }

  public static getOne(id: string) {
    const requestConfig: AxiosRequestConfig = {
      method: "GET",
      url: API.server.company.single.replace("%id%", id),
    };

    return ApiClient.request(requestConfig).then((response) =>
      new Company().copy(response.model)
    );
  }

  public static getByParentId(id: string) {
    return;
  }

  public static getAll() {
    const requestConfig: AxiosRequestConfig = {
      method: "GET",
      url: API.server.company.list,
    };

    return ApiClient.request(requestConfig).then((response) => {
      const companies: Company[] = [];
      response.model.forEach((model) =>
        companies.push(new Company().copy(model))
      );
      return companies;
    });
  }

  public static updateOne(company: any) {
    const requestConfig: AxiosRequestConfig = {
      method: "POST",
      url: API.server.company.list,
      data: company,
    };

    return ApiClient.request(requestConfig);
  }

  static addOne(company: Company) {
    const requestConfig: AxiosRequestConfig = {
      method: "PUT",
      url: API.server.company.list,
      data: company,
    };

    return ApiClient.request(requestConfig);
  }

  static deleteOne(company: Company) {
    const requestConfig: AxiosRequestConfig = {
      method: "DELETE",
      url: API.server.company.list,
      data: { CompanyId: company.id },
    };

    return ApiClient.request(requestConfig);
  }

  static updateSystemNickname(companyId: string, systemId: string, name: string) {
    const requestConfig: AxiosRequestConfig = {
      method: "POST",
      url: API.server.company.system.nickname.replace("%id%", companyId).replace("%systemId%", systemId),
      data: {
        Nickname: name
      },
    };

    return ApiClient.request(requestConfig);
  }
}
