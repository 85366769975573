// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n\n\n.KfUe3WLkwIcFvMEY83bN {\n    width: 100%;\n    height: calc(100% - 10px);\n    display: flex;\n    padding-top: 10px;\n    \n}\n\n.KnBVGEpWMxeIopoCdLNR  {\n    display: none !important;\n    /* background-color: black; */\n}", "",{"version":3,"sources":["webpack://./src/areas/admin/events/styles/Components.css"],"names":[],"mappings":";;;AAGA;IACI,WAAW;IACX,yBAAyB;IACzB,aAAa;IACb,iBAAiB;;AAErB;;AAEA;IACI,wBAAwB;IACxB,6BAA6B;AACjC","sourcesContent":["\n\n\n.listContainer {\n    width: 100%;\n    height: calc(100% - 10px);\n    display: flex;\n    padding-top: 10px;\n    \n}\n\n.listHeaderItem  {\n    display: none !important;\n    /* background-color: black; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listContainer": "KfUe3WLkwIcFvMEY83bN",
	"listHeaderItem": "KnBVGEpWMxeIopoCdLNR"
};
export default ___CSS_LOADER_EXPORT___;
