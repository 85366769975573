import React, { FunctionComponent } from "react";
import { Routes } from "../../../App";
import NavigationBar from "../../../components/navigationbar/NavigationBar";

interface Props {}

const AdminNavigationBar: FunctionComponent<Props> = () => {


    return (
        <NavigationBar sections={[Routes.Companies, Routes.Users, Routes.Systems, Routes.Events, Routes.AdminAnalysis]} />
    )
}

export default AdminNavigationBar;