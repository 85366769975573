import { Button } from "@mui/material";
import { DataGrid, GridColumns, GridRenderCellParams } from "@mui/x-data-grid";
import React, { FunctionComponent, useEffect, useState } from "react";
import { AppStore } from "../../../../AppStore";
import { GridCard } from "../../../../components/gridManager/components/GridCard";
import TableCellExpand from "../../../../components/gridManager/components/TableCellExpand";
import { IGridComponent } from "../../../../components/gridManager/GridManager";
import {
  UserRole,
  UserRoleRender,
} from "../../../../services/user/models/User";
import { UserService } from "../../../../services/user/UserService";
import styles from "../styles/Components.css";
import { AddUserForm } from "./AddUserForm";

interface Props extends IGridComponent {}

const UsersList: FunctionComponent<Props> = (props) => {
  const [companies, companiesActions] = AppStore.companies.use();
  const [state, actions] = AppStore.users.use();
  const [users, setUsers] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [account] = AppStore.account.use();

  const columns: GridColumns = [
    {
      field: "name",
      headerName: "Name",
      width: 130,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      renderCell: TableCellExpand,
    },
    {
      field: "role",
      headerName: "Role",
      width: 130,
      valueFormatter: (params) => UserRoleRender[params.value as UserRole],
    },
    {
      field: "remove",
      headerName: "",
      width: 120,
      renderCell: (params: GridRenderCellParams) => {
        const user = params.row;
        const self = account.user;

        if (self && user.id != self.id && 
            UserRole[self.role] == UserRole.CompanyAdmin)
          return (
            <Button onClick={() => handleRemove(params.row)} color="error">
              remove
            </Button>
          );
      },
    },
  ];

  const handleRemove = (user) => {
    if (user && companies.highlighted)
      UserService.removeFromCompany(user, companies.highlighted.id)
        .then(() => actions.updateParentCollection(companies.highlighted))
        .catch(console.log);
  };

  const handleUsers = () => {
    if (companies.highlighted)
      actions
        .getCachedParentCollection(companies.highlighted)
        .then(setUsers)
        .then(() => setLoading(false))
        .catch(console.log);
  };

  useEffect(() => {
    setLoading(true);
    handleUsers();
  }, [companies.highlighted]);

  useEffect(() => {
    if (!loading) handleUsers();
  }, [state.parentCollection]);

  return (
    <GridCard
      gridIndex={props.gridIndex}
      loading={loading}
      icon="./icons/group.png"
      title="Users"
      utility={
        account.user && UserRole[account.user.role] == UserRole.CompanyAdmin ? (
          <AddUserForm />
        ) : undefined
      }
    >
      <div className={styles.listContainer}>
        <DataGrid
          rows={users}
          columns={columns}
          loading={loading}
          rowHeight={30}
          headerHeight={30}
          sx={{
            "& .MuiDataGrid-columnSeparator": {
              visibility: "hidden",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#e9e9e9",
            },
          }}
          hideFooter
        />
      </div>
    </GridCard>
  );
};

export default UsersList;
