import { AxiosRequestConfig } from "axios";
import { API } from "../../api";
import { ApiClient } from "../../utils/ApiClient";
import { User } from "./models/User";

export class UserService {

  public static getByParentId(id: string) {
    return this.getByCompanyId(id);
  }

  public static getAll() {
    const requestConfig: AxiosRequestConfig = {
      method: "GET",
      url: API.server.user.list
    }

    return ApiClient.request(requestConfig).then((response) => {
      const users: User[] = [];
      response.model.forEach((model) => 
        users.push(new User().copy(model))
      )
      return users;
    })
  }

  public static getByCompanyId(id: string) {
    const requestConfig: AxiosRequestConfig = {
      method: "GET",
      url: API.server.company.users.replace("%id%", id)
    }

    return ApiClient.request(requestConfig).then((response) => {
      const users: User[] = [];
      response.model.forEach((model) => 
        users.push(new User().copy(model))
      )
      return users;
    })
  }

  public static updateOne(user: User) {
    const requestConfig: AxiosRequestConfig = {
      method: "POST",
      url: API.server.user.list,
      data: user
    }

    return ApiClient.request(requestConfig)
  }

  public static addToCompany(user: User, id: string) {
    
    const requestConfig: AxiosRequestConfig = {
      method: "POST",
      url: API.server.company.users.replace("%id%", id),
      data: { "userId": user.id }
    }

    return ApiClient.request(requestConfig)

  }

  public static removeFromCompany(user: User, id: string) {
    
    const requestConfig: AxiosRequestConfig = {
      method: "DELETE",
      url: API.server.company.users.replace("%id%", id),
      data: { "userId": user.id }
    }

    return ApiClient.request(requestConfig)

  }

  static addOne(user: any) {
    const requestConfig: AxiosRequestConfig = {
      method: "PUT",
      url: API.server.user.list,
      data: user
    }

    return ApiClient.request(requestConfig)
  }

  static deleteOne(user: User) {
    console.warn("HERE")
    const requestConfig: AxiosRequestConfig = {
      method: "DELETE",
      url: API.server.user.list,
      data: { "userId": user.id }
    }

    return ApiClient.request(requestConfig)
  }

}
