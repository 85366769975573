import { Button, Typography } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { FunctionComponent, useEffect, useState } from "react";
import { AppStore } from "../../../AppStore";
import Popup from "../../../components/popup/Popup";
import styles from "../styles/Components.css";
import RGL, { WidthProvider } from "react-grid-layout";
import DeviceDetailAnalysisGrid from "./DeviceDetailGrid/Grid";

const GridLayout = WidthProvider(RGL);


interface Props {
  onDismiss: () => void;
  show?: boolean;
}

const DeviceModal: FunctionComponent<Props> = (props) => {
  const [state, actions] = AppStore.devices.use();
  const [section, setSection] = useState(0);

  const options = {
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    yAxis: {
      title: {
        text: "Measurements",
      },
    },
    xAxis: {
      title: {
        text: "Time (s)",
      },
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
      },
    },
    series: [
      {
        name: "Installation",
        data: [43934, 52503, 57177, 69658, 97031, 119931, 137133, 154175],
      },
    ],
  };

  const createGridItem = (element: JSX.Element | undefined, index: number) => {
    if (element) {
      return (
        <div key={index} className={styles.detailCard}>
          {React.cloneElement(element, { ...element.props, gridIndex: index })}
        </div>
      );
    }
  };

  const analysis = (
    <GridLayout
      layout={DeviceDetailAnalysisGrid.layout}
      // onLayoutChange={props.onLayoutChange}
      className={`layout`}
      compactType={null}
      rowHeight={20}
      cols={12}
      margin={[5, 5]}
      items={DeviceDetailAnalysisGrid.layout.length}
    >
      {DeviceDetailAnalysisGrid.components.map(createGridItem)}
    </GridLayout>
  );
  const settings = <> </>;
  const [sectionRender, setSectionRender] = useState(analysis);

  const handleToggleDeviceDetails = (event) => {
    setSection(event.target.id);
  };

  useEffect(() => {
    setSectionRender(section == 0 ? analysis : settings);
  }, [section]);

  return (
    <Popup visible={props.show} onDismiss={props.onDismiss}>
      <div className={styles.deviceDetailsHeader}>
        <div className={styles.deviceDetailsHeaderTitle}>
          <h3 className={styles.deviceTitle}>{state.highlighted?.name}</h3>
          <h2 className={styles.deviceSubtitle}>{state.highlighted?.ssid}</h2>
        </div>
        <Button className={styles.dismissModalButton} onClick={props.onDismiss}>
          X
        </Button>
      </div>
      <div className={styles.deviceDetailsToggleBody}>
        <Button
          className={`${styles.deviceDetailsToggleButton} ${
            section == 0 ? styles.deviceDetailsToggledButton : ""
          }`}
          id="0"
          onClick={handleToggleDeviceDetails}
        >
          Analysis
        </Button>
        <Button
          className={`${styles.deviceDetailsToggleButton} ${
            section == 1 ? styles.deviceDetailsToggledButton : ""
          }`}
          id="1"
          onClick={handleToggleDeviceDetails}
        >
          Settings
        </Button>
      </div>
      <div
        className={`${styles.deviceDetailsBody} ${
          section == 0 ? styles.deviceDetailsBodyDefault : ""
        }`}
      >
        <div className={styles.deviceDetailsContainer}>{sectionRender}</div>
      </div>
    </Popup>
  );
};

export default DeviceModal;
