import React, { FunctionComponent, useContext } from "react";
import { AppStore } from "../../../../AppStore";
import { WebSocketContext } from "../../../../providers/WebSocketProvider";
import styles from "../styles/CompaniesDashboard.css";
import Body from "./Body";

interface Props {}

const CompanyDashboard: FunctionComponent<Props> = () => {


    return (
        <div className={styles.bodyContainer} >
            <Body />
        </div>
    )
}

export default CompanyDashboard;