import { Company } from "../company/models/Company";
import { User } from "./models/User";
import { UserService } from "./UserService";
import {
  IModelActions,
  IModelState,
  ModelStore,
} from "../../utils/ModelStore";
import { Grid } from "../../components/gridManager/components/Grid";
import UsersGrid from "../../areas/admin/users/layouts/Grid";

export interface UsersState extends IModelState<User> {
  activeGrid: Grid
  defaultGrid: Grid
}

export interface UsersActions extends IModelActions<User> {
  setActiveGrid: (grid: Grid) => void
}

export class UsersStore
  extends ModelStore<User, UsersState, UsersActions>
  implements UsersActions
{
  protected prototype = UsersStore.prototype;
  protected initialState = {
    collection: [],
    parentCollection: [],
    highlighted: undefined,
    highlightedParent: undefined,
    lastUpdatedCollection: undefined,
    lastUpdatedHighlighted: undefined,
    lastUpdatedParentCollection: undefined,
    activeGrid: UsersGrid,
    defaultGrid: UsersGrid,
    cachedCapacity: 30,
    api: UserService
  };

  constructor() {
    super();
  }

  public setActiveGrid(grid: Grid) {
    this.setState({
      ...this.state,
      activeGrid: grid
    })
  }
}