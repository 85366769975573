import { Typography, TextField, Button } from "@mui/material";
import React, { FunctionComponent, useEffect, useState } from "react";
import { AppStore } from "../../../AppStore";
import Popup from "../../../components/popup/Popup";
import { FormatStringToDate } from "../../../helpers/time";
import {
  EntryType,
  JournalEntry,
} from "../../../services/system/models/Journal";
import { SystemService } from "../../../services/system/SystemService";
import styles from "../styles/Components.css";

interface Props {}

interface ModalProps {
  show?: boolean;
  onDismiss: () => void;
  onLoading?: (loading: boolean) => void;
}

interface EntryProps {
  entry: JournalEntry;
}

const JournalEntryComponent: FunctionComponent<EntryProps> = (props) => {
  return (
    <div className={styles.journalEntry}>
      <Typography fontWeight={"bold"} variant={"body2"}>
        {props.entry.user}
      </Typography>
      <Typography variant="body2">{props.entry.message}</Typography>
      <Typography align="right" variant="body2">
        {FormatStringToDate(props.entry.date.toString())}
      </Typography>
    </div>
  );
};

const Journal: FunctionComponent<Props> = (props) => {
  const [state, actions] = AppStore.systems.use();
  const [account, accountActions] = AppStore.account.use();
  const [entries, setEntries] = useState<JournalEntry[]>();
  const [input, setInput] = useState("");

  const createJournalEntry = (entry, index) => {
    if (entry.type == EntryType.User)
      return <JournalEntryComponent entry={entry} key={index} />;
  };

  const handleChangeInput = (event) => {
    setInput(event.target.value);
  };

  const handleSubmit = () => {
    const entry = {
      user: account.user?.name,
      message: input,
    };

    if (state.highlighted)
      SystemService.addJournalEntry(state.highlighted.sid, entry).then(() =>
        setInput("")
      );
  };

  useEffect(() => {
    if (state.highlighted) setEntries(state.highlighted.journal.entries);
  }, [state.highlighted]);

  return (
    <div className={styles.detailContainer}>
      <div className={styles.journalContainer}>
        <div className={styles.journalBody}>
          {entries && entries.map(createJournalEntry)}
        </div>
        <div className={styles.journalTextEntry}>
          <div className={styles.journalTextEntryContainer}>
            <TextField
              variant="outlined"
              fullWidth
              placeholder="Add note here..."
              onChange={handleChangeInput}
              value={input}
              size="small"
            />
          </div>

          <Button variant="contained" onClick={handleSubmit}>
            post
          </Button>
        </div>
      </div>
    </div>
  );
};

const JournalModal: FunctionComponent<ModalProps> = (props) => {
  return (
    <Popup
      title="System Notes"
      visible={props.show}
      onDismiss={props.onDismiss}
    >
      <Journal />
    </Popup>
  );
};

export { JournalModal, Journal };
