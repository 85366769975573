import { AxiosRequestConfig } from "axios";
import { API } from "../../api";
import { ApiClient } from "../../utils/ApiClient";
import { Diagnostic } from "./models/Diagnostic";

export class DiagnosticService {

  public static getAll() {
    const requestConfig: AxiosRequestConfig = {
      method: "GET",
      url: API.server.diagnostic.list
    }

    return ApiClient.request(requestConfig).then((response) => {
      const systems: Diagnostic[] = [];
      response.model.forEach((model) => 
        systems.push(new Diagnostic().copy(model))
      )
      return systems;
    })
  }

  public static getByParentId(id: string)
  {
    return this.getBySystemId(id);
  }

  public static getBySystemId(sid: string)
  {
    const requestConfig: AxiosRequestConfig = {
      method: "GET",
      url: API.server.system.diagnostics.replace("%id%", sid)
    }

    return ApiClient.request(requestConfig).then((response) => {
      const systems: Diagnostic[] = [];
      response.model.forEach((model) => 
        systems.push(new Diagnostic().copy(model))
      )
      return systems;
    })
  }
}
