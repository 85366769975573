export function convertUTCDateToLocalDate(date) {
  const newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);

  const offset = date.getTimezoneOffset() / 60;
  const hours = date.getHours();

  newDate.setHours(hours - offset);

  return newDate;   
}


export function isUTCOffset(date: string) {
    
    if (date.length > 0)
        return date[date.length - 1] == 'Z'

    return false
}

export function FormatStringToDate(date: string) {

    if (date == "0001-01-01T00:00:00Z")
        return "N/A"

    const parsed = new Date(Date.parse(date));

    const month = parsed.getMonth() + 1;
    const day = parsed.getDate();
    const year = parsed.getFullYear();

    const hour =
      parsed.getHours().toString().length == 1
        ? "0" + parsed.getHours()
        : parsed.getHours();
    const minute =
      parsed.getMinutes().toString().length == 1
        ? "0" + parsed.getMinutes()
        : parsed.getMinutes();
    const second =
      parsed.getSeconds().toString().length == 1
        ? "0" + parsed.getSeconds()
        : parsed.getSeconds();

    return `${month}/${day}/${year} ${hour}:${minute}:${second}`;
}

export function FormatDateToUTCString(date: Date) {

  const parsed = new Date(date);

  const month = parsed.getUTCMonth() + 1;
  const day = parsed.getUTCDate();
  const year = parsed.getUTCFullYear();

  const hour =
    parsed.getUTCHours().toString().length == 1
      ? "0" + parsed.getUTCHours()
      : parsed.getUTCHours();
  const minute =
    parsed.getMinutes().toString().length == 1
      ? "0" + parsed.getUTCMinutes()
      : parsed.getUTCMinutes();
  const second =
    parsed.getUTCSeconds().toString().length == 1
      ? "0" + parsed.getUTCSeconds()
      : parsed.getUTCSeconds();

  return `${month}/${day}/${year} ${hour}:${minute}:${second}`;
}

export const FormatDateToString = (date: Date | undefined) => {
  if (!date) return;

  let month = (date.getMonth() + 1).toString();
  let day = date.getDate().toString();

  if (month.length < 2) month = "0" + month;

  if (day.length < 2) day = "0" + day;

  return date.getFullYear() + "-" + month + "-" + day;
};