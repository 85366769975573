import { Button } from "@mui/material";
import React, { Fragment, FunctionComponent, useState } from "react";
import { AppStore } from "../../../../AppStore";
import { GridCardUtilityIcon } from "../../../../components/gridManager/components/GridCard";
import List from "../../../../components/list/List";
import Popup from "../../../../components/popup/Popup";
import { ObjectId } from "../../../../helpers/objectid";
import { System } from "../../../../services/system/models/System";
import { SystemService } from "../../../../services/system/SystemService";
import styles from "../styles/Components.css";

interface Props {}

export const AddSystemToCompanyForm: FunctionComponent<Props> = (props) => {
  const [state, actions] = AppStore.systems.use();
  const [companyState] = AppStore.companies.use();
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState<any>();
  const [systems, setSystems] = useState<(System | undefined)[]>([]);

  const handleCloseForm = () => {
    setShow(false);
  };

  const handleAddSystem = () => {
    if (selected && companyState.highlighted)
      SystemService.addToCompany(selected, companyState.highlighted.id)
        .then(handleCloseForm)
        .then(() => actions.updateParentCollection(companyState.highlighted))
        .then(() => actions.updateCollection())
        .catch(console.log);
  };

  const filterSystems = (systems: System[]) => {
    return systems.map((s) => {
      if (
        companyState.highlighted &&
        !s.companyIds.includes(companyState.highlighted?.id)
      )
        return s;
    });
  };

  const handleOpenForm = () => {
    setShow(true);

    actions
      .getCachedCollection()
      .then(filterSystems)
      .then(setSystems)
      .catch(console.log);
  };

  return (
    <Fragment>
      <Popup visible={show} onDismiss={handleCloseForm} title={"Add System"}>
        <List
          items={systems}
          title="systems"
          primaryField="sid"
          secondaryField=""
          onSelect={setSelected}
        />
        <div className={styles.addUserButtonContainer}>
          <Button onClick={handleAddSystem} variant="contained">
            Add
          </Button>
        </div>
      </Popup>
      <GridCardUtilityIcon
        src="./icons/plus.png"
        width={20}
        height={20}
        onClick={handleOpenForm}
      />
    </Fragment>
  );
};
