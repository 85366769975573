import { DataGrid, GridColumns } from "@mui/x-data-grid";
import React, { FunctionComponent, useEffect, useState } from "react";
import { AppStore } from "../../../../AppStore";
import { GridCard } from "../../../../components/gridManager/components/GridCard";
import { Device } from "../../../../services/device/models/Device";
import styles from "../styles/Components.css";

interface Props {}

const DevicesList: FunctionComponent<Props> = (props) => {
  const [systems, systemsActions] = AppStore.systems.use();
  const [state, actions] = AppStore.devices.use();
  const [devices, setDevices] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  const columns: GridColumns = [
    {
      field: "name",
      headerName: "Name",
      width: 160,
    },
    {
      field: "ssid",
      headerName: "Sub System",
      width: 160,
    },
    {
      field: "measurement",
      headerName: "Measurement",
      width: 130,
    },
    {
      field: "units",
      headerName: "Units",
      width: 130,
    },
    {
      field: "category",
      headerName: "Category",
      width: 130,
    },
    {
      field: "alerts",
      headerName: "Alerts",
      minWidth: 100,
      width: 400
    },
  ];

  const processDevices = (devices: Device[]) => {
    const processed: any[] = [];

    devices.forEach((device, index) => {
      processed.push({
        name: device.name,
        ssid: device.ssid,
        measurement: device.measurement,
        units: device.units,
        category: device.category,
        alerts: device.alerts.map(a => a.value),
        id: index,
      });
    });

    return processed;
  };

  const handleDevices = () => {
    if (systems.highlighted)
      actions
        .getCachedParentCollection(systems.highlighted)
        .then(processDevices)
        .then(setDevices)
        .then(() => setLoading(false))
        .catch(console.log);
  }

  useEffect(() => {
    setLoading(true)
    handleDevices()
  }, [systems.highlighted]);

  useEffect(() => {
    if (!loading) handleDevices();
  }, [state.collection]);

  return (
    <GridCard loading={loading} icon="./icons/sensor.png" title="Devices">
      <div className={styles.listContainer}>
        <DataGrid
          rows={devices}
          columns={columns}
          loading={loading}
          rowHeight={30}
          headerHeight={30}
          sx={{
            "& .MuiDataGrid-columnSeparator": {
              visibility: "hidden",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#e9e9e9",
            },
          }}
          hideFooter
        />
      </div>
    </GridCard>
  );
};

export default DevicesList;
