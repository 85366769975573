import { dividerClasses } from "@mui/material";
import React, { Fragment, FunctionComponent, useEffect, useState } from "react";
import { GridCardUtilityIcon } from "../../../components/gridManager/components/GridCard";
import { Device } from "../../../services/device/models/Device";
import DownloadModal from "./DownloadModal";

interface Props {
  devices: Device[]
  timeSeries: [Date, Date, number]
}

export const DownloadGraph: FunctionComponent<Props> = (props) => {
  const [downloading, setDownloading] = useState(false);
  const [keys, setKeys] = useState<string[]>([])

  const handleCloseDownload = () => {
    setDownloading(false);
  };

  const handleOpenDownload = () => {
    setDownloading(true);
  };

  useEffect(() => {
    if (props.devices.length > 0) {
      const newKeys: string[] = []

      props.devices.forEach(device => {
        newKeys.push(`${device.uniqueName} (${device.units})`);
      })
    
      setKeys(newKeys)
    }
  }, [props.devices])

  return (
    <Fragment>
      <DownloadModal timeSeries={props.timeSeries} keys={keys} visible={downloading} onDismiss={handleCloseDownload} />
      <GridCardUtilityIcon
        src="./icons/download-dark.svg"
        width={25}
        height={25}
        onClick={handleOpenDownload}
      />
    </Fragment>
  );
};
