import { Button, Typography } from "@mui/material";
import React, { FunctionComponent, useState } from "react";
import { AppStore } from "../../../../AppStore";
import { Form } from "../../../../components/form/FormElement";
import { GridCardUtilityIcon } from "../../../../components/gridManager/components/GridCard";
import Popup from "../../../../components/popup/Popup";
import { CompanyService } from "../../../../services/company/CompanyService";
import { Project } from "../../../../services/company/models/Company";
import styles from "../styles/Components.css";

interface Props {
  onSubmit?: () => void;
}

export const AddProjectForm: FunctionComponent<Props> = (props) => {
  const [state, actions] = AppStore.companies.use();
  const [error, setError] = useState(undefined);
  const [show, setShow] = useState(false);
  const [model] = useState(new Project());

  const handleSubmit = (project) => {
    setError(undefined);

    if (state.highlighted)
      return CompanyService.addProject(state.highlighted.id, project)
        .then(() => actions.updateHighlighted(undefined))
        .then(handleCloseForm)
        .then(props.onSubmit)
        .catch((error) =>
          setError(JSON.parse(error.request.response).message));

    return new Promise((resolve, reject) => {});
  };

  const handleCloseForm = () => {
    setShow(false);
    setError(undefined);
  };

  const handleOpenForm = () => {
    setShow(true);
  };

  return (
    <>
      <Popup visible={show} onDismiss={handleCloseForm} title={"Add Project"}>
        <Form model={model} onSubmit={handleSubmit}>
          <Form.Item field="name">
            <Form.Input fullWidth placeholder="Project XYZ" label="Name" />
          </Form.Item>
          <Form.Item field="submit">
            <div className={styles.formButtonContainer}>
              {error && <Typography color="error">{error}</Typography>}
              <Button variant="contained">Submit</Button>
            </div>
          </Form.Item>
        </Form>
      </Popup>
      <GridCardUtilityIcon
        src="./icons/plus.png"
        width={20}
        height={20}
        onClick={handleOpenForm}
      />
    </>
  );
};
