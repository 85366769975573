import { Button } from "@mui/material";
import { DataGrid, GridColumns, GridRenderCellParams } from "@mui/x-data-grid";
import React, { FunctionComponent, useEffect, useState } from "react";
import { AppStore } from "../../../../AppStore";
import { GridCard } from "../../../../components/gridManager/components/GridCard";
import { IGridComponent } from "../../../../components/gridManager/GridManager";
import { CompanyService } from "../../../../services/company/CompanyService";
import { Project } from "../../../../services/company/models/Company";
import { UserRole } from "../../../../services/user/models/User";
import styles from "../styles/Components.css";
import { AddProjectForm } from "./AddProjectForm";

interface Props extends IGridComponent {}

const ProjectsList: FunctionComponent<Props> = (props) => {
  const [state, actions] = AppStore.companies.use();
  const [projects, setProjects] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [account] = AppStore.account.use();

  const columns: GridColumns = [
    {
      field: "name",
      headerName: "Name",
      width: 130,
    },
    {
      field: "delete",
      headerName: "",
      width: 120,
      renderCell: (params: GridRenderCellParams) => {
        const self = account.user;

        if (
          params.row.name != "Default" &&
          self &&
          UserRole[self.role] == UserRole.CompanyAdmin
        )
          return (
            <Button onClick={() => handleRemove(params.row)} color="error">
              remove
            </Button>
          );
      },
    },
  ];

  const processProjects = (projects: Project[] | undefined) => {
    const processed: any[] = [];

    if (!projects) return;

    projects.forEach((project, index) => {
      processed.push({
        name: project.name,
        id: index,
      });
    });

    return processed;
  };

  const handleRemove = (project) => {
    if (project && state.highlighted)
      CompanyService.removeProject(state.highlighted.id, project)
        .then(() => actions.updateHighlighted(undefined))
        .catch(console.log);
  };

  const handleProjects = () => {
    if (state.highlighted)
      actions
        .getCachedHighlighted(state.highlighted.id)
        .then((company) => processProjects(company?.projects))
        .then(setProjects)
        .then(() => setLoading(false))
        .catch(console.log);
  };

  useEffect(() => {
    setLoading(true);
    handleProjects();
  }, [state.highlighted]);

  return (
    <GridCard
      gridIndex={props.gridIndex}
      loading={loading}
      icon="./icons/project.png"
      title="Projects"
      utility={
        account.user && UserRole[account.user.role] == UserRole.CompanyAdmin ? (
          <AddProjectForm />
        ) : undefined
      }
    >
      <div className={styles.listContainer}>
        <DataGrid
          rows={projects}
          columns={columns}
          loading={loading}
          rowHeight={30}
          headerHeight={30}
          sx={{
            "& .MuiDataGrid-columnSeparator": {
              visibility: "hidden",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#e9e9e9",
            },
          }}
          hideFooter
        />
      </div>
    </GridCard>
  );
};

export default ProjectsList;
