import { AxiosRequestConfig } from "axios";
import { API } from "../../api";
import { ApiClient } from "../../utils/ApiClient";
import { User } from "../user/models/User";

export class AccountService {

    public static getUserDetails(id: string) {

        const requestConfig: AxiosRequestConfig = {
            method: "GET",
            url: API.server.account.auth0Details.replace("%id%", id)
        };

        return ApiClient.request(requestConfig)
            .then(response => new User().copy(response.model))
    }
}