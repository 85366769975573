import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AppStore } from "../../../AppStore";
import { GridCardExpand } from "../../../components/gridManager/components/Grid";
import { GridCard } from "../../../components/gridManager/components/GridCard";
import {
  GridContext,
  IGridComponent,
} from "../../../components/gridManager/GridManager";
import { Device } from "../../../services/device/models/Device";
import {
  Units,
  UnitSymbol,
} from "../../../services/device/models/DeviceMeasurement";
import { System } from "../../../services/system/models/System";
import styles from "../styles/Components.css";
import DeviceModal from "./DeviceModal";

interface Props extends IGridComponent {}

export const DeviceList: FunctionComponent<Props> = (props) => {
  const [loading, setLoading] = useState(false);
  const [systems] = AppStore.systems.use();
  const [state, actions] = AppStore.devices.use();
  const [devices, setDevices] = useState<Device[] | undefined>();
  const [deviceElements, setDeviceElements] = useState<
    JSX.Element[] | undefined
  >();
  const [showDeviceDetails, setShowDeviceDetails] = useState(false);
  const [previousSystem, setPreviousSystem] = useState<System>();
  const [gridState] = AppStore.grid.use();
  const gridContext = useContext(GridContext);

  const retrieveDevices = () => {
    setDevices([]);
    if (systems.highlighted)
      actions
        .getCachedParentCollection(systems.highlighted)
        .then(setDevices)
        .then(() => setLoading(false))
        .catch(console.log);
  };

  const updateDevices = () => {
    if (systems.highlighted)
      actions
        .updateParentCollection(systems.highlighted)
        .then(setDevices)
        .then(() => setLoading(false))
        .catch(console.log);
  };

  const handleSelectedDevice = (device: Device) => {
    setShowDeviceDetails(true);
    actions.setHighlighted(device);
  };

  const handleDismissDetails = () => {
    setShowDeviceDetails(false);
  };

  useEffect(() => {
    if (systems.highlighted) {
      setLoading(true);

      if (systems.highlighted.id != previousSystem?.id) retrieveDevices();
      else updateDevices();

      setPreviousSystem(systems.highlighted);
    }
  }, [systems.highlighted]);

  useEffect(() => {
    const newDevices: JSX.Element[] = [];
    const rows = state.parentCollection.length / 5;
    const whole = Math.trunc(rows);
    const scale = (rows % 1 == 0 ? whole : whole + 1) * 2.3;

    if (gridState.expandedCard.scale != scale)
      gridContext.scaleCard(new GridCardExpand(props.gridIndex, scale));

    devices?.forEach((device, index) =>
      newDevices.push(renderDevice(device, index))
    );

    setDeviceElements(newDevices);
  }, [devices]);

  const renderDevice = (device, index) => {
    const element = (
      <div key={index} className={styles.deviceListColumn}>
        <DeviceCard
          online={systems.highlighted?.isOnline()}
          onSelect={handleSelectedDevice}
          details={device}
        />
      </div>
    );

    return element;
  };

  return (
    <GridCard
      gridIndex={props.gridIndex}
      // expandable
      icon="/icons/sensor.png"
      title="Devices"
    >
      <div className={styles.deviceListBody}>
        <div className={styles.deviceListContainer}>{deviceElements}</div>
      </div>
      <DeviceModal onDismiss={handleDismissDetails} show={showDeviceDetails} />
    </GridCard>
  );
};

interface DeviceProps {
  details: Device;
  online?: boolean;
  onSelect: (device: Device) => void;
}

const DeviceCard: FunctionComponent<DeviceProps> = (props) => {
  const { details } = props;

  const [measurement, setMeasurement] = useState("");
  const [statusProps, setstatusProps] = useState({ health: "offline" });

  useEffect(() => {
    if (props.details.sid ==  'PAM-1322' || props.details.sid ==  'PAM-1323' || props.details.sid ==  'PAM-1324' || props.details.sid ==  'PAM-1325' || props.details.sid ==  'PAM-1326' || props.details.sid ==  'PAM-1327' || props.details.sid ==  'PAM-1328' || props.details.sid ==  'PAM-1329' || props.details.sid ==  'PAM-1330' || props.details.sid ==  'PAM-1331')
    {
      // console.log("Doing 1003");
      // console.log(props.details.health);
      setstatusProps({ health: "Healthy"})
    }
    else
    {
      if (props.online) setstatusProps({ health: details.health });
    }
    
  }, [props.online]);

  useEffect(() => {
    const measurement = details.measurement;
    setMeasurement(measurement);
  }, [details.measurement]);

  const DeviceMemo = useMemo(() => {
    return (
      <article
        className={styles.device}
        onClick={() => props.onSelect(props.details)}
      >
        <div className={styles.deviceCardHeader}>
          <h3 className={styles.deviceTitle}>{details.name}</h3>
          <div {...statusProps} className={styles.deviceStatus} />
        </div>
        <p className={styles.deviceSubtitle}>
          {`${measurement == null ? "N/A" : measurement} ${
            Units[details.units] != Units.OTHER
              ? UnitSymbol[Units[details.units]] == undefined
                ? details.units
                : UnitSymbol[Units[details.units]]
              : ""
          }`}
        </p>
      </article>
    );
  }, [props.details, statusProps, measurement]);

  return DeviceMemo;
};
