// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.ny0jCAYsT9ZFw7ebRl_O {\n    width: 100%;\n    height: calc(100% - 10px);\n    display: flex;\n    padding-top: 10px;\n}\n\n.U2iKv9ZciZiGiuIp64Bf  {\n    display: none !important;\n    /* background-color: black; */\n}\n\n.S3WuYMBUBgGj_9M46YmE {\n    background-color: #e9e9e9;\n    height: calc(100% - 1em - 40px);\n    overflow-y: scroll;\n    border-radius: 8px;\n    border: 1px solid #dfdfdf;\n    padding: 20px;\n}\n\n.CB0fuIJ4N8XzqDBew_38 {\n\n}\n\n.fV9Q_H2jq2NV502ESjNy {\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-end;\n}\n\n.DlFis5yTiynHgkukpwMh {\n    width: 100%;\n    border: 1px solid #dfdfdf;\n}", "",{"version":3,"sources":["webpack://./src/areas/admin/systems/styles/Components.css"],"names":[],"mappings":";AACA;IACI,WAAW;IACX,yBAAyB;IACzB,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,wBAAwB;IACxB,6BAA6B;AACjC;;AAEA;IACI,yBAAyB;IACzB,+BAA+B;IAC/B,kBAAkB;IAClB,kBAAkB;IAClB,yBAAyB;IACzB,aAAa;AACjB;;AAEA;;AAEA;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,yBAAyB;AAC7B","sourcesContent":["\n.listContainer {\n    width: 100%;\n    height: calc(100% - 10px);\n    display: flex;\n    padding-top: 10px;\n}\n\n.listHeaderItem  {\n    display: none !important;\n    /* background-color: black; */\n}\n\n.diagnosticWindow {\n    background-color: #e9e9e9;\n    height: calc(100% - 1em - 40px);\n    overflow-y: scroll;\n    border-radius: 8px;\n    border: 1px solid #dfdfdf;\n    padding: 20px;\n}\n\n.systemManifestContainer {\n\n}\n\n.submitButtonContainer {\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-end;\n}\n\n.textArea {\n    width: 100%;\n    border: 1px solid #dfdfdf;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listContainer": "ny0jCAYsT9ZFw7ebRl_O",
	"listHeaderItem": "U2iKv9ZciZiGiuIp64Bf",
	"diagnosticWindow": "S3WuYMBUBgGj_9M46YmE",
	"systemManifestContainer": "CB0fuIJ4N8XzqDBew_38",
	"submitButtonContainer": "fV9Q_H2jq2NV502ESjNy",
	"textArea": "DlFis5yTiynHgkukpwMh"
};
export default ___CSS_LOADER_EXPORT___;
