import React, { FunctionComponent, ComponentProps } from "react";
import { FormItemProps } from "../FormElement";
import { Input, InputProps, Typography } from "@mui/material";
import styles from "../styles/Components.css"

interface Props
  extends Omit<InputProps, "value" | "defaultValue" | "size">,
    FormItemProps<string> {
  size?: "small" | "medium" | undefined;
  elevated?: number;
  borderless?: boolean;
}

const FormInput: FunctionComponent<Props> = (props) => {
  const size = props.size ?? undefined;
  const { label, errorText, helperText, style, onBlur, onFocus, onUpdate, _controlled, ...rest } =
    props;
  const [isFocused, setFocused] = React.useState(false);
  const [value, setValue] = React.useState("");
  const inputRef = React.useRef<typeof Input>(null);

  React.useEffect(() => {
    handleChange(props.defaultValue ?? "");
    setFocused(!!props.defaultValue);
  }, [props.defaultValue]);

  const handleChange = (event) => {
    const text = event.nativeEvent?.target.value

    if (text) {
      props.onUpdate?.(text);
      setValue(text);
    }
  };

  return (
    <div>
       <Typography variant="caption">
        {label}
      </Typography>
      <Input
        ref={inputRef}
        {...rest}
        size={size}
        value={undefined}
        onChange={handleChange}
        className={props.className ? props.className : styles.input}
        disableUnderline
      />

      <Typography variant="caption">
        {errorText ? "*" : ""}
      </Typography>
    </div>
  );
};

FormInput.defaultProps = {
  _type: "FormItem",
};

export { FormInput };
