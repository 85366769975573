import React from "react";
import { Grid } from "../../../components/gridManager/components/Grid";
import { DeviceList } from "../components/DeviceList";
import Graph from "../components/Graph";
import MapElement from "../components/Map";
import StatusRecordList from "../components/StatusRecordList";
import SystemInfo from "../components/SystemInfo";
import DisclaimerElement from "../components/Disclaimer";


const SystemGrid = new Grid();
SystemGrid.id = "System"
SystemGrid.components = [
  <SystemInfo />,
  <MapElement />,
  <DeviceList />,
  <DisclaimerElement />,
  <Graph />,
  
];
SystemGrid.layout = [
  {
    x: 0,
    y: 0,
    w: 5,
    h: 10,
    i: "0",
    static: true,
  },
  {
    x: 6,
    y: 0,
    w: 7,
    h: 10,
    i: "1",
    static: true,
  },
  {
    x: 0,
    y: 10,
    w: 12,
    h: 4,
    i: "2",
    static: true,
  },
  {
    x: 0,
    y: 14,
    w: 12,
    h: 6,
    i: "3",
    static: true,
  },
  {
    x: 0,
    y: 20,
    w: 12,
    h: 14,
    i: "4",
    static: true,
  }
];

export default SystemGrid;