import { TextField, Button, Typography } from '@mui/material';
import React, { Fragment, FunctionComponent, useEffect, useState } from 'react'
import Popup from '../../../components/popup/Popup';
import { FormatDateToString } from '../../../helpers/time';
import styles from '../styles/Components.css'

interface CustomTimeSeriesProps {
    handleChangeTimeSeries: ([]: [Date, Date, number]) => void;
    timeSeries: [Date, Date, number];
  }
  
  export const CustomTimeSeries: FunctionComponent<CustomTimeSeriesProps> = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [keys, setKeys] = useState<string[]>([]);
    const [start, setStart] = useState<Date>(new Date());
    const [end, setEnd] = useState<Date>(new Date());
    const [average, setAverage] = useState<number>(0);
  
    const handleCloseModal = () => {
      setShowModal(false);
    };
  
    const handleOpenModal = () => {
      setShowModal(true);
    };
  
    const handleCancel = () => {
      handleCloseModal();
    };
  
    const handleSubmit = (timeSeries) => {
      props.handleChangeTimeSeries(timeSeries);
      handleCloseModal();
    };
  
    useEffect(() => {
      const [start, end, average] = props.timeSeries;
  
      setStart(start);
      setEnd(end);
      setAverage(average);
    }, [props.timeSeries]);
  
    return (
      <Fragment>
        <Popup
          title="Custom Time Frame"
          visible={showModal}
          onDismiss={handleCloseModal}
        >
          <TextField
            id="Start_date"
            label="Start Date"
            type="date"
            value={FormatDateToString(start)}
            onChange={(e) => setStart(new Date(e.target.value + "T00:00"))}
            className={styles.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="End_date"
            label="End Date"
            type="date"
            value={FormatDateToString(end)}
            onChange={(e) => setEnd(new Date(e.target.value + "T00:00"))}
            className={styles.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="Average"
            label="Average (minutes)"
            type="number"
            value={average}
            fullWidth={false}
            onChange={(e) => setAverage(parseInt(e.target.value))}
            className={styles.textField}
          />
          <div className={styles.downloadButtonContainer}>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={() => handleSubmit([start, end, average])}>
              Set
            </Button>
          </div>
        </Popup>
        <Typography
          className={`${styles.graphCustomTimeSeriesButton} ${styles.graphTimeFrameOption}`}
          onClick={handleOpenModal}
          variant="body2"
        >
          Custom
        </Typography>
      </Fragment>
    );
  };