// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".A3PUpdM1zhoZnunJL8Bl {\n  padding-top: 10px;\n  flex-direction: column;\n  justify-content: center;\n}\n\n.tH4FKF64q8btgaNnrVnS {\n  color: #6a6a6a;\n}\n\n.OUZqmjqeUM3NqcJkdTvR {\n  width: 100%;\n  height: calc(100% - 10px);\n  display: flex;\n  padding-top: 10px;\n}\n\n.eFMGOUtGvXWQPVqqe_Ta {\n  display: none !important;\n  /* background-color: black; */\n}\n\n.YWW_kOwTZF_S6uPEhA7b {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  align-items: center;\n  padding-top: 10px;\n}\n\n.uO9Ip3T9hdzvaU7e8Ims {\n  display: flex;\n  width: 100%;\n  justify-content: end;\n}\n\n.oSg_R6rOmN_vJvfGPmqe {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  align-items: flex-end;\n  padding-top: 10px;\n}", "",{"version":3,"sources":["webpack://./src/areas/client/company/styles/Components.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,wBAAwB;EACxB,6BAA6B;AAC/B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,qBAAqB;EACrB,iBAAiB;AACnB","sourcesContent":[".companyInfoBodyItem {\n  padding-top: 10px;\n  flex-direction: column;\n  justify-content: center;\n}\n\n.companyInfoBodyContent {\n  color: #6a6a6a;\n}\n\n.listContainer {\n  width: 100%;\n  height: calc(100% - 10px);\n  display: flex;\n  padding-top: 10px;\n}\n\n.listHeaderItem {\n  display: none !important;\n  /* background-color: black; */\n}\n\n.addUserButtonContainer {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  align-items: center;\n  padding-top: 10px;\n}\n\n.submitButtonContainer {\n  display: flex;\n  width: 100%;\n  justify-content: end;\n}\n\n.formButtonContainer {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  align-items: flex-end;\n  padding-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"companyInfoBodyItem": "A3PUpdM1zhoZnunJL8Bl",
	"companyInfoBodyContent": "tH4FKF64q8btgaNnrVnS",
	"listContainer": "OUZqmjqeUM3NqcJkdTvR",
	"listHeaderItem": "eFMGOUtGvXWQPVqqe_Ta",
	"addUserButtonContainer": "YWW_kOwTZF_S6uPEhA7b",
	"submitButtonContainer": "uO9Ip3T9hdzvaU7e8Ims",
	"formButtonContainer": "oSg_R6rOmN_vJvfGPmqe"
};
export default ___CSS_LOADER_EXPORT___;
