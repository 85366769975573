import { AxiosRequestConfig } from "axios";
import { API } from "../../api";
import { ApiClient } from "../../utils/ApiClient";
import { StatusRecord } from "../status/models/StatusRecord";
import { Upload } from "../upload/models/Upload";
import { AssembledSystem, System } from "./models/System";
import { SystemAlert } from "./models/SystemAlert";
import { SystemManifestWrapper } from "./models/SystemManifest";

export class SystemService {

  static getManifestById(id: string) {
    const requestConfig: AxiosRequestConfig = {
      method: "GET",
      url: API.server.system.manifest.replace("%id%", id),
    };

    return ApiClient.request(requestConfig).then((response) => {
      return new SystemManifestWrapper().copy(response.model);
    });  
  }

  static getSystemStatusRecordsByRange(sid: string, from: Date, to: Date) {
    const requestConfig: AxiosRequestConfig = {
      method: "GET",
      url: API.server.system.sid.status.replace("%sid%", sid),
      params: {
        start: from,
        end: to,
      },
    };

    return ApiClient.request(requestConfig).then((response) => {
      const records: StatusRecord[] = [];
      response.model.forEach((model) =>
        records.push(new StatusRecord().copy(model))
      );
      return records;
    });
  }

  static getPrimaryUploadsByRange(sid: string, from: Date, to: Date) {
    const requestConfig: AxiosRequestConfig = {
      method: "GET",
      url: API.server.system.sid.uploads.primary.replace("%sid%", sid),
      params: {
        start: from,
        end: to,
      },
    };

    return ApiClient.request(requestConfig).then((response) => {
      const uploads: Upload[] = [];
      response.model.forEach((model) => uploads.push(new Upload().copy(model)));
      return uploads;
    });
  }

  static getAlerts(id: string) {
    const requestConfig: AxiosRequestConfig = {
      method: "GET",
      url: API.server.system.alerts.replace("%id%", id),
    };

    return ApiClient.request(requestConfig).then((response) => {
      const alerts: SystemAlert[] = [];
      response.model.forEach((model) =>
        alerts.push(new SystemAlert().copy(model))
      );
      return alerts;
    });
  }

  public static getOne(id: string) {
    const requestConfig: AxiosRequestConfig = {
      method: "GET",
      url: API.server.system.single.replace("%id%", id),
    };

    return ApiClient.request(requestConfig).then((response) => {
      return new System().copy(response.model);
    });
  }

  public static getAll() {
    const requestConfig: AxiosRequestConfig = {
      method: "GET",
      url: API.server.system.list,
    };

    return ApiClient.request(requestConfig).then((response) => {
      const systems: System[] = [];
      response.model.forEach((model) => systems.push(new System().copy(model)));
      return systems;
    });
  }

  public static getSubsystemsByType(id: string, type: number) {
    const requestConfig: AxiosRequestConfig = {
      method: "GET",
      url: API.server.system.subsystems + `/${type}`,
    };

    return ApiClient.request(requestConfig).then((response) => {
      const systems: System[] = [];
      response.model.forEach((model) => systems.push(new System().copy(model)));
      return systems;
    });
  }

  public static getByCompanyId(id: string) {
    const requestConfig: AxiosRequestConfig = {
      method: "GET",
      url: API.server.company.systems.replace("%id%", id),
    };

    return ApiClient.request(requestConfig).then((response) => {
      const systems: System[] = [];
      response.model.forEach((model) => systems.push(new System().copy(model)));
      return systems;
    });
  }

  static getAssembledSubSystemsByCompany(id: string, type: number) {
    const requestConfig: AxiosRequestConfig = {
      method: "GET",
      url: API.server.company.systems.replace("%id%", id) + `/${type}`,
    };

    return ApiClient.request(requestConfig).then((response) => {
      const systems: AssembledSystem[] = [];
      response.model.forEach((model) =>
        systems.push(new AssembledSystem().copy(model))
      );
      return systems;
    });
  }

  static getAssembledSubSystems(id: string, type: number) {
    const requestConfig: AxiosRequestConfig = {
      method: "GET",
      url: API.server.system.subsystems.replace("%id%", id) + `/${type}`,
    };

    return ApiClient.request(requestConfig).then((response) => {
      const systems: AssembledSystem[] = [];
      response.model.forEach((model) =>
        systems.push(new AssembledSystem().copy(model))
      );
      return systems;
    });
  }

  public static getByParentId(id: string) {
    return this.getByCompanyId(id);
  }

  public static addToCompany(system: System, id: string) {
    const requestConfig: AxiosRequestConfig = {
      method: "POST",
      url: API.server.company.systems.replace("%id%", id),
      data: { systemId: system.id },
    };

    return ApiClient.request(requestConfig);
  }

  static assignToProject(system: System, id: string, project: string) {
    const requestConfig: AxiosRequestConfig = {
      method: "POST",
      url: API.server.company.project.systems.replace("%id%", id).replace("%project%", project),
      data: { systemId: system.id },
    };

    return ApiClient.request(requestConfig);
  }

  public static removeFromCompany(system: System, id: string) {
    const requestConfig: AxiosRequestConfig = {
      method: "DELETE",
      url: API.server.company.systems.replace("%id%", id),
      data: { systemId: system.id },
    };

    return ApiClient.request(requestConfig);
  }

  static addOne(manifest: any) {
    const requestConfig: AxiosRequestConfig = {
      method: "POST",
      url: API.server.system.create,
      data: manifest,
    };

    return ApiClient.request(requestConfig);
  }

  static deleteOne(system: System) {
    const requestConfig: AxiosRequestConfig = {
      method: "DELETE",
      url: API.server.system.list,
      data: { SystemId: system.id },
    };

    return ApiClient.request(requestConfig);
  }

  public static addJournalEntry(sid: string, entry: any) {
    const requestConfig: AxiosRequestConfig = {
      method: "POST",
      url: API.server.system.sid.journal.replace("%sid%", sid),
      data: entry,
    };

    return ApiClient.request(requestConfig);
  }
}
