import { BackdropProps } from "@mui/material";
import React from "react";
import { ModelApi } from "./ApiClient";
import { BaseStore, IActions, IState } from "./BaseStore";
import { FormModel } from "./FormModel";

export interface IModelState<T> extends IState {
  collection: T[];
  parentCollection: T[];
  highlighted: T | undefined;
  highlightedParent: FormModel | undefined;
  lastUpdatedCollection: Date | undefined;
  lastUpdatedHighlighted: Date | undefined;
  lastUpdatedParentCollection: Date | undefined;
  api: any;
  cachedCapacity: number;
}

export interface IModelActions<T> extends IActions {
  updateCollection(): Promise<T[]>;
  updateSingle(id: string): Promise<T>;
  updateParentCollection(parent: any): Promise<T[]>;
  getCachedCollection(): Promise<T[]>;
  getCachedParentCollection(parent: any): Promise<T[]>;
  handleUpdateAll(update: any): void;
  handleUpdateSingle(update: any): void;
  handleParentUpdateAll(update: any): void;
  setHighlighted(highlighted: T | undefined, update?: boolean);
  deleteHighlighted(): Promise<boolean>;
  updateHighlighted(id: string | undefined): Promise<T>;
  getCachedHighlighted(id: string): Promise<T | undefined>;
  // initializeWebsockets(): void;
}

// README
// Model Store: A caching mechanism for retrieving and caching models from the server. 
// Collections that are cached: 
// 1) Single model
// 2) Parent collection: children models that belong to a unique parent
// 3) Total collection: all models queryable in server

// API service needs to support the following methods to tap into cache system: 
//  getByParentId(id)
//  getAll()
//  getOne(id)
//  deleteOne(model)

// getCached... requests made through this api will first search for cached models for quick resolution.
// They will request updated models from the server if there's nothing cached. 

export class ModelStore<
    T,
    State extends IModelState<T>,
    Actions extends IModelActions<T>
  >
  extends BaseStore<State, Actions>
  implements IModelActions<T>
{
  public updateParentCollection(parent: FormModel): Promise<T[]> {

    return new Promise<T[]>((resolve, reject) => {
      this.state.api
        .getByParentId(parent.id)
        .then((models) => {
          resolve(models);
          this.setState({
            ...this.state,
            parentCollection: models, //.slice(0, this.state.cachedCapacity),
            highlightedParent: parent,
            lastUpdatedParentCollection: new Date(),
            highlighted: models.find(
              (m) => m.id == (this.state.highlighted as any)?.id
            ),
            lastUpdatedHighlighted: new Date(),
          });
        })
        .catch(reject);
    });
  }

  public getCachedParentCollection(parent: FormModel): Promise<T[]> {

    return new Promise<T[]>((resolve, reject) => {
      if (
        this.state.lastUpdatedParentCollection &&
        this.state.highlightedParent?.id == parent.id
      ) {
        resolve(this.state.parentCollection);
        return;
      }

      resolve(this.updateParentCollection(parent));
    });
  }

  public updateCollection(): Promise<T[]> {
    return new Promise<T[]>((resolve, reject) => {
      this.state.api
        .getAll()
        .then((models) => {
          let highlighted = this.state.highlighted;

          if (highlighted)
            highlighted = models.find(
              (m) => m.id == (this.state.highlighted as any)?.id
            );

          resolve(models);
          this.setState({
            ...this.state,
            collection: models, //.slice(0, this.state.cachedCapacity),
            lastUpdatedCollection: new Date(),
            highlighted: highlighted,
            lastUpdatedHighlighted: new Date(),
          });
        })
        .catch(reject);
    });
  }

  public getCachedCollection(): Promise<T[]> {
    return new Promise<T[]>((resolve, reject) => {
      if (this.state.lastUpdatedCollection) {
        resolve(this.state.collection);
        return;
      }

      resolve(this.updateCollection());
    });
  }

  public updateSingle(id: string): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.state.api
        .getOne(id)
        .then((model) => {
          let highlighted = this.state.highlighted;

          const parentCollection = this.state.parentCollection.map((m) => {
            if ((m as any).id == model.id) return model;
            return m;
          });

          const collection = this.state.collection.map((m) => {
            if ((m as any).id == model.id) return model;
            return m;
          });

          if (highlighted && (highlighted as any).id == model.id)
            highlighted = model;

          resolve(model);
          this.setState({
            ...this.state,
            collection: collection,
            parentCollection: parentCollection,
            highlighted: highlighted,
          });
        })
        .catch(reject);
    });
  }

  public updateHighlighted(id: string | undefined): Promise<T> {

    if (!id && this.state.highlighted)
      id = (this.state.highlighted as any).id

    
    return this.state.api.getOne(id).then((model) => {
      this.setState({
        ...this.state,
        highlighted: model,
        lastUpdatedHighlighted: new Date(),
      });
      return model;
    });
  }

  public getCachedHighlighted(id: string): Promise<T | undefined> {
    return new Promise<T | undefined>((resolve, reject) => {
      if (this.state.lastUpdatedHighlighted) {
        resolve(this.state.highlighted);
        return;
      }

      resolve(this.updateHighlighted(id));
    });
  }

  handleUpdateAll(update: any): void {
    this.updateCollection().catch(console.log);
  }

  handleUpdateSingle(id: any): void {
    if (id) this.updateSingle(id).catch(console.log);
    // setTimeout(() => {if (id) this.updateSingle(id).catch(console.log);}, 0)
    
  }

  handleParentUpdateAll(update: any): void {
    const parent = this.state.highlightedParent;

    if (parent) this.updateParentCollection(parent).catch(console.log);
  }

  public deleteHighlighted(): Promise<boolean> {
    if (!this.state.highlighted)
      return new Promise((resolve, reject) => reject);

    return new Promise((resolve, reject) => {
      resolve(this.state.api.deleteOne(this.state.highlighted));
    });
  }

  public setHighlighted(model: T, update?: boolean) {
    if (
      model &&
      this.state.highlighted &&
      (model as any).id == (this.state.highlighted as any).id
    )
      return;

    if (update) {
      this.updateSingle((model as any).id).then(model => {
        this.setState({
          ...this.state,
          highlighted: model,
        });
      })
    } else {
      this.setState({
        ...this.state,
        highlighted: model,
      });
    }
      

    
  }
}
