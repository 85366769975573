
import {
  IModelActions,
  IModelState,
  ModelStore
} from "../../utils/ModelStore";
import { EventService } from "./EventService";
import { ChangeEvent } from "./models/Event";

export interface EventsState extends IModelState<ChangeEvent> {}

export interface EventsActions extends IModelActions<ChangeEvent> {
}

export class EventsStore
  extends ModelStore<ChangeEvent, EventsState, EventsActions>
  implements EventsActions
{
  protected prototype = EventsStore.prototype;
  protected initialState = {
    collection: [],
    parentCollection: [],
    highlighted: undefined,
    highlightedParent: undefined,
    lastUpdatedCollection: undefined,
    lastUpdatedHighlighted: undefined,
    lastUpdatedParentCollection: undefined,
    cachedCapacity: 10,
    api: EventService
  };

  constructor() {
    super();
  }
}