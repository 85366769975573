import { Button } from "@mui/material";
import React, { FunctionComponent, useState } from "react";
import Loader from "react-spinners/BarLoader";
import { AppStore } from "../../../../AppStore";
import { Form } from "../../../../components/form/FormElement";
import { CompanyService } from "../../../../services/company/CompanyService";
import { Company } from "../../../../services/company/models/Company";
import styles from "../styles/Components.css";

interface Props {
  onSubmit?: () => void;
}

export const AddCompanyForm: FunctionComponent<Props> = (props) => {
  const [state, actions] = AppStore.companies.use();
  const [model] = useState(new Company());
  const [loading, setLoading] = useState(false);

  const handleSubmit = (company) => {
    setLoading(true)

    return CompanyService.addOne(company)
      .then(actions.updateCollection)
      .then(props.onSubmit)
      .catch(console.log)
      .finally(() => setLoading(false))
  };

  return (
    <Form model={model} onSubmit={handleSubmit}>
      <Form.Item field="name">
        <Form.Input
          fullWidth
          placeholder="2B Technologies"
          label="Company name"
        />
      </Form.Item>
      <Form.Item field="location">
        <Form.Input
          fullWidth
          placeholder="Boulder, Colorado"
          label="Location"
        />
      </Form.Item>
      <Form.Item field="contactName">
        <Form.Input fullWidth label="Contact Name" placeholder="John Doe" />
      </Form.Item>
      <Form.Item field="contactEmail">
        <Form.Input
          fullWidth
          label="Contact Email"
          placeholder="john@doe.com"
        />
      </Form.Item>
      <Form.Item field="description">
        <Form.Input
          fullWidth
          placeholder="Your super cool company!"
          label="Description"
        />
      </Form.Item>
      <Form.Item field="submit">
        <div className={styles.submitButtonContainer}>
          {loading ? <Loader /> : <Button variant="contained">Submit</Button>}
        </div>
      </Form.Item>
    </Form>
  );
};
