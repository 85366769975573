import { Divider } from "@mui/material";
import React, { FunctionComponent, useEffect } from "react";
import { AppStore } from "../../../../AppStore";
import {
  GridCard,
  GridCardItem,
  GridCardSection,
  GridFormCard,
} from "../../../../components/gridManager/components/GridCard";
import { IGridComponent } from "../../../../components/gridManager/GridManager";
import { CompanyService } from "../../../../services/company/CompanyService";
import { UserRole } from "../../../../services/user/models/User";

interface Props extends IGridComponent {}

const CompanyInfo: FunctionComponent<Props> = (props) => {
  const [state, actions] = AppStore.companies.use();
  const [account] = AppStore.account.use();

  const handleSubmit = (model) => {
    return CompanyService.updateOne(model)
    .then(() => actions.updateHighlighted(undefined))
    .catch(console.log);
  };

  return (
    <GridFormCard
      model={state.highlighted}
      icon="./icons/office-building.png"
      title="Company Info"
      onSubmit={handleSubmit}
      loading={!state.highlighted}
      editable={
        account.user && UserRole[account.user.role] == UserRole.CompanyAdmin
      }
    >
      <GridCardSection title="Name">
        <GridCardItem formElement="input" field="name" />
      </GridCardSection>
      <GridCardSection title="Contact">
        <GridCardItem formElement="input" field="contactName" />
        <GridCardItem formElement="input" field="contactEmail" />
      </GridCardSection>
      <GridCardSection title="Location">
        <GridCardItem formElement="input" field="location" />
      </GridCardSection>
      <GridCardSection>
        <Divider />
      </GridCardSection>
      <GridCardSection title="Description">
        <GridCardItem formElement="input" field="description" />
      </GridCardSection>
    </GridFormCard>
  );
};

export default CompanyInfo;
