import { DataGrid, GridColumns } from "@mui/x-data-grid";
import React, { FunctionComponent, useEffect, useState } from "react";
import { AppStore } from "../../../../AppStore";
import { GridCard } from "../../../../components/gridManager/components/GridCard";
import { EventService } from "../../../../services/event/EventService";
import { ChangeEvent } from "../../../../services/event/models/Event";
import styles from "../styles/Components.css";

interface Props {}

const EventsList: FunctionComponent<Props> = (props) => {
  const [companies, companiesActions] = AppStore.companies.use();
  const [events, setEvents] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  const columns: GridColumns = [
    {
      field: "user",
      headerName: "Originator",
      width: 200,
    },
    {
      field: "message",
      headerName: "Message",
      width: 400,
    },
    {
      field: "date",
      headerName: "Date",
      width: 230,
    },
  ];

  const handleEvents = (events: ChangeEvent[]) => {
    const processed: any[] = [];

    events.forEach((event, index) => {
      processed.push({
        user: event.user,
        message: event.message,
        date: event.dateGenerated,
        id: index,
      });
    });
    return processed;
  };

  useEffect(() => {
    EventService.getAll()
      .then(handleEvents)
      .then(setEvents)
      .then(() => setLoading(false))
      .catch(console.log);
  }, []);

  return (
    <GridCard icon="./icons/group.png" title="Events">
      <div className={styles.listContainer}>
        <DataGrid
          rows={events}
          columns={columns}
          loading={loading}
          pageSize={20}
          rowHeight={30}
          headerHeight={30}
          rowsPerPageOptions={[20]}
          sx={{
            "& .MuiDataGrid-columnSeparator": {
              visibility: "hidden",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#e9e9e9",
            },
          }}
        />
      </div>
    </GridCard>
  );
};

export default EventsList;
