import CheckBox from "@mui/material/Checkbox";
import React, { FunctionComponent, useEffect, useState } from "react";
import { AppStore } from "../../../../AppStore";
import { Device } from "../../../../services/device/models/Device";

interface Props {
  device: Device;
  onClick: (event, device) => void;
}

export const CheckBoxWrapper: FunctionComponent<Props> = (props) => {
  const ref = React.createRef<any>();
  const [checked, setChecked] = useState(false);
  const [analysisState, analysisActions] = AppStore.analysis.use();

  const handleClick = (event) => {
    props.onClick(event, props.device);
  };

  useEffect(() => {
    setChecked(
      analysisState.graphedDevices.find((d) => d.id == props.device.id) !=
        undefined
    );
  }, [analysisState.graphedDevices]);

  return (
    <>
      <CheckBox
        ref={ref}
        defaultChecked={
          analysisState.graphedDevices.find((d) => d.id == props.device.id) !=
          undefined
        }
        checked={checked}
        onChange={handleClick}
      />
    </>
  );
};
