import { ServiceConnection } from "./utils/ServiceConnection";

export enum ApplicationMode {
    development = "development",
    production = "production"
}

type Services = "api" | "identity";

const environment = process.env.NODE_ENV ?? "development"
const applicationMode = ApplicationMode[environment]

const connections = require("../connections.json")[
    process.env.CONNECTIONS ?? applicationMode];
    
const auth0 = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN ?? "",
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID ?? "",
    audience: process.env.REACT_APP_AUTH0_AUDIENCE ?? ""
}

export const ENV = {
    mode: applicationMode,
    connections: ServiceConnection.import<Services>(connections),
    auth0: auth0,
    title: "Instrument Portal",
    appName: "2B Technologies Device Dashboard" 
}

