import {
  IModelActions,
  IModelState,
  ModelStore
} from "../../utils/ModelStore";
import { CompanyService } from "./CompanyService";
import { Company } from "./models/Company";

export interface CompaniesState extends IModelState<Company> {}

export interface CompaniesActions extends IModelActions<Company> {
}

export class CompaniesStore
  extends ModelStore<Company, CompaniesState, CompaniesActions>
  implements CompaniesActions
{
  protected prototype = CompaniesStore.prototype;
  protected initialState = {
    collection: [],
    parentCollection: [],
    highlighted: undefined,
    highlightedParent: undefined,
    lastUpdatedCollection: undefined,
    lastUpdatedHighlighted: undefined,
    lastUpdatedParentCollection: undefined,
    cachedCapacity: 30,
    api: CompanyService
  };

  constructor() {
    super();
  }
}