import { Button } from "@mui/material";
import React, { FunctionComponent, useState } from "react";
import RGL, { WidthProvider } from "react-grid-layout";
import { AppStore } from "../../../AppStore";
import Popup from "../../../components/popup/Popup";
import styles from "../styles/Components.css";
import SystemDetailGrid from "./SystemDetailGrid/Grid";

const GridLayout = WidthProvider(RGL);

interface Props {
  onDismiss: () => void;
  show?: boolean;
}

const SystemStatusModal: FunctionComponent<Props> = (props) => {
  const [state, actions] = AppStore.systems.use();
  const [section, setSection] = useState(0);

  const options = {
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    yAxis: {
      title: {
        text: "Measurements",
      },
    },
    xAxis: {
      title: {
        text: "Time (s)",
      },
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
      },
    },
    series: [
      {
        name: "Installation",
        data: [43934, 52503, 57177, 69658, 97031, 119931, 137133, 154175],
      },
    ],
  };

  const createGridItem = (element: JSX.Element | undefined, index: number) => {
    if (element) {
      return (
        <div key={index} className={styles.detailCard}>
          {React.cloneElement(element, { ...element.props, gridIndex: index })}
        </div>
      );
    }
  };

  return (
    <Popup visible={props.show} onDismiss={props.onDismiss}>
      <div className={styles.deviceDetailsHeader}>
        <div className={styles.deviceDetailsHeaderTitle}>
          <h3 className={styles.deviceTitle}>{state.highlighted?.sid}</h3>
          <h2 className={styles.deviceSubtitle}>Detailed System Status</h2>
        </div>
        <Button className={styles.dismissModalButton} onClick={props.onDismiss}>
          x
        </Button>
      </div>
      <div
        className={`${styles.deviceDetailsBody}`}
      >
        {/* <div className={styles.deviceDetailsContainer}>
          <SystemUpTimeChart />
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div> */}
        <GridLayout
          layout={SystemDetailGrid.layout}
          // onLayoutChange={props.onLayoutChange}
          className={`layout`}
          compactType={null}
          rowHeight={20}
          cols={12}
          margin={[5, 5]}
          items={SystemDetailGrid.layout.length}
        >
          {SystemDetailGrid.components.map(createGridItem)}
        </GridLayout>
        {/* <SystemUpTimeChart /> */}
      </div>
    </Popup>
  );
};

export default SystemStatusModal;
