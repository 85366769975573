import { AccountStore } from "./services/account/AccountStore";
import { DrawerStore } from "./components/drawer/DrawerStore";
import { GridManagerStore } from "./components/gridManager/GridManagerStore";
import { NavigationBarStore } from "./components/navigationbar/NavigationBarState";
import { CompaniesStore } from "./services/company/CompaniesStore";
import { DevicesStore } from "./services/device/DeviceStore";
import { DiagnosticsStore } from "./services/diagnostic/DiagnosticStore";
import { EventsStore } from "./services/event/EventsStore";
import { StatusRecordsStore } from "./services/status/StatusRecordStore";
import { SystemsStore } from "./services/system/SystemsStore";
import { UsersStore } from "./services/user/UsersStore";
import { NotificationStore } from "./services/notifications/NotificationStore";
import { DashboardStore } from "./areas/client/DashboardStore";
import { AnalysisStore } from "./services/analysis/AnalysisStore";

const AppStore = {
  account: new AccountStore(),
  grid: new GridManagerStore(),
  navbar: new NavigationBarStore(),
  drawer: new DrawerStore(),
  companies: new CompaniesStore(),
  users: new UsersStore(),
  systems: new SystemsStore(),
  dashboard: new DashboardStore(),
  devices: new DevicesStore(),
  diagnostics: new DiagnosticsStore(),
  events: new EventsStore(),
  statusRecords: new StatusRecordsStore(),
  notifications: new NotificationStore(),
  analysis: new AnalysisStore()
};

export { AppStore };
