import { util } from "webpack";
import { BaseStore } from "../../utils/BaseStore";
import { FormModel } from "../../utils/FormModel";
import { Grid, GridCardExpand } from "./components/Grid";

export interface GridManagerState {
  grid: Grid | undefined;
  previousGrid: Grid;
  defaultGrid: Grid;
  loading: boolean;
  locked: boolean;
  primaryTitle: string;
  secondaryTitle: string;
  expandedCard: GridCardExpand;
  scaledCards: GridCardExpand[];
  scrolling: boolean;
  utilities: JSX.Element[] | undefined;
  onEditPrimaryTitle: ((newTitle: string) => void) | undefined;
}

export interface GridMangerActions {
  clear();
  setScrolling(scrolling: boolean): void;
  setLoading(loading: boolean): void;
  updateGridLayout(layout: any);
  initGrid(grid: Grid | undefined): void;
  // setGrid(grid: Grid | undefined): void;
  scaleCard(expand: GridCardExpand): void;
  setLocked(locked: boolean): void;
  setPrimaryTitle(value?: string): void;
  setSecondaryTitle(value?: string): void;
  toggleExpandedCard(expand: GridCardExpand): void;
  setUtilities(utilites: JSX.Element[] | undefined): void;
  setOnEditPrimaryTitle: (
    handler: ((newTitle: string) => void) | undefined
  ) => void;
}

export class GridManagerStore
  extends BaseStore<GridManagerState, GridMangerActions>
  implements GridMangerActions
{
  protected prototype = GridManagerStore.prototype;
  protected initialState = {
    grid: new Grid(),
    previousGrid: new Grid(),
    defaultGrid: new Grid(),
    loading: true,
    locked: true,
    primaryTitle: "",
    secondaryTitle: "",
    expandedCard: new GridCardExpand(-1, 1),
    scaledCards: [],
    scrolling: false,
    utilities: undefined,
    onEditPrimaryTitle: undefined,
  };

  constructor() {
    super();
  }

  public clear() {
    this.setState(this.initialState);
  }

  public initGrid(grid: Grid) {
    this.setState({
      ...this.state,
      defaultGrid: grid,
      grid: grid,
      previousGrid: grid,
      expandedCard: new GridCardExpand(-1, 1),
    });
  }

  // public setGrid(grid: Grid) {
  //   this.setState({
  //     ...this.state,
  //     grid: grid,
  //     rerender: true,
  //     // previousGrid: this.state.grid ? this.state.grid : this.state.previousGrid,
  //     expandedCard: new GridCardExpand(-1, 1),
  //   });
  // }

  public setLocked(locked: boolean) {
    this.setState({
      ...this.state,
      locked: locked,
    });
  }

  public setLoading(loading: boolean) {
    this.setState({
      ...this.state,
      loading: loading,
    });
  }

  public updateGridLayout(layout: any) {
    if (this.state.grid) {
      const newGrid = new Grid();

      newGrid.id = this.state.grid.id;
      newGrid.components = this.state.grid.components;
      newGrid.layout = layout;

      this.setState({
        ...this.state,
        previousGrid: this.state.grid,
        grid: newGrid,
      });
    }
  }

  public setSecondaryTitle(value: string) {
    this.setState({
      ...this.state,
      secondaryTitle: value,
    });
  }

  public setPrimaryTitle(value: string) {
    this.setState({
      ...this.state,
      primaryTitle: value,
    });
  }

  public toggleExpandedCard(card: GridCardExpand) {
    if (card.index == this.state.expandedCard.index) {
      this.setState({
        ...this.state,
        grid: this.state.previousGrid,
        expandedCard: new GridCardExpand(-1, 1),
      });
    } else if (this.state.expandedCard.index != -1) {
      this.setState({
        ...this.state,
        grid: this.state.previousGrid,
        expandedCard: card,
      });
    } else {
      this.setState({
        ...this.state,
        expandedCard: card,
      });
    }
  }

  public scaleCard(card: GridCardExpand): void {
    const cards: GridCardExpand[] = [];

    this.state.scaledCards.forEach((c) => cards.push(c));
    const existing = cards.find((c) => c.index == card.index);

    if (existing) cards[cards.indexOf(existing)] = card;
    else cards.push(card);

    this.setState({
      ...this.state,
      scaledCards: cards,
      grid: this.state.defaultGrid
    });
  }

  public setUtilities(utilities: JSX.Element[] | undefined) {
    this.setState({
      ...this.state,
      utilities: utilities,
    });
  }

  public setScrolling(scrolling: boolean) {
    this.setState({
      ...this.state,
      scrolling: scrolling,
    });
  }

  public setOnEditPrimaryTitle(
    handler: ((newTitle: string) => void) | undefined
  ) {
    this.setState({
      ...this.state,
      onEditPrimaryTitle: handler,
    });
  }
}
