import { CheckboxProps, MenuItem, Select, Typography } from "@mui/material";
import React, { FunctionComponent } from "react";
import { FormItemProps } from "../FormElement";
import styles from "../styles/Components.css";

interface Props
  extends Omit<CheckboxProps, "value" | "defaultValue">,
    FormItemProps<string> {
  options: any[];
  valueModifier?: (value: any) => string;
}

const FormDropdown: FunctionComponent<Props> = (props) => {
  const {
    label,
    errorText,
    helperText,
    style,
    onBlur,
    onFocus,
    onUpdate,
    _controlled,
    ...rest
  } = props;
  const [value, setValue] = React.useState("");

  React.useEffect(() => {
    const val = props.defaultValue ?? "";
    handleChange(val);
    setValue(val);
  }, [props.defaultValue]);

  const handleChange = (event) => {
    const selected = event.target?.value;

    if (selected) {
      props.onUpdate?.(selected);
      setValue(selected);
    }
  };

  const mapOptions = () => {
    return props.options.map((o, index) => {
      return (
        <MenuItem key={index} value={o}>
          {props.valueModifier ? props.valueModifier(o) : o}
        </MenuItem>
      );
    });
  };

  return (
    <div>
      <Typography variant="caption">
        {label}
      </Typography>
      <Select
        className={styles.dropdownContainer}
        value={value}
        onChange={handleChange}
      >
        {mapOptions()}
      </Select>

      <Typography variant="caption">
        {errorText ? "*" : ""}
      </Typography>
    </div>
  );
};

FormDropdown.defaultProps = {
  _type: "FormItem",
};

export { FormDropdown };
