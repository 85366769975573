import React, { FunctionComponent, useState } from "react";
import { GridCard } from "../../../components/gridManager/components/GridCard";
import { IGridComponent } from "../../../components/gridManager/GridManager";
interface Props extends IGridComponent {}

const DisclaimerElement: FunctionComponent<Props> = (props) => {

  return (
    <GridCard
      gridIndex={props.gridIndex}
      icon="./icons/info.png"
      title="Disclaimer"
      backGroundColor='#fffde7'
    >
      <h2> Note data in the graph below is in local time. All downloaded data is in UTC time. </h2>
    </GridCard>
  );
};

export default DisclaimerElement;
