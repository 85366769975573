// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pdhYw31SyiB5sMuEg7do {\n    max-width: none !important;\n    min-width: 400px;\n}\n\n.MoD_pu06VTsi9mEUWVvD {\n    font-weight: 700 !important;\n    padding: 10px 24px !important;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n}\n\n.XkyP7LPGW_JGuKj_jwWI {\n    overflow-x: hidden;\n}", "",{"version":3,"sources":["webpack://./src/components/popup/styles/Popup.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,gBAAgB;AACpB;;AAEA;IACI,2BAA2B;IAC3B,6BAA6B;IAC7B,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".container {\n    max-width: none !important;\n    min-width: 400px;\n}\n\n.dialogTitle {\n    font-weight: 700 !important;\n    padding: 10px 24px !important;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n}\n\n.dialogContent {\n    overflow-x: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "pdhYw31SyiB5sMuEg7do",
	"dialogTitle": "MoD_pu06VTsi9mEUWVvD",
	"dialogContent": "XkyP7LPGW_JGuKj_jwWI"
};
export default ___CSS_LOADER_EXPORT___;
