import React from "react";
import { Grid } from "../../../../components/gridManager/components/Grid";
import Map from "../components/Map";

const MapGrid = new Grid();
MapGrid.id = "Map"
MapGrid.components = [<Map />];
MapGrid.layout = [
  {
    x: 0,
    y: 0,
    w: 12,
    h: 22,
    i: "0",
    static: true,
  },
];

export default MapGrid;
