import { Typography } from "@mui/material";
import React from "react";
import { Grid } from "../../../../components/gridManager/components/Grid";
import CompanyInfo from "../components/CompanyInfo";
import EventsList from "../components/EventsList";
import SystemsList from "../components/SystemsList";
import UsersList from "../components/UsersList";


const CompaniesGrid = new Grid();
CompaniesGrid.id = "Companies"
CompaniesGrid.components = [
  <CompanyInfo />,
  <EventsList />,
  <UsersList />,
  <SystemsList />
];
CompaniesGrid.layout = [
  {
    x: 0,
    y: 0,
    w: 5,
    h: 10,
    i: "0",
    static: true,
  },
  {
    x: 6,
    y: 0,
    w: 7,
    h: 10,
    i: "1",
    static: true,
  },
  {
    x: 0,
    y: 10,
    w: 6,
    h: 10,
    i: "2",
    static: true,
  }
  ,
  {
    x: 7,
    y: 10,
    w: 6,
    h: 10,
    i: "3",
    static: true,
  }
];

export default CompaniesGrid;