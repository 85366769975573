import { Typography } from "@mui/material";
import React from "react";
import { Grid } from "../../../../components/gridManager/components/Grid";
import CompanyInfo from "../components/CompanyInfo";
import EventsList from "../components/EventsList";
import ProjectsList from "../components/ProjectsList";
import SystemsList from "../components/SystemsList";
import UsersList from "../components/UsersList";


const CompanyGrid = new Grid();
CompanyGrid.id = "Company"
CompanyGrid.components = [
  <CompanyInfo />,
  <EventsList />,
  <UsersList />,
  <SystemsList />,
  <ProjectsList />

];
CompanyGrid.layout = [
  {
    x: 0,
    y: 0,
    w: 5,
    h: 10,
    i: "0",
    static: true,
  },
  {
    x: 6,
    y: 0,
    w: 7,
    h: 10,
    i: "1",
    static: true,
  },
  {
    x: 0,
    y: 10,
    w: 6,
    h: 10,
    i: "2",
    static: true,
  },
  {
    x: 7,
    y: 10,
    w: 6,
    h: 10,
    i: "3",
    static: true,
  },
  {
    x: 0,
    y: 20,
    w: 6,
    h: 10,
    i: "4",
    static: true,
  }
];

export default CompanyGrid;