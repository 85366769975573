import { Device } from "../../services/device/models/Device";
import { BaseStore } from "../../utils/BaseStore";

export interface DashboardState {
  highlightedDevices: Device[]
}

export interface DashboardActions {
  setHighlightedDevices: (devices: Device[]) => void
}

export class DashboardStore
  extends BaseStore<DashboardState, DashboardActions>
  implements DashboardActions
{
  protected prototype = DashboardStore.prototype;
  protected initialState = {
    highlightedDevices: []
  };

  constructor() {
    super();
  }

  public setHighlightedDevices(devices: Device[] | undefined) {
    this.setState({
      highlightedDevices: devices ? devices : []
    })
  }
}
