import React, {
  createRef,
  Fragment,
  FunctionComponent,
  Ref,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { AppStore } from "../../../../AppStore";
import { DrawerItem } from "../../../../components/drawer/components/DrawerItem";
import { DrawerSection } from "../../../../components/drawer/components/DrawerSection";
import { FormatStringToDate } from "../../../../helpers/time";
import { NotificationContext } from "../../../../providers/Notifications";
import { Company } from "../../../../services/company/models/Company";
import { Device } from "../../../../services/device/models/Device";
import { CheckBoxWrapper } from "../components/CheckBox";
import AnalysisGrid from "./Grid";

interface Props {}

const Body: FunctionComponent<Props> = (props) => {
  const [account] = AppStore.account.use();
  const [companyState, companyActions] = AppStore.companies.use();
  const [deviceState, deviceActions] = AppStore.devices.use();
  const [state, actions] = AppStore.systems.use();
  const [, gridActions] = AppStore.grid.use();
  const [, drawerActions] = AppStore.drawer.use();
  const [, companiesActions] = AppStore.companies.use();
  const [analysisState, analysisActions] = AppStore.analysis.use();
  const { clearNotifications } = useContext(NotificationContext);
  const graphedDevicesReference = useRef<Device[]>(
    analysisState.graphedDevices
  );
  const [checkBoxRefs, setCheckBoxRefs] = useState<
    React.RefObject<HTMLButtonElement>[]
  >([]);

  const handleSelected = (device: any) => {};

  const handleAppendSelected = (event: any, device: Device) => {
    const checked = event.target.checked;
    let newDevices: Device[] = [];

    graphedDevicesReference.current.forEach((dev) => {
      newDevices.push(dev);
    });

    if (checked) newDevices.push(device);
    else newDevices = newDevices.filter((d) => d.id != device.id);

    graphedDevicesReference.current = newDevices;
  };

  const updateDrawer = (company: Company) => {
    actions.getCachedParentCollection(company).then((systems) => {
      const sections: DrawerSection[] = [];
      const newRefs: React.RefObject<HTMLButtonElement>[] = [];
      let highlighted: DrawerItem | undefined;

      systems.forEach((system, index) => {
        const section = new DrawerSection();
        const items: DrawerItem[] = [];

        section.index = index;
        section.name = system.ssid;

        deviceActions.getCachedParentCollection(system).then((devices) => {
          devices.forEach((device, index) => {
            const utilities: JSX.Element[] = [];
            const newRef = createRef<HTMLButtonElement>();

            utilities.push(
              <CheckBoxWrapper device={device} onClick={handleAppendSelected} />
            );

            const drawerItem = new DrawerItem();

            drawerItem.primary = device.name;
            drawerItem.secondary = device.ssid;
            drawerItem.model = device;
            drawerItem.children = utilities;
            drawerItem.onClick = handleSelected;

            items.push(drawerItem);
            newRefs.push(newRef);
          });
        });

        section.items = items;
        sections.push(section);
      });

      drawerActions.setSections(sections);
      setCheckBoxRefs(newRefs);
      drawerActions.setExpandedSection(undefined);
    });
  };

  useEffect(() => {
    clearNotifications();
    
    gridActions.clear();
    gridActions.initGrid(AnalysisGrid);
    drawerActions.setTitle("device");

    if (!analysisState.currentGraph) {
      const to = new Date();
      const from = new Date();

      from.setDate(to.getDate() - 1);

      analysisActions.setTimeSeries([from, to, 0]);
    }
  }, []);

  useEffect(() => {
    analysisActions.setGraphedDevices(graphedDevicesReference.current);
  }, [graphedDevicesReference.current]);

  useEffect(() => {
    if (analysisState.graphedDevices != graphedDevicesReference.current)
      graphedDevicesReference.current = analysisState.graphedDevices;
  }, [analysisState.graphedDevices]);

  useEffect(() => {
    if (!companyState.highlighted) return;

    updateDrawer(companyState.highlighted);

    gridActions.setPrimaryTitle("Analysis");
    gridActions.setLoading(false);
  }, [companyState.highlighted]);

  return <Fragment />;
};

export default Body;
