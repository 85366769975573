import { BaseStore } from "../../utils/BaseStore";
import { Form } from "../form/FormElement";
import { DrawerItem } from "./components/DrawerItem";
import { DrawerSection } from "./components/DrawerSection";

export interface DrawerState {
  drawerToggled: boolean;
  animating: boolean;
  items: DrawerItem[] | undefined;
  sections: DrawerSection[] | undefined;
  highlighted: DrawerItem | undefined;
  title: string;
  addModelForm: JSX.Element | undefined;
  addModelFormTitle: string | undefined; 
  expandedSection: number | undefined;
}

export interface DrawerActions {
  setItems(items: DrawerItem[] | undefined);
  setSections(sections: DrawerSection[] | undefined);
  toggleDrawer(toggle: boolean);
  setHighlighted(item: DrawerItem | undefined);
  setTitle(title: string);
  setAddModelForm(form: JSX.Element | undefined);
  setAddModelFormTitle(title: string | undefined);
  setAnimating(animating: boolean);
  setExpandedSection(section: number | undefined);
}

export class DrawerStore
  extends BaseStore<DrawerState, DrawerActions>
  implements DrawerActions
{
  protected prototype = DrawerStore.prototype;
  protected initialState = {
    drawerToggled: true,
    animating: false,
    items: [],
    sections: [],
    highlighted: undefined,
    title: "",
    addModelForm: undefined,
    expandedSection: undefined,
    addModelFormTitle: undefined
  };

  constructor() {
    super();
  }

  public toggleDrawer(toggle: boolean) {
    this.setState({
      ...this.state,
      animating: true,
      drawerToggled: toggle,
    });
  }

  public setItems(items: DrawerItem[]) {
    this.setState({
      ...this.state,
      items: items,
    });
  }

  public setSections(sections: DrawerSection[] | undefined) {
    this.setState({
      ...this.state,
      sections: sections,
    });
  }

  public setHighlighted(item: DrawerItem) {

    this.setState({
      ...this.state,
      highlighted: item,
    });
  }

  public setTitle(title: string) {
    this.setState({
      ...this.state,
      title: title,
    });
  }

  public setAddModelForm(form: JSX.Element | undefined) {
    this.setState({
      ...this.state,
      addModelForm: form,
    });
  }

  public setAddModelFormTitle(title: string | undefined) {
    this.setState({
      ...this.state,
      addModelFormTitle: title,
    });
  }

  public setAnimating(animating: boolean) {
    this.setState({
      ...this.state, 
      animating: animating,
    });
  }

  public setExpandedSection(section: number) {
    this.setState({
      ...this.state,
      expandedSection: section,
    });
  }
}
