import { BaseStore } from "../../utils/BaseStore";

export interface NavigationBarState {
 
}

export interface NavigationBarActions {
  
}

export class NavigationBarStore
  extends BaseStore<NavigationBarState, NavigationBarActions>
  implements NavigationBarActions
{
  protected prototype = NavigationBarStore.prototype;
  protected initialState = {
    
  };

  constructor() {
    super();
  }

  
}
