import { BaseStore } from "../../utils/BaseStore";
import { INotification } from "./models/Notification";


export interface NotificationState {
    notifications: INotification[],

}

export interface NotificationActions {
    addNotification(notification: INotification);
    clearNotifications();
}

export class NotificationStore 
    extends BaseStore<NotificationState, NotificationActions> 
    implements NotificationActions {

    protected prototype = NotificationStore.prototype;
    protected initialState = {
        notifications: []
    }

    constructor() {
        super();                
    }

    public addNotification(notification: INotification) {
        const newNotifications: INotification[] = []

        this.state.notifications.forEach(n => newNotifications.push(n))
        newNotifications.push(notification)

        this.setState({
            notifications: newNotifications
        })
    }

    public clearNotifications() {
        this.setState({
            notifications: []
        })
    }

}