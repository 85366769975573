import { Divider } from "@mui/material";
import React, { FunctionComponent, useEffect } from "react";
import { AppStore } from "../../../../AppStore";
import {
  GridCard,
  GridCardItem,
  GridCardSection,
  GridFormCard,
} from "../../../../components/gridManager/components/GridCard";
import {
  User,
  UserRole,
  UserRoleRender,
} from "../../../../services/user/models/User";
import { UserService } from "../../../../services/user/UserService";

interface Props {}

const UserInfo: FunctionComponent<Props> = (props) => {
  const [state, actions] = AppStore.users.use();

  const handleSubmit = (user) => {
    return UserService.updateOne(user)
      .catch(console.log);
  };

  const userRoleModifier = (value: any) => {
    return UserRoleRender[value as UserRole];
  };

  useEffect(() => {
    // console.log(state.highlighted)
  }, [state.highlighted])

  return (
    <GridFormCard
      model={state.highlighted}
      icon="./icons/user.png"
      title="User Info"
      onSubmit={handleSubmit}
      editable
    >
      <GridCardSection title="Name">
        <GridCardItem formElement="input" field="name" />
      </GridCardSection>
      <GridCardSection title="Email">
        <GridCardItem field="email" />
      </GridCardSection>
      <GridCardSection title="Role">
        <GridCardItem
          formElement="dropdown"
          options={Object.values(UserRole)}
          valueModifier={userRoleModifier}
          field="role"
        />
      </GridCardSection>
      <GridCardSection title="Auth0 ID">
        <GridCardItem  field="auth0Id" />
      </GridCardSection>
    </GridFormCard>
  );
};

export default UserInfo;
