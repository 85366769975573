import React from "react";
import { Grid } from "../../../../components/gridManager/components/Grid";
import DevicesList from "../components/DevicesList";
import DiagnosticsList from "../components/DiagnosticsList";
import ManifestWindow from "../components/ManifestViewer";
import SystemInfo from "../components/SystemInfo";


const SystemsGrid = new Grid();
SystemsGrid.id = "Systems"
SystemsGrid.components = [
  <SystemInfo />,
  <ManifestWindow />,
  <DevicesList />,
  <DiagnosticsList />
];
SystemsGrid.layout = [
  {
    x: 0,
    y: 0,
    w: 5,
    h: 12,
    i: "0",
    static: true,
  },
  {
    x: 6,
    y: 0,
    w: 7,
    h: 12,
    i: "1",
    static: true,
  },
  {
    x: 0,
    y: 12,
    w: 6,
    h: 10,
    i: "2",
    static: true,
  },
  {
    x: 7,
    y: 12,
    w: 6,
    h: 10,
    i: "3",
    static: true,
  }
];

export default SystemsGrid;