import { Button, Typography } from "@mui/material";
import React, {
  createContext,
  Fragment,
  FunctionComponent,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { FormModel } from "../../../utils/FormModel";
import { Form } from "../../form/FormElement";
import Loader from "../../loader/Loader";
import BarLoader from "react-spinners/BarLoader";
import Popup from "../../popup/Popup";
import { GridContext, IGridComponent } from "../GridManager";
import styles from "../styles/Components.css";
import PropTypes from "prop-types";
import isRequiredIf from "react-proptype-conditional-require";
import { GridCardExpand } from "./Grid";

interface GridCardPropsBase extends IGridComponent {
  icon?: string;
  title?: string;
  utility?: JSX.Element;
  expandable?: boolean;
  loading?: boolean;
  layout?: "row" | "column";
  backGroundColor?: string;
  onExpand?: () => void;
}

interface FormProps extends GridCardPropsBase {
  model: FormModel | undefined;
  onSubmit?: (model: FormModel) => Promise<any>;
  editable?: boolean;
}

interface IGridFormCardContext {
  model: FormModel | undefined;
  editing?: boolean;
}

interface GridCardUtilityIconProps {
  src: string;
  width?: number;
  height: number;
  style?: any;
  onClick?: (event: any) => void;
  onFocus?: (event: any) => void;
}

interface GridCardSectionProps {
  title?: string;
  editing?: boolean;
}

const initialContext: IGridFormCardContext = {
  model: new FormModel(),
  editing: false,
};

const GridFormCardContext = createContext<IGridFormCardContext>(initialContext);

export const GridFormCard: FunctionComponent<FormProps> = (props) => {
  const [showEditForm, toggleShowEditForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [context, setContext] = useState<IGridFormCardContext>({
    ...initialContext,
    model: props.model,
  });

  const handleSubmit = (model: FormModel) => {
    setLoading(true);

    return props.onSubmit
      ? props
          .onSubmit(model)
          .then(() => handleCloseEditForm())
          .finally(() => setLoading(false))
      : new Promise((_, reject) => reject).finally(() => setLoading(false));
  };

  const handleEditing = () => {
    toggleShowEditForm(true);
    setContext({ ...context, editing: true });
  };

  const handleCloseEditForm = () => {
    toggleShowEditForm(false);
    setContext({ ...context, editing: false });
  };

  const extractChildren = (children: ReactNode) => {
    const elements: JSX.Element[] = [];

    React.Children.map(props.children, (child, index) => {
      if (!React.isValidElement(child)) return;

      if ((child as any).type.displayName == "GridCardSection") {
        elements.push(
          React.cloneElement(child, { key: index, editing: true } as any)
        );
      }
    });

    return elements;
  };

  const EditForm = () => {
    const elements = extractChildren(props.children);

    return (
      <Popup
        visible={showEditForm}
        onDismiss={handleCloseEditForm}
        title={`${props.title}`}
      >
        <GridFormCardContext.Consumer>
          {(value) => (
            <Form
              model={value.model ? value.model : new FormModel()}
              onSubmit={handleSubmit}
            >
              {elements}
              <Form.Item field="submit">
                <div className={styles.submitButtonContainer}>
                  {loading ? (
                    <BarLoader />
                  ) : (
                    <Button variant="contained">Submit</Button>
                  )}
                </div>
              </Form.Item>
            </Form>
          )}
        </GridFormCardContext.Consumer>
      </Popup>
    );
  };

  useEffect(() => {
    setContext({ ...context, model: props.model });
  }, [props.model]);

  return (
    <GridFormCardContext.Provider value={context}>
      <GridCard
        {...props}
        utility={
          props.editable ? (
            <Fragment>
              {EditForm()}
              <GridCardUtilityIcon
                onClick={handleEditing}
                src="./icons/edit.png"
                width={25}
                height={25}
              />
            </Fragment>
          ) : (
            props.utility
          )
        }
      >
        {props.children}
      </GridCard>
    </GridFormCardContext.Provider>
  );
};

export const GridCard: FunctionComponent<GridCardPropsBase> = (props) => {
  const gridContext = useContext(GridContext);

  const handleExpand = () => {
    if (props.gridIndex) {
      gridContext.expandCard(new GridCardExpand(props.gridIndex, 1));
      props.onExpand?.();
    }
  };

  const expandUtility = (
    <GridCardUtilityIcon
      src={"./icons/expand.png"}
      onClick={handleExpand}
      width={20}
      height={20}
      style={{ opacity: 0.8 }}
    />
  );

  return (
    <div style={{backgroundColor: props.backGroundColor}} className={styles.gridCardContainer}>
      {props.title && props.icon && (
        <div className={styles.gridCardHeader}>
          <div className={styles.gridCardHeaderTitleContainer}>
            <img src={props.icon} width={25} height={25} />
            <Typography
              className={styles.gridCardHeaderTitle}
              fontWeight="bold"
              variant="subtitle1"
            >
              {props.title}
            </Typography>
          </div>
          {props.expandable && expandUtility}
          {props.utility}
        </div>
      )}
      {props.title && props.icon ? (
        props.loading ? (
          <div className={styles.loading}>
            <Loader />
          </div>
        ) : (
          <div
            className={
              props.layout == "row"
                ? styles.gridCardBodyColumn
                : styles.gridCardBody
            }
          >
            {props.children}
          </div>
        )
      ) : (
        <>{props.children}</>
      )}
    </div>
  );
};

export const GridCardUtilityIcon: FunctionComponent<
  GridCardUtilityIconProps
> = (props) => {
  return (
    <div className={styles.gridCardUtilityButton}>
      <img {...props} />
    </div>
  );
};

export const GridCardSection: FunctionComponent<GridCardSectionProps> = (
  props
) => {
  const mapItems = () => {
    const elements: JSX.Element[] = [];

    React.Children.map(props.children, (child, index) => {
      if (!React.isValidElement(child)) return;

      if (
        (child as any).type.displayName == "GridCardItem" &&
        (child as any).props.formElement
      ) {
        elements.push(
          React.cloneElement(child, {
            ...child.props,
            editing: props.editing,
            key: index,
          })
        );
      }
    });

    return elements;
  };

  return (
    <div className={styles.gridCardBodyItem}>
      {props.editing ? (
        mapItems().length > 0 && (
          <>
            <Typography fontWeight="light" variant="subtitle2">
              {props.title}
            </Typography>
            {mapItems()}
          </>
        )
      ) : (
        <>
          <Typography fontWeight="light" variant="subtitle2">
            {props.title}
          </Typography>
          {props.children}
        </>
      )}
    </div>
  );
};

interface GridCardItemProps {
  field: string;
  valueModifier?: (value: any) => string;
  formElement?: "input" | "checkbox" | "dropdown";
  editing?: boolean;
  options?: any[];
}

export const GridCardItem: FunctionComponent<GridCardItemProps> = (props) => {
  const context = useContext(GridFormCardContext);

  const InputElement = () => {
    if (props.formElement) {
      switch (props.formElement) {
        case "dropdown":
          if (!props.options) return;

          return (
            <Form.Dropdown
              options={props.options}
              defaultValue={context.model?.[props.field]}
              valueModifier={props.valueModifier}
              size="small"
            ></Form.Dropdown>
          );
        default:
          return (
            <Form.Input
              className={styles.gridCardBodyContentInput}
              defaultValue={
                props.valueModifier
                  ? props.valueModifier(context.model?.[props.field])
                  : context.model?.[props.field]
              }
              disableUnderline
              fullWidth
            />
          );
      }
    }

    return null;
  };

  return (
    <Fragment>
      {props.editing && props.formElement ? (
        <Form.Item field={props.field}>{InputElement()}</Form.Item>
      ) : (
        <Typography className={styles.gridCardBodyContent} variant="subtitle2">
          {props.valueModifier
            ? props.valueModifier(context.model?.[props.field])
            : context.model?.[props.field]}
        </Typography>
      )}
    </Fragment>
  );
};

const { number } = PropTypes;

GridCard.propTypes = {
  gridIndex: isRequiredIf(number, (props) => props.expandable),
};

GridCardSection.displayName = "GridCardSection";
GridCardItem.displayName = "GridCardItem";
