import React, { Fragment, FunctionComponent, useContext, useEffect } from "react";
import { AppStore } from "../../../../AppStore";
import { NotificationContext } from "../../../../providers/Notifications";
import CompanyGrid from "./Grid";

interface Props {}

const Body: FunctionComponent<Props> = (props) => {
  const [account, ] = AppStore.account.use();
  const [, gridActions] = AppStore.grid.use();
  const [, drawerActions] = AppStore.drawer.use();
  const [, companiesActions] = AppStore.companies.use();
  const { clearNotifications } = useContext(NotificationContext);

  useEffect(() => {
    clearNotifications();

    gridActions.clear();
    gridActions.initGrid(CompanyGrid);
    drawerActions.toggleDrawer(false);
    drawerActions.setSections(undefined);
    drawerActions.setItems(undefined);
    drawerActions.setTitle("");
    drawerActions.setAddModelForm(undefined);

    if (account.user)
      companiesActions.getCachedHighlighted(account.user.companyId)
        .then(company => {
          gridActions.setPrimaryTitle(company?.name);
          gridActions.setSecondaryTitle(company?.location);
          gridActions.setLoading(false);
        })   
  }, []);


  return <Fragment />;
};

export default Body;
