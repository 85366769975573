import { Button } from "@mui/material";
import React, { FunctionComponent, useState } from "react";
import Popup from "../../popup/Popup";
import styles from "../styles/Components.css";

interface Props {
  onDelete: () => void;
  requireConfirm?: boolean;
  title?: string
}

export const DeleteUtility: FunctionComponent<Props> = (props) => {

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const handleDelete = () => {
    if (props.requireConfirm) setShowConfirmDelete(true);
    else props.onDelete()
  };

  const handleConfirm = () => {
    props.onDelete()
    setShowConfirmDelete(false);
  }

  return (
    <div>
      <Button
        className={styles.deleteButton}
        variant="outlined"
        onClick={handleDelete}
      >
        delete
      </Button>
      {props.requireConfirm && (
        <Popup
          subtitle={props.title}
          visible={showConfirmDelete}
          onDismiss={() => setShowConfirmDelete(false)}
        >
          <br />
          <div className={styles.submitButtonContainer}>
            <Button variant="outlined" color="error" onClick={handleConfirm}>
              confirm
            </Button>
          </div>
        </Popup>
      )}
    </div>
  );
};
