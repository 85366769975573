import React, { Fragment, FunctionComponent, useEffect } from "react";
import { AppStore } from "../../../../AppStore";
import { DrawerItem } from "../../../../components/drawer/components/DrawerItem";
import { DeleteUtility } from "../../../../components/gridManager/components/DeleteUtility";
import { User } from "../../../../services/user/models/User";
import { UserService } from "../../../../services/user/UserService";
import { AddUserForm } from "../components/AddUserForm";
import UsersGrid from "./Grid";

interface Props {}

const Body: FunctionComponent<Props> = (props) => {
  const [gridState, gridActions] = AppStore.grid.use();
  const [, drawerActions] = AppStore.drawer.use();
  const [state, actions] = AppStore.users.use();
  const [companyState, companyActions] = AppStore.companies.use();

  const handleSelected = (user: any) => {
    actions.setHighlighted(user);
  };

  const handleDeleteUser = () => {
    actions
      .deleteHighlighted()
      .then(actions.updateCollection)
      .catch(console.log);
  };

  const updateDrawer = () => {
    actions.getCachedCollection().then((users) => {
      const items: DrawerItem[] = [];
      let highlighted: DrawerItem | undefined;

      users.forEach((user) => {
        const drawerItem = new DrawerItem();

        drawerItem.primary = user.name;
        drawerItem.secondary = user.auth0Id;
        drawerItem.model = user;
        drawerItem.onClick = handleSelected;

        items.push(drawerItem);

        if (state.highlighted && user.id == state.highlighted.id)
          highlighted = drawerItem;

        if (!highlighted && !state.highlighted) {
          highlighted = drawerItem;
        }
      });

      if (highlighted) actions.setHighlighted(highlighted.model as User);

      drawerActions.setHighlighted(highlighted);
      drawerActions.setItems(items);
    });
  };

  useEffect(() => {
    gridActions.clear();
    gridActions.initGrid(UsersGrid);
    gridActions.setUtilities([
      <DeleteUtility
        requireConfirm
        onDelete={handleDeleteUser}
        title={`Are you sure you want to delete this user?`}
      />,
    ]);
    drawerActions.setTitle("user");
    drawerActions.setAddModelFormTitle("User");
    drawerActions.setSections(undefined);
    drawerActions.setAddModelForm(<AddUserForm />);
  }, []);

  useEffect(() => {
    updateDrawer();
  }, [state.collection]);

  useEffect(() => {
    if (state.highlighted) {
      companyActions.getCachedCollection().then((collection) => {
        const company = collection.find(
          (company) => company.id == state.highlighted?.companyId
        );
        gridActions.setSecondaryTitle(company?.name);
      });
      gridActions.setPrimaryTitle(state.highlighted.name);
      gridActions.setLoading(false);
    }
  }, [state.highlighted]);

  return <Fragment />;
};

export default Body;
