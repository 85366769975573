import { Typography } from "@mui/material";
import L, { LatLng, Map } from "leaflet";
import React, { FunctionComponent, useEffect, useState } from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../App";
import { AppStore } from "../../../AppStore";
import { GridCard } from "../../../components/gridManager/components/GridCard";
import { IGridComponent } from "../../../components/gridManager/GridManager";
import {
  AssembledSystem,
  SystemType
} from "../../../services/system/models/System";
import { SystemService } from "../../../services/system/SystemService";
import styles from "../styles/Components.css";

interface Props extends IGridComponent {}

interface PlotProps {
  coordinate: LatLng;
  online?: boolean;
}

const UnitedStatesCenter = new LatLng(37.0902, -95.7129);

const MapElement: FunctionComponent<Props> = (props) => {
  const [state, actions] = AppStore.systems.use();
  const [deviceState, deviceActions] = AppStore.devices.use();
  const [drawerState] = AppStore.drawer.use();
  const [gridState] = AppStore.grid.use();
  const [loading, setLoading] = useState(true);
  const [expanding, setExpanding] = useState(false);
  const [map, setMap] = useState<Map>();
  const [plot, setPlot] = useState<LatLng | undefined>();

  useEffect(() => {
    map?.attributionControl.remove();
    // map?.zoomControl.remove()
  }, [map]);

  useEffect(() => {
    getLocation();
  }, [state.highlighted]);

  const plotSystem = (locationSystems: AssembledSystem[] | undefined) => {
    setPlot(undefined);

    if (!locationSystems || locationSystems.length != 1) return;

    const system = locationSystems[0];

    if (SystemType[system.type] != SystemType.GPS)
        return

      const lat = system.devices.find(d => d.name.toLowerCase() == "lat")
      const lng = system.devices.find(d => d.name.toLowerCase() == "lon")

      if (lat && lng) {
        const x = parseFloat(lat.measurement);
        const y = parseFloat(lng.measurement);

        setPlot(new LatLng(x, y));
      }
  };

  const getLocation = () => {
    if (state.highlighted)
      SystemService.getAssembledSubSystems(state.highlighted.id, SystemType.GPS)
        .then(plotSystem)
        .catch(console.log);
  };

  useEffect(() => {
    if (!map || drawerState.animating) return;

    map.invalidateSize();
  }, [drawerState.animating]);

  const reflow = () => {
    map?.invalidateSize();

    setExpanding(false);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => setLoading(false), 100);
  }, [expanding]);

  useEffect(() => {
    setLoading(drawerState.animating || expanding);

    if (expanding) setTimeout(() => setLoading(false), 100);
  }, [drawerState.animating, expanding]);

  useEffect(() => {
    if (!drawerState.animating && !loading) {
      setTimeout(() => {
        reflow();
      }, 200);
    }
  }, [loading]);

  useEffect(() => {
    if (map && plot) map.flyTo(plot, 5, { duration: 0.5 });
  }, [plot]);

  return (
    <GridCard
      gridIndex={props.gridIndex}
      loading={loading}
      onExpand={() => setExpanding(true)}
      expandable
      icon="./icons/location.png"
      title="Map"
    >
      <MapContainer
        zoom={5}
        whenCreated={setMap}
        center={plot ?? UnitedStatesCenter}
        scrollWheelZoom
        className={`${styles.mapContainer}`}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {!loading && plot && (
          <MapPlot online={state.highlighted?.isOnline()} coordinate={plot} />
        )}
        {!plot && !loading && (
          <div className={styles.locationDataUnavailableContainer}>
            <Typography
              className={styles.locationDataUnavailable}
              variant="subtitle1"
            >
              Location information is not available for this system
            </Typography>
          </div>
        )}
      </MapContainer>
    </GridCard>
  );
};

const MapPlot: FunctionComponent<PlotProps> = (props) => {
  const history = useHistory();

  const toDashboard = () => history.push(Routes.System);

  enum healthStyles {
    "Healthy" = styles.healthy,
    "Unhealthy" = styles.unhealthy,
    "Moderate" = styles.moderate,
    "Pending" = styles.pending,
  }

  const icon = L.divIcon({
    className: `${styles.mapPlot} ${props.online ? styles.online : styles.offline}`,
    iconSize: [30, 30],
  });

  return (
    <Marker position={props.coordinate} icon={icon}>
      <Popup closeButton={false}>
        <div className={styles.plotPopUp}>
          <p className={styles.popupFont}>
          Latitude: {props.coordinate.lat} Longitude: {props.coordinate.lng}
          </p>
        </div>
      </Popup>
    </Marker>
  );
};

export default MapElement;
