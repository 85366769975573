import { SnackbarProps } from "@mui/material";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { SnackbarProvider, useSnackbar } from "snackfy";
import { AppStore } from "../AppStore";
import { INotification } from "../services/notifications/models/Notification";

interface Props {}

interface ProviderProps {}

interface IContext {
  addNotifications: (notifications: string[], variant: string) => void,
  addNotification: (notification: INotification) => void;
  clearNotifications: () => void;
}

const initialContext: IContext = {
  addNotifications: (notifications: string[], variant: string) => {},
  addNotification: (notification: INotification) => {},
  clearNotifications: () => {},
};

export const NotificationContext = React.createContext(initialContext);

// Provider wrapper around snackify snackbar. Need to set z-index of snackbars to 1300 in source code...
const Notifications: FunctionComponent<Props> = (props) => {
  return (
    <SnackbarProvider placement={{ vertical: "bottom", horizontal: "right" }}>
      <NotificationProvider>{props.children}</NotificationProvider>
    </SnackbarProvider>
  );
};

const NotificationProvider: FunctionComponent<ProviderProps> = (props) => {
  const [state, actions] = AppStore.notifications.use();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const addNotifications = (notifications: string[], variant: string) => {
    notifications.forEach((n) => {
      addNotification({ message: n, options: { variant: variant } });
    });
  };

  const addNotification = (notification: INotification) => {
    const id = enqueueSnackbar({
      ...notification,
    });

    notification.id = id;

    actions.addNotification(notification);
  };

  const clearNotifications = () => {
    state.notifications.forEach((n) => closeSnackbar(n.id));
    actions.clearNotifications();
  };

  const context = {
    addNotifications,
    addNotification,
    clearNotifications,
  };

  return (
    <NotificationContext.Provider value={context}>
      {props.children}
    </NotificationContext.Provider>
  );
};

export { Notifications };
