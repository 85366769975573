// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uJ78HQPitvYtYjRkPwzS {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: inherit;\n    width: inherit;\n}\n", "",{"version":3,"sources":["webpack://./src/components/loader/styles/Loader.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,cAAc;AAClB","sourcesContent":[".container {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: inherit;\n    width: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "uJ78HQPitvYtYjRkPwzS"
};
export default ___CSS_LOADER_EXPORT___;
