import { Typography } from "@mui/material";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import React, { FunctionComponent, useEffect, useState } from "react";
import { AppStore } from "../../../../AppStore";
import TableCellExpand from "../../../../components/gridManager/components/TableCellExpand";
import { IGridComponent } from "../../../../components/gridManager/GridManager";
import { SystemAlert } from "../../../../services/system/models/SystemAlert";
import styles from "../../styles/Components.css";

interface Props extends IGridComponent {}

const AlertsList: FunctionComponent<Props> = (props) => {
  const [state, actions] = AppStore.devices.use();
  const [alerts, setAlerts] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  const columns: GridColumns = [
    {
      field: "timestamp",
      headerName: "Timestamp",
      width: 200
    },
    {
      field: "origin",
      headerName: "Origin",
      width: 300,
      renderCell: TableCellExpand
    },
    {
      field: "message",
      headerName: "Message",
      width: 400,
      renderCell: TableCellExpand
    }
  ];

  const processAlerts = (alerts: SystemAlert[]) => {
    const processed: any[] = [];

    alerts.forEach((alert, index) => {
      processed.push({
        message: alert.value,
        origin: alert.origin,
        timestamp: alert.timestamp,
        id: index,
      });
    });

    return processed;
  };

  const handleAlerts = (alerts: SystemAlert[]) => {

    setAlerts(processAlerts(alerts))
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true);
    if (state.highlighted)
      handleAlerts(state.highlighted.alerts);

  }, [state.highlighted]);

  return (
      <div className={styles.detailContainer}>
        <DataGrid
          rows={alerts}
          columns={columns}
          loading={loading}
          rowHeight={30}
          headerHeight={30}
          sx={{
            "& .MuiDataGrid-columnSeparator": {
              visibility: "hidden",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#e9e9e9",
            },
          }}
          hideFooter
        />
      </div>
  );
};

export default AlertsList;
