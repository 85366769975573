import { DiagnosticService } from "./DiagnosticService";
import { Diagnostic } from "./models/Diagnostic";
import { System } from "../system/models/System";
import { IModelActions, IModelState, ModelStore } from "../../utils/ModelStore";



export interface DiagnosticsState extends IModelState<Diagnostic> {}

export interface DiagnosticsActions extends IModelActions<Diagnostic> {
}

export class DiagnosticsStore
  extends ModelStore<Diagnostic, DiagnosticsState, DiagnosticsActions>
  implements DiagnosticsActions
{
  protected prototype = DiagnosticsStore.prototype;
  protected initialState = {
    collection: [],
    parentCollection: [],
    highlighted: undefined,
    highlightedParent: undefined,
    lastUpdatedCollection: undefined,
    lastUpdatedHighlighted: undefined,
    lastUpdatedParentCollection: undefined,
    cachedCapacity: 30,
    api: DiagnosticService
  };

  constructor() {
    super();
  }

}