// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.IQ9HC1s5tlnmvurvZUvI {\n    width: 100%;\n    height: calc(100% - 10px);\n    display: flex;\n    padding-top: 10px;\n    \n}\n\n.YYu48rCU1KXWQswt8kYx  {\n    display: none !important;\n    /* background-color: black; */\n}\n\n.iQ9hEcgikO3roi_e2Jl4 {\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-end;\n}\n", "",{"version":3,"sources":["webpack://./src/areas/admin/users/styles/Components.css"],"names":[],"mappings":";AACA;IACI,WAAW;IACX,yBAAyB;IACzB,aAAa;IACb,iBAAiB;;AAErB;;AAEA;IACI,wBAAwB;IACxB,6BAA6B;AACjC;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;AAC7B","sourcesContent":["\n.listContainer {\n    width: 100%;\n    height: calc(100% - 10px);\n    display: flex;\n    padding-top: 10px;\n    \n}\n\n.listHeaderItem  {\n    display: none !important;\n    /* background-color: black; */\n}\n\n.submitButtonContainer {\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listContainer": "IQ9HC1s5tlnmvurvZUvI",
	"listHeaderItem": "YYu48rCU1KXWQswt8kYx",
	"submitButtonContainer": "iQ9hEcgikO3roi_e2Jl4"
};
export default ___CSS_LOADER_EXPORT___;
