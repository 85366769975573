import { DataGrid, GridColumns } from "@mui/x-data-grid";
import React, { FunctionComponent, useEffect, useState } from "react";
import { AppStore } from "../../../../AppStore";
import { GridCard } from "../../../../components/gridManager/components/GridCard";
import { IGridComponent } from "../../../../components/gridManager/GridManager";
import styles from "../styles/Components.css";

interface Props extends IGridComponent {}

const SystemsList: FunctionComponent<Props> = (props) => {
  const [companies, companiesActions] = AppStore.companies.use();
  const [state, actions] = AppStore.systems.use();
  const [systems, setSystems] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  const columns: GridColumns = [
    {
      field: "ssid",
      headerName: "SID",
      width: 130,
    },
    {
      field: "health",
      headerName: "Health",
      width: 130,
    },
    {
      field: "lastUploaded",
      headerName: "Last Uploaded",
      width: 200,
    }
  ];



  const handleSystems = () => {
    if (companies.highlighted)
      actions
        .getCachedParentCollection(companies.highlighted)
        .then(setSystems)
        .then(() => setLoading(false))
        .catch(console.log);
  };

  useEffect(() => {
    setLoading(true);
    handleSystems();
  }, [companies.highlighted]);

  useEffect(() => {
    if (!loading) handleSystems();
  }, [state.parentCollection]);

  return (
    <GridCard
      gridIndex={props.gridIndex}
      loading={loading}
      icon="./icons/antena.png"
      title="Systems"
    >
      <div className={styles.listContainer}>
        <DataGrid
          rows={systems}
          columns={columns}
          loading={loading}
          rowHeight={30}
          headerHeight={30}
          sx={{
            "& .MuiDataGrid-columnSeparator": {
              visibility: "hidden",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#e9e9e9",
            },
          }}
          hideFooter
        />
      </div>
    </GridCard>
  );
};

export default SystemsList;
