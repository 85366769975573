import { TextField, Menu, MenuItem, Typography, Button } from "@mui/material";
import React, { FunctionComponent, useState } from "react";
import styles from "../styles/Drawer.css";

interface Props {
  title: string;
  onSearch: (search: string) => void;
  onAddModel?: () => void;
}

const DrawerHeader: FunctionComponent<Props> = (props) => {
  const [ancorEl, setAncorEl] = useState(null);
  const [openFilter, setOpenFilter] = useState(false);

  const handleOpenFilter = (event) => {
    setAncorEl(event.currentTarget);
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
    setAncorEl(null);
  };

  return (
    <div className={styles.drawerHeader}>
      <div className={styles.searchContainer}>
        {props.title && (
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder={`Search for ${props.title}...`}
            className={styles.itemSearchTextField}
            onChange={(event) => props.onSearch(event.target.value)}
          />
        )}

        {/* <img
          height={25}
          width={25}
          src="./icons/filter.png"
          onClick={handleOpenFilter}
        /> */}
        <Menu
          anchorEl={ancorEl}
          open={openFilter}
          onClose={handleCloseFilter}
          onClick={handleCloseFilter}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem>Type</MenuItem>
        </Menu>
      </div>
      {props.onAddModel && (
        <Button
          className={styles.addButton}
          fullWidth
          variant="outlined"
          onClick={props.onAddModel}
        >
          +
        </Button>
      )}
    </div>
  );
};

export default DrawerHeader;
