import { FormModel, ValueStore } from "../../../utils/FormModel";
import { SystemAlert } from "../../system/models/SystemAlert";
import { DeviceCategory } from "./DeviceGroup";

export class Device extends FormModel {
    
    public id: string = "";
    public sid: string = "";
    public ssid: string = "";
    public name: string = "";
    public units: string = "";
    public category: string = "";
    public health: string = "";
    public uniqueName: string = "";
    public actualUniqueName: string = "";
    public alerts: SystemAlert[] = [];
    public measurement: string = "";
    public measurementType: string = "";
}

export class DeviceQuery {
    public id: string = "";
    public sid: string = "";
    public ssid: string = "";
    public name: string = "";

    constructor(props) {

        this.id = props.id;
        this.sid = props.sid;
        this.ssid = props.ssid;
        this.name = props.name;

    }
}