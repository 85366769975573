import React, { FunctionComponent, useContext, useEffect } from "react";
import { API } from "../api";
import { AppStore } from "../AppStore";
import { UserRole } from "../services/user/models/User";
import { WebSocketContext } from "./WebSocketProvider";

interface ProviderProps {}

// Utility to handle API websocket methods
const ApiProvider: FunctionComponent<ProviderProps> = (props) => {

  const [ account, ] = AppStore.account.use()

  const [ , users ] = AppStore.users.use()
  const [ , companies ] = AppStore.companies.use()
  const [ , systems ] = AppStore.systems.use()
  const [ , events ] = AppStore.events.use()

  const { service, connected } = useContext(WebSocketContext);
  const { updates, uploads } = service.connections;

  useEffect(() => {
    if (connected) {

      // Uploads
      uploads.on(API.webSockets.commands.systemUploaded, systems.handleUpdateSingle);

      // Updates
      updates.on(API.webSockets.commands.systemUpdated, systems.handleUpdateSingle);

      if (account.user?.role == UserRole.PortalAdmin) {
        updates.on(API.webSockets.commands.userUpdated, users.handleUpdateAll);
        updates.on(API.webSockets.commands.userCreated, users.handleUpdateAll);
        updates.on(API.webSockets.commands.companyCreated, companies.handleUpdateAll);
        updates.on(API.webSockets.commands.companyUpdated, companies.handleUpdateAll);
        updates.on(API.webSockets.commands.systemCreated, systems.handleUpdateAll);
        updates.on(API.webSockets.commands.changeEventCreated, events.handleUpdateAll);
        updates.on(API.webSockets.commands.userAssignedToCompany, users.handleUpdateAll);
        updates.on(API.webSockets.commands.userRemovedFromCompany, users.handleUpdateAll);
        updates.on(API.webSockets.commands.systemAssignedToCompany, users.handleUpdateAll);
        updates.on(API.webSockets.commands.systemRemovedFromCompany, users.handleUpdateAll);
      } else {
        updates.on(API.webSockets.commands.userUpdated, users.handleParentUpdateAll);
        updates.on(API.webSockets.commands.userAssignedToCompany, users.handleParentUpdateAll);
        updates.on(API.webSockets.commands.userRemovedFromCompany, users.handleParentUpdateAll);
        updates.on(API.webSockets.commands.systemAssignedToCompany, systems.handleParentUpdateAll);
        updates.on(API.webSockets.commands.systemRemovedFromCompany, systems.handleParentUpdateAll);
        updates.on(API.webSockets.commands.changeEventCreated, events.handleParentUpdateAll);
        updates.on(API.webSockets.commands.companyUpdated, companies.handleUpdateSingle);
      }
    }

    return () => {
      uploads.stop()
      updates.stop()
    }
  }, [connected])


  return <div>{props.children}</div>;
};

export { ApiProvider };
