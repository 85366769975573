import { FormModel } from "../../../utils/FormModel";
import { SystemAlert } from "../../system/models/SystemAlert";

export class Diagnostic extends FormModel {
    
    public id: string = "";
    public ssid: string = "";
    public name: string = "";
    public units: string = "";
    public health: string = "";
    public alerts: SystemAlert[] = [];
    public measurement: string = "";
}
