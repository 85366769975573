import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import qs from "qs";
import React, {
  createContext,
  Fragment,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { AppStore } from "../AppStore";
import Loader from "../components/loader/Loader";
import { UserFitsRoles } from "../helpers/roles";
import { UserRole } from "../services/user/models/User";
import { AuthenticationContext } from "./AuthenticationProvider";
import { NotificationContext } from "./Notifications";

interface RouterProps {
  defaultSecureRoute: string;
  defaultAdminRoute: string;
  defaultInsecureRoute: string;
}

interface RoutedSegmentProps {
  globalComponents?: React.ComponentType<any>[];
  roles?: UserRole[];
  path?: string;
}

interface ProtectedRouteProps extends RouteProps {
  component: React.ComponentType<any>;
  useNavigation?: boolean;
}

interface RouteProps {
  component: React.ComponentType<any>;
  path: string;
  roles?: UserRole[];
}

export enum DefaultRoutes {
  Loading = "/loading",
  Login = "/login",
  Logout = "/logout",
}

interface RouterState {
  redirect: string;
  metadata: any;
}

const initialRouterContext = {
  redirect: "",
  metadata: {},
  clearState: () => {},
};

const RouterContext = createContext(initialRouterContext);

const RoutedSegment: FunctionComponent<RoutedSegmentProps> = (props) => {
  const [account] = AppStore.account.use();
  const { authenticated } = useContext(AuthenticationContext);
  const { children, globalComponents, roles } = props;

  const mapGlobalComponents = () => {
    const elements: React.ReactElement[] = [];

    if (!globalComponents) return elements;

    globalComponents.forEach((component, index) =>
      elements.push(React.createElement(component, { key: index }))
    );

    return elements;
  };

  if (
    (account.user &&
      roles &&
      roles.length > 0 &&
      authenticated &&
      roles.includes(UserRole[account.user.role])) ||
    (!authenticated && (!roles || roles?.length == 0))
  ) {
    return (
      <Fragment>
        <Route path={props.path}>
          {mapGlobalComponents()}
          <Switch>{children}</Switch>
        </Route>
      </Fragment>
    );
  }

  return <></>;
};

const RouterProvider: FunctionComponent<RouterProps> = (props) => {
  const { isLoading, user } = useAuth0();
  const { authenticated } = useContext(AuthenticationContext);
  const [account] = AppStore.account.use();
  const [context, setContext] = useState<any>(initialRouterContext);
  const [redirect, setRedirect] = useState<any>();

  const clearState = () => {
    setContext(initialRouterContext);
  };

  useEffect(() => {
    if (!window.location) return;

    const state = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    if (state) {
      const { redirect, ...rest } = state;

      if (redirect) {
        setContext({
          ...context,
          clearState: clearState,
          redirect: redirect,
          metadata: rest
        });

        setRedirect(redirect);
      }
    }
  }, []);

  useEffect(() => {
    if (window.location && window.location.pathname == redirect)
      setRedirect(undefined);
  }, [window.location]);

  return (
    <RouterContext.Provider value={context}>
      <Router>
        {(isLoading && !account.loggingIn) || account.loggingOut ? (
          <div style={{ height: "100vh", width: "100vw" }}>
            <Loader />
          </div>
        ) : (
          props.children
        )}
        {!isLoading &&
          (authenticated && account.user ? (
            UserRole[account.user.role] == UserRole.PortalAdmin ? (
              <Redirect
                from="*"
                to={redirect ? "/admin/" + redirect : props.defaultAdminRoute}
              />
            ) : (
              <Redirect
                from="*"
                to={redirect ? "/dashboard/" + redirect : props.defaultSecureRoute}
              />
            )
          ) : (
            <Redirect from="*" to={props.defaultInsecureRoute} />
          ))}
      </Router>
    </RouterContext.Provider>
  );
};

const AdminRoute: FunctionComponent<RouteProps> = (props) => {
  return <ProtectedRoute {...props} roles={[UserRole.PortalAdmin]} />;
};

const AuthenticatedRoute: FunctionComponent<RouteProps> = (props) => {
  return (
    <ProtectedRoute
      {...props}
      roles={[UserRole.User, UserRole.CompanyAdmin, UserRole.PortalAdmin]}
    />
  );
};

const UnauthenticatedRoute: FunctionComponent<RouteProps> = (props) => {
  return <ProtectedRoute {...props} />;
};

const ProtectedRoute: FunctionComponent<RouteProps> = (props) => {
  const { component, path, roles } = props;

  const withAuthentication = (
    comp: React.ComponentType<any>,
    roles: UserRole[]
  ) => {
    return withAuthenticationRequired(comp, {
      onRedirecting: () => (
        <div style={{ height: "100vh", width: "100vw" }}>
          <Loader />
        </div>
      ),
      claimCheck: (user) => {
        const [account] = AppStore.account.use();

        if (!account.user) return false;

        return roles.includes(UserRole[account.user.role]);
      },
    });
  };

  const withoutAuthentication = (comp) => {
    return comp;
  };

  return (
    <Route
      path={path}
      component={
        roles
          ? withAuthentication(component, roles)
          : withoutAuthentication(component)
      }
    />
  );
};

export {
  RouterProvider,
  RouterContext,
  RoutedSegment,
  AdminRoute,
  AuthenticatedRoute,
  UnauthenticatedRoute,
};
