import { TextField, Menu, MenuItem, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import React, { FunctionComponent, useEffect, useState } from "react";
import { AppStore } from "../../../AppStore";
import { FormModel } from "../../../utils/FormModel";
import styles from "../styles/List.css";

export interface ListItemProps {
  primary: string;
  secondary?: string;
  model?: FormModel;
  image?: string;
  children?: JSX.Element[];
  highlighted?: boolean;
  onClick: (model: FormModel) => void;
}

export const ListItemComponent: FunctionComponent<ListItemProps> = (props) => {

  const handleClick = () => {
    props.model &&
    props.onClick(props.model);
  };

  return (
    <ListItem
      button
      onClick={handleClick}
      className={`${styles.listItem} ${
        props.highlighted
          ? styles.highlightedListItem
          : ""
      }`}
    >
      {props.image && (
        <ListItemIcon>
          <img src={props.image} height={35} width={25} />
        </ListItemIcon>
      )}
      <ListItemText primary={props.primary} secondary={props.secondary} />
      {props.children}
    </ListItem>
  );
};