import React, { FunctionComponent, useEffect } from "react";
import styles from "../styles/SystemDashboard.css";
import Body from "./Body";

interface Props {}

const AdminSystemDashboard: FunctionComponent<Props> = () => {

    return (
        <div className={styles.bodyContainer} >
            <Body />
        </div>
    )
}

export default AdminSystemDashboard;