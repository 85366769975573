import React, {
  Fragment,
  FunctionComponent,

  useEffect
} from "react";
import { AppStore } from "../../../../AppStore";
import EventsGrid from "./Grid";

interface Props {}

const Body: FunctionComponent<Props> = (props) => {
  const [, gridActions] = AppStore.grid.use();
  const [, drawerActions] = AppStore.drawer.use();
  const [state, actions] = AppStore.events.use();

  const handleSelected = (user: any) => {
    actions.setHighlighted(user);
  };

  useEffect(() => {
    gridActions.clear();
    gridActions.initGrid(EventsGrid);
    drawerActions.setItems(undefined)
    drawerActions.setSections(undefined);
    gridActions.setPrimaryTitle("Events");
    drawerActions.setTitle("");
    gridActions.setLoading(false);
    drawerActions.setAddModelForm(undefined);
  }, []);

  return <Fragment />;
};

export default Body;
