import { Button, Menu, MenuItem } from "@mui/material";
import React, { FunctionComponent, useState } from "react";
import { AppStore } from "../../../AppStore";
import {
  GridCardItem,
  GridCardSection,
  GridCardUtilityIcon,
  GridFormCard,
} from "../../../components/gridManager/components/GridCard";
import { SystemStatusIndicator } from "./SystemStatus";
import styles from "../styles/Components.css";
import DiagnosticsPopupWindow from "./DiagnosticsPopupWindow";
import { FormatStringToDate } from "../../../helpers/time";
import { UserRole } from "../../../services/user/models/User";
import {JournalModal} from "./Journal";

interface Props {}

const SystemInfo: FunctionComponent<Props> = (props) => {
  const [state, actions] = AppStore.systems.use();
  const [companies, companyActions] = AppStore.companies.use();
  const [showDiagnostics, setShowDiagnostics] = useState(false);
  const [viewJournal, setViewJournal] = useState(false);
  const [ancorEl, setAncorEl] = useState(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [account, accountActions] = AppStore.account.use();

  const handleOpenFilter = (event) => {
    setAncorEl(event.currentTarget);
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
    setAncorEl(null);
  };

  const handleDismissDiagnostics = () => {
    setShowDiagnostics(false);
  };

  const handleRequestDiagnostics = () => {
    if (!state.highlighted) return;

    setShowDiagnostics(true);
  };

  const handleViewJournal = () => {
    if (!state.highlighted) return;

    setViewJournal(true);
  }

  const handleDismissJournal = () => {
    setViewJournal(false);
  }

  const handleSystemNickname = (sid) => {
    if (!companies.highlighted) return;

    let nickname = sid;

    companies.highlighted.projects.forEach((p) => {
      const system = p.systems.find((s) => s.sid == sid);
      if (system) nickname = system.nickname;
    });

    return nickname;
  };

  const handleFormatDate = (date: string) => {
    const result = FormatStringToDate(date);

    return result;
  };

  return (
    <GridFormCard
      model={state.highlighted}
      icon="./icons/antena.png"
      title="System Info"
      layout="row"
      // editable
      utility={
        <>
          <GridCardUtilityIcon
            height={25}
            width={25}
            src="./icons/info.png"
            onClick={handleOpenFilter}
          />
          <Menu
            anchorEl={ancorEl}
            open={openFilter}
            onClose={handleCloseFilter}
            onClick={handleCloseFilter}
            transformOrigin={{ horizontal: "center", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem>Firmware Version: {state.highlighted?.version}</MenuItem>
          </Menu>
        </>
      }
    >
      <div className={styles.fullWidth}>
        <GridCardSection title="Nick Name">
          <GridCardItem
            formElement="input"
            field="sid"
            valueModifier={handleSystemNickname}
          />
        </GridCardSection>
        <GridCardSection title="SID">
          <GridCardItem field="sid" />
        </GridCardSection>
        <GridCardSection title="Upload Frequency (seconds)">
          <GridCardItem field="primaryUploadFrequency" />
        </GridCardSection>
        <GridCardSection title="Alerts">
          <GridCardItem
            field="alerts"
            valueModifier={(alerts) => alerts?.length}
          />
        </GridCardSection>
        <GridCardSection title="Last Uploaded">
          <GridCardItem field="lastUploaded" valueModifier={handleFormatDate} />
        </GridCardSection>
      </div>
      <div className={`${styles.fullWidth} ${styles.systemInfoColumn}`}>
        <SystemStatusIndicator />
        <div className={styles.showDiagnosticsButtonContainer}>
          <Button
            size="small"
            variant="contained"
            onClick={handleViewJournal}
          >
            SYSTEM NOTES
          </Button>
        </div>

        <JournalModal
          show={viewJournal}
          onDismiss={handleDismissJournal}
        />
      </div>
    </GridFormCard>
  );
};

export default SystemInfo;
