import { User } from "../user/models/User";
import { BaseStore } from "../../utils/BaseStore";

export interface AccountState {
  loggedIn?: boolean;
  user?: User;
  loggingIn?: boolean;
  loggingOut?: boolean;
  submittingCredentials?: boolean;
}

export interface AccountActions {
  setSubmittingCredentials(submitting: boolean): any;
  setLoggingIn(state: boolean);
  setLoggingOut(state: boolean);
  logout(): void;
  login(user: User): void;
}

export class AccountStore
  extends BaseStore<AccountState, AccountActions>
  implements AccountActions
{
  protected prototype = AccountStore.prototype;
  protected initialState = {
    loggedIn: false,
    loggingOut: false,
    user: undefined,
    loggingIn: false,
    submittingCredentials: false,
  };

  constructor() {
    super();
  }

  public login(user: User) {
    this.setState({
      loggedIn: user != undefined,
      loggingIn: false,
      loggingOut: false,
      user,
    });
  }

  public logout() {
    this.setState({
      loggedIn: false,
      user: undefined,
      loggingIn: false,
      loggingOut: false,
    });
  }

  public setLoggingIn(state: boolean) {
    this.setState({
      ...this.state,
      loggingIn: state,
      loggingOut: false,
    });
  }

  public setLoggingOut(state: boolean) {
    this.setState({
      ...this.state,
      loggingIn: false,
      loggingOut: state,
    });
  }

  public setSubmittingCredentials(state: boolean) {
    this.setState({
      ...this.state,
      submittingCredentials: state,
    });
  }
}
