import { Button, Typography } from "@mui/material";
import React, {
  Fragment,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { AppStore } from "../../../../AppStore";
import { DrawerItem } from "../../../../components/drawer/components/DrawerItem";
import { DeleteUtility } from "../../../../components/gridManager/components/DeleteUtility";
import Popup from "../../../../components/popup/Popup";
import { WebSocketContext } from "../../../../providers/WebSocketProvider";
import { Company } from "../../../../services/company/models/Company";
import { AddCompanyForm } from "../components/AddCompanyForm";
import CompaniesGrid from "./Grid";
import styles from "../styles/Components.css";

interface Props {}

const Body: FunctionComponent<Props> = (props) => {
  const [, gridActions] = AppStore.grid.use();
  const [, drawerActions] = AppStore.drawer.use();
  const [state, actions] = AppStore.companies.use();
  const { connected } = useContext(WebSocketContext);

  const handleSelected = (company: any) => {
    actions.setHighlighted(company);
  };

  const handleDeleteCompany = () => {
    actions
      .deleteHighlighted()
      .then(actions.updateCollection)
      .catch(console.log);
  };

  const updateDrawer = () => {
    actions.getCachedCollection().then((companies) => {
      const items: DrawerItem[] = [];
      let highlighted: DrawerItem | undefined;

      companies.forEach((company) => {
        const drawerItem = new DrawerItem();

        drawerItem.primary = company.name;
        drawerItem.secondary = company.location;
        drawerItem.model = company;
        drawerItem.onClick = handleSelected;

        items.push(drawerItem);

        if (state.highlighted && company.id == state.highlighted.id)
          highlighted = drawerItem;

        if (!highlighted && !state.highlighted) {
          highlighted = drawerItem;
        }
      });

      if (highlighted) actions.setHighlighted(highlighted.model as Company);

      drawerActions.setHighlighted(highlighted);
      drawerActions.setItems(items);
    });
  };

  useEffect(() => {
    gridActions.clear();
    gridActions.initGrid(CompaniesGrid);
    gridActions.setUtilities([
      <DeleteUtility
        requireConfirm
        onDelete={handleDeleteCompany}
        title={`Are you sure you want to delete this company?`}
      />,
    ]);
    drawerActions.setTitle("company");
    drawerActions.setSections(undefined);
    drawerActions.setAddModelFormTitle("Company");
    drawerActions.setAddModelForm(<AddCompanyForm />);
  }, []);

  useEffect(() => {
    updateDrawer();
  }, [state.collection]);

  useEffect(() => {
    if (state.highlighted) {
      gridActions.setPrimaryTitle(state.highlighted.name);
      gridActions.setSecondaryTitle(state.highlighted.location);
      gridActions.setLoading(false);
    }
  }, [state.highlighted]);

  return (
    <Fragment>
      {/* <Popup visible={!connected}>
        <Typography align="center">
          Connecting to server...
        </Typography>
      </Popup> */}
    </Fragment>
  );
};

export default Body;
