import { Button } from "@mui/material";
import React, { Fragment, FunctionComponent, useState } from "react";
import { AppStore } from "../../../../AppStore";
import { GridCardUtilityIcon } from "../../../../components/gridManager/components/GridCard";
import List from "../../../../components/list/List";
import Popup from "../../../../components/popup/Popup";
import { System } from "../../../../services/system/models/System";
import { SystemService } from "../../../../services/system/SystemService";
import styles from "../styles/Components.css";

interface Props {
  project: string;
  onClick?: (event) => void;
}

export const AddSystemToProjectForm: FunctionComponent<Props> = (props) => {
  const [state, actions] = AppStore.systems.use();
  const [companyState, companyActions] = AppStore.companies.use();
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState<any>();
  const [systems, setSystems] = useState<(System | undefined)[]>([]);

  const handleCloseForm = (event) => {
    setShow(false);
  };

  const handleAddSystem = () => {
    if (selected && companyState.highlighted)
      SystemService.assignToProject(
        selected,
        companyState.highlighted.id,
        props.project
      )
        .then(handleCloseForm)
        .then(() => companyActions.updateHighlighted(companyState.highlighted?.id))
        .then(() => actions.updateParentCollection(companyState.highlighted))
        .catch(console.log);
  };

  const filterSystems = (systems: System[]) => {
    const project = companyState.highlighted?.projects.find(
      (project) => project.name == props.project
    );

    return systems.map((s) => {

      if (project && !project.systems.find(ref => ref.sid == s.ssid))
        return s;
    });
  };

  const handleOpenForm = () => {
    setShow(true);

    actions
      .getCachedParentCollection(companyState.highlighted)
      .then(filterSystems)
      .then(setSystems)
      .catch(console.log);
  };

  const handleClick = (event) => {
    handleOpenForm();
    props.onClick?.(event);
  };

  return (
    <div className={styles.projectUtilityButton}>
      <Popup
        visible={show}
        onDismiss={handleCloseForm}
        title={`Add System to ${props.project}`}
      >
        <List
          items={systems}
          title="systems"
          primaryField="sid"
          secondaryField=""
          onSelect={setSelected}
        />
        <div className={styles.formButtonContainer}>
          <Button onClick={handleAddSystem} variant="contained">
            Add
          </Button>
        </div>
      </Popup>
      <GridCardUtilityIcon
        src="./icons/plus.png"
        width={15}
        height={15}
        onClick={handleClick}
      />
    </div>
  );
};
