import { ListItem, ListItemText } from "@mui/material";
import { string } from "prop-types";
import React, { FunctionComponent, useEffect, useState } from "react";
import { AppStore } from "../../../AppStore";
import styles from "../styles/Drawer.css";
import { DrawerItem, DrawerItemComponent } from "./DrawerItem";

interface Props {
  index: number;
  name: string;
  items: DrawerItem[];
  utility?: JSX.Element;
  onClick: () => void;
  createDrawerItem: (item: DrawerItem, index: number) => void;
}

export class DrawerSection {
  index: number;
  name: string;
  items: DrawerItem[];
  utility?: JSX.Element;
}

export const DrawerSectionComponent: FunctionComponent<Props> = (props) => {
  const [state, actions] = AppStore.drawer.use();
  const [expanded, setExpanded] = useState(false);

  const handleClick = (event) => {
    if (event.target.textContent != props.name) return;

    handleExpand();
  };

  const handleExpand = () => {
    props.onClick();
    setExpanded(!expanded);
  }

  useEffect(() => {
    setExpanded(state.expandedSection == props.index)
  }, [state.expandedSection])
  
  useEffect(() => {
    if (!state.highlighted) return;

    // if (props.items.includes(state.highlighted)) setExpanded(true);
  }, [state.highlighted]);

  return (
    <>
      <ListItem
        button
        onClick={handleClick}
        disableRipple
        className={`${styles.drawerSection} 
          ${expanded ? styles.expandedSection : ""}
          `}
      >
        <ListItemText primary={props.name} />
        {props.utility}
        <img
          onClick={handleExpand}
          src="./icons/expand-arrow.png"
          width={15}
          height={15}
          style={{ transform: expanded ? "rotate(180deg)" : "rotate(90deg)" }}
        />
      </ListItem>
      {expanded && props.items.map(props.createDrawerItem)}
    </>
  );
};
