import { FormModel } from "../../../utils/FormModel";

export class SystemManifestWrapper extends FormModel {
    public sid: string = "";
    public manifest: string = "";
}

export class SystemManifest extends FormModel {
    public id: string = "";
    public sid: string = "";
    public ssid: string = "";
    public topology: SystemTopology = new SystemTopology();
    public settings: SystemSettings = new SystemSettings();
}

export class SystemSettings extends FormModel  {
    public name: string = "";
    public serial: string = "";
    public type: number = 0;
    public configurationKey: string = "";
    public primaryUploadFrequency: number = 0;
    public diagnosticUploadFrequency: number = 0;
}

export class SystemTopology extends FormModel {
    public devices: DeviceManifest[] = [];
    public diagnostics: DiagnosticManifest[] = [];
    public subSystems: SystemManifest[] = [];
}
  

export class DeviceManifest extends FormModel {
    public name: string = "";
    public ssid: string = "";
    public units: number = 0;
    public category: number = 0;
    public calibrationParams: calibrationParams[] = [];
}

export class DiagnosticManifest {
    public name: string = "";
    public ssid: string = "";
    public units: number = 0;
    public category: number = 0;
    public calibrationParams: calibrationParams[] = [];
}


export class calibrationParams extends FormModel {
    public name: string = "";
    public units: number = 0;
    public value: string = "";
}