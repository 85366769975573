import { DataGrid, GridColumns } from "@mui/x-data-grid";
import React, { FunctionComponent, useEffect, useState } from "react";
import { AppStore } from "../../../../AppStore";
import { GridCard } from "../../../../components/gridManager/components/GridCard";
import TableCellExpand from "../../../../components/gridManager/components/TableCellExpand";
import { IGridComponent } from "../../../../components/gridManager/GridManager";
import { ChangeEvent } from "../../../../services/event/models/Event";
import styles from "../styles/Components.css";

interface Props extends IGridComponent {}

const EventsList: FunctionComponent<Props> = (props) => {
  const [companies, companiesActions] = AppStore.companies.use();
  const [state, actions] = AppStore.events.use();
  const [users, setEvents] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  const columns: GridColumns = [
    {
      field: "originator",
      headerName: "Originator",
      width: 200,
    },
    {
      field: "message",
      headerName: "Message",
      width: 300,
      renderCell: TableCellExpand
    },
    {
      field: "date",
      headerName: "Date",
      width: 250,
    },
  ];

  const processEvents = (events: ChangeEvent[]) => {
    const processed: any[] = [];

    events.forEach((event, index) => {
      processed.push({
        originator: event.user,
        message: event.message,
        date: event.dateGenerated,
        id: index,
      });
    });

    return processed;
  };

  const handleEvents = () => {
    if (companies.highlighted)
      actions
        .getCachedParentCollection(companies.highlighted)
        .then(processEvents)
        .then(setEvents)
        .then(() => setLoading(false))
        .catch(console.log);
  };

  useEffect(() => {
    setLoading(true);
    handleEvents();
  }, [companies.highlighted]);

  useEffect(() => {
    if (!loading) handleEvents();
  }, [state.parentCollection]);

  return (
    <GridCard
      gridIndex={props.gridIndex}
      expandable
      loading={loading}
      icon="./icons/flash.png"
      title="Events"
    >
      <div className={styles.listContainer}>
        <DataGrid
          rows={users}
          columns={columns}
          loading={loading}
          rowHeight={30}
          headerHeight={30}
          sx={{
            "& .MuiDataGrid-columnSeparator": {
              visibility: "hidden",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#e9e9e9",
            },
          }}
          hideFooter
        />
      </div>
    </GridCard>
  );
};

export default EventsList;
