import React, { FunctionComponent, useContext, useEffect } from "react";
import { API } from "../../../../api";
import { AppStore } from "../../../../AppStore";
import { WebSocketContext } from "../../../../providers/WebSocketProvider";
import styles from "../styles/UsersDashboard.css";
import Body from "./Body";

interface Props {}

const UsersDashboard: FunctionComponent<Props> = () => {

    return (
        <div className={styles.bodyContainer} >
            <Body />
        </div>
    )
}

export default UsersDashboard;