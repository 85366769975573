// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".G7Dg5vdqOY9D_oh7UC7V {\n    width: 100%;\n    margin-bottom: 10px;\n}\n\n.h2AIGzw3xcnN8KxCQPw9 {\n    margin-bottom: 10px;\n\n}\n\n._yPXWIZmHtZh46r25ExC {\n    margin-bottom: 10px !important;\n\n}\n\n.h2AIGzw3xcnN8KxCQPw9:hover {\n    background-color: #f6f6f6;\n}", "",{"version":3,"sources":["webpack://./src/components/form/styles/Components.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;;AAEvB;;AAEA;IACI,8BAA8B;;AAElC;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".dropdownContainer {\n    width: 100%;\n    margin-bottom: 10px;\n}\n\n.input {\n    margin-bottom: 10px;\n\n}\n\n.dateSelect {\n    margin-bottom: 10px !important;\n\n}\n\n.input:hover {\n    background-color: #f6f6f6;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdownContainer": "G7Dg5vdqOY9D_oh7UC7V",
	"input": "h2AIGzw3xcnN8KxCQPw9",
	"dateSelect": "_yPXWIZmHtZh46r25ExC"
};
export default ___CSS_LOADER_EXPORT___;
