import { FormModel } from "../../../utils/FormModel";
import { Device } from "../../device/models/Device";
import { Diagnostic } from "../../diagnostic/models/Diagnostic";
import { Journal, JournalEntry } from "./Journal";
import { SystemAlert } from "./SystemAlert";

export class System extends FormModel {
    
    public id: string = "";
    public sid: string = "";
    public ssid: string = "";
    public rank: string = "";
    public type: string = "";
    public health: string = "";
    public version: string = "";
    public companyIds: string[] = [];
    public alerts: SystemAlert[] = [];
    public journal: Journal = new Journal();
    public lastUploaded: string = "";
    public lastUploadReceived: string = "";
    public dateCreated: string = "";
    public configurationKey: string = "";
    public primaryUploadFrequency: number = 0;
    public diagnosticUploadFrequency: number = 0;

    public isOnline(): boolean {
        const buffer = 10; // 10 second buffer
        const frequency = this.primaryUploadFrequency * 1000;
        const uploadDifference = Date.now() - Date.parse(this.lastUploadReceived);
        const normalized = Math.abs(uploadDifference);

        return normalized < (frequency + buffer);
    }

}

export class AssembledSystem extends System {
    public devices: Device[] = [];
    public subSystems: System[] = [];
    public diagnostics: Diagnostic[] = [];
}

export enum SystemType {
    OTHER = 0,
    AQSYNC = 1,
    PAM = 2,
    OZONE = 3,
    GPS = 4,
    NOX = 5,
    CO2 = 6,
    CO = 7,
    PARTICLE = 8,
    WEATHER = 9
}

export enum SystemHealth {
    Pending = "Pending",
    Healthy = "Healthy",
    Unhealthy = "Unhealthy",
    Moderate = "Moderate",
    Undetermined = "Undetermined",
  }
  