import { FormModel } from "../../../utils/FormModel";

export class Journal extends FormModel {
    public entries: JournalEntry[] = [];
}

export class JournalEntry extends FormModel {
    public user: string = "";
    public message: string = "";
    public date: Date = new Date();
    public type: string = EntryType.User;
}

export enum EntryType {
    Admin = "Admin",
    User = "User"
}