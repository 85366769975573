
import { Api } from "@mui/icons-material";
import {
  IModelActions,
  IModelState,
  ModelStore
} from "../../utils/ModelStore";
import { Company } from "../company/models/Company";
import { DeviceCategory } from "../device/models/DeviceGroup";
import { System } from "./models/System";
import { SystemService } from "./SystemService";

export interface SystemsState extends IModelState<System> {
}

export interface SystemsActions extends IModelActions<System> {
}

export class SystemsStore
  extends ModelStore<System, SystemsState, SystemsActions>
  implements SystemsActions
{
  protected prototype = SystemsStore.prototype;
  protected initialState = {
    collection: [],
    parentCollection: [],
    highlighted: undefined,
    highlightedParent: undefined,
    lastUpdatedCollection: undefined,
    lastUpdatedHighlighted: undefined,
    lastUpdatedParentCollection: undefined,
    cachedCapacity: 30,
    api: SystemService
  };

  constructor() {
    super();
  }


}
