import { Button, Chip, Typography } from "@mui/material";
import React, {
  Fragment,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../../App";
import { AppStore } from "../../../../AppStore";
import { DrawerItem } from "../../../../components/drawer/components/DrawerItem";
import { DrawerSection } from "../../../../components/drawer/components/DrawerSection";
import Popup from "../../../../components/popup/Popup";
import { ObjectId } from "../../../../helpers/objectid";
import { NotificationContext } from "../../../../providers/Notifications";
import { Company } from "../../../../services/company/models/Company";
import { System } from "../../../../services/system/models/System";
import { AddSystemToProjectForm } from "../components/AddSystemToProjectForm";
import SystemGrid from "./Grid";
import styles from "../styles/Components.css";
import { AuthenticationContext } from "../../../../providers/AuthenticationProvider";
import { FormatStringToDate } from "../../../../helpers/time";
import { RouterContext } from "../../../../providers/RouterProvider";

interface Props {}

const Body: FunctionComponent<Props> = (props) => {
  const [companyState, companyActions] = AppStore.companies.use();
  const [, gridActions] = AppStore.grid.use();
  const [drawerState, drawerActions] = AppStore.drawer.use();
  const [state, actions] = AppStore.systems.use();
  const [account] = AppStore.account.use();
  const [hasCompany, setHasCompany] = useState(true);
  const history = useHistory();
  const { clearNotifications } = useContext(NotificationContext);
  const authContext = React.useContext(AuthenticationContext);

  const handleSelected = (system: any) => {
    actions.setHighlighted(system);
  };

  const handleViewSystem = (system: any) => {
    handleSelected(system);
    history.push(Routes.System);
  };

  const handleLogout = () => {
    authContext.logout();
  };

  const updateDrawer = (company: Company) => {
    actions.getCachedParentCollection(company).then((systems) => {
      const sections: DrawerSection[] = [];
      let highlighted: DrawerItem | undefined;

      company.projects.forEach((project, index) => {
        const section = new DrawerSection();
        const items: DrawerItem[] = [];

        section.index = index;
        section.name = project.name;
        section.utility = <AddSystemToProjectForm project={project.name} />;

        project.systems.forEach((reference) => {
          const system = systems.find((s) => s.sid == reference.sid);

          const utilities: JSX.Element[] = [];
          utilities.push(
            <Chip
              label="View"
              className={styles.viewSystemChip}
              variant="outlined"
              onClick={() => handleViewSystem(system)}
              size="small"
              key={1}
            />
          );

          if (!system) return;

          const drawerItem = new DrawerItem();

          drawerItem.primary = reference.nickname;
          drawerItem.secondary = FormatStringToDate(system.lastUploaded);
          drawerItem.model = system;
          drawerItem.children = utilities;
          drawerItem.onClick = handleSelected;

          items.push(drawerItem);

          if (state.highlighted && system.id == state.highlighted.id)
            highlighted = drawerItem;

          if (!highlighted && !state.highlighted) {
            highlighted = drawerItem;
          }
        });

        section.items = items;

        sections.push(section);
      });

      if (highlighted) actions.setHighlighted(highlighted.model as System);

      drawerActions.setHighlighted(highlighted);
      drawerActions.setSections(sections);
      drawerActions.setExpandedSection(undefined)
    });
  };

  useEffect(() => {
    clearNotifications();

    gridActions.clear();
    gridActions.initGrid(SystemGrid);
    drawerActions.setTitle("system");

    if (!account.user || ObjectId.isDefault(account.user?.companyId))
      setHasCompany(false);
    else companyActions.getCachedHighlighted(account.user?.companyId);
  }, []);

  useEffect(() => {
    if (companyState.highlighted) updateDrawer(companyState.highlighted);

  }, [state.parentCollection, companyState.highlighted]);

  useEffect(() => {
    gridActions.setSecondaryTitle(undefined);
    gridActions.setPrimaryTitle(undefined);
    gridActions.setLoading(false);

    drawerActions.setHighlighted(
      drawerState.items?.find((item) => item.model.id == state.highlighted?.id)
    );
  }, [state.highlighted]);

  return (
    <Fragment>
      <Popup visible={!hasCompany}>
        <Typography align="center">
          You are not assigned to a company.
        </Typography>
        <Typography align="center">
          Please contact support at techsupport@2btech.io
        </Typography>
        <Typography align="center">to be assigned to a company.</Typography>
        <br />
        <Button
          variant="contained"
          onClick={handleLogout}
          className={styles.formButtonContainer}
        >
          Logout
        </Button>
      </Popup>
    </Fragment>
  );
};

export default Body;
