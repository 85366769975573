import { Typography } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { FunctionComponent, useEffect, useState } from "react";
import Loader from "react-spinners/BarLoader";
import { AppStore } from "../../../../AppStore";
import { Device } from "../../../../services/device/models/Device";
import { UploadService } from "../../../../services/upload/UploadService";

import styles from "../../styles/Components.css";

interface Props {}

const DeviceDetailGraph: FunctionComponent<Props> = (props) => {
  const [systemsState, systemsActions] = AppStore.systems.use();
  const [state, actions] = AppStore.devices.use();
  const [drawerState] = AppStore.drawer.use();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();
  const [device, setDevice] = useState<Device>();
  const [timeSeries, setTimeSeries] = useState<[Date, Date, number]>([
    new Date(),
    new Date(),
    0,
  ]);
  const [options, setOptions] = useState<any>({
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    yAxis: {},
    xAxis: {
      type: "datetime",
    },
    time: {
      useUTC: false,
    },
    legend: {
      enabled: false,
    },
    chart: {
      zoomType: "x",
      // type: 'spline'
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
      },
    },
    series: [],
  });

  const handleChangeTimeFrame = (e: any) => {
    const timeframe = e.target.id;
    const [, , average] = timeSeries;

    const to = new Date();
    let from = new Date();

    setLoading(true);

    switch (timeframe) {
      case "month":
        from.setDate(to.getDate() - 30);
        break;
      case "week":
        from.setDate(to.getDate() - 7);
        break;
      default:
        from.setDate(to.getDate() - 1);
    }

    setTimeSeries([from, to, average]);
  };

  const handleChangeAverage = (e: any) => {
    const select = e.target.id;
    const [from, to] = timeSeries;

    let [, , average] = timeSeries;

    setLoading(true);

    switch (select) {
      case "1h":
        average = 60;
        break;
      case "4h":
        average = 4 * 60;
        break;
      case "8h":
        average = 8 * 60;
        break;
      default:
        average = 0;
    }

    setTimeSeries([from, to, average]);
  };

  const retreiveData = (
    device: Device,
    from: Date,
    to: Date,
    average: number
  ) => {
    if (systemsState.highlighted && state.highlighted)
      UploadService.getPrimaryUploadTimeSeriesForDeviceBySystem(
        systemsState.highlighted.sid,
        device.ssid,
        device.name,
        from, 
        to,
        average
      )
        .then(preProcess)
        .then(setData)
        .catch(console.log);
  };

  const preProcess = (data: any) => {
    if (!device || Object.keys(data).length == 0) return {};

    const processed: any = {};

    processed[device.uniqueName] = data[device.uniqueName].map((element) => {
      return [
        new Date(Date.parse(element.averagedStartDate)).getTime(),
        parseFloat(element.dataPoint.value),
      ];
    });

    return processed;
  };

  useEffect(() => {
    if (data && device) {
      setOptions({
        ...options,
        series: [{ name: device.uniqueName, data: data[device.uniqueName] }],
      });

      setLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (device) {
      setOptions({
        ...options,
        series: [
          {
            name: device.name,
          },
        ],
      });

      const to = new Date();
      const from = new Date();
      const average = 0;

      from.setDate(from.getDate() - 1); // 1 day from now

      setTimeSeries([from, to, average]);
    }
  }, [device]);

  useEffect(() => {
    if (device && timeSeries && timeSeries.length == 3) {
      const [from, to, average] = timeSeries;

      retreiveData(device, from, to, average);
    }
  }, [timeSeries]);

  useEffect(() => {
    setLoading(true);
    if (state.highlighted) setDevice(state.highlighted);
  }, [state.highlighted]);

  return (
    <div className={styles.detailContainer}>
      {loading ? (
        <div className={styles.loading}>
          <Loader />
        </div>
      ) : (
        <>
          <div className={styles.deviceGraphTimeFrameSelector}>
            <Typography
              className={styles.graphTimeFrameTitle}
              variant="subtitle1"
            >
              Time Frame
            </Typography>
            <Typography
              className={styles.graphTimeFrameOption}
              id={"day"}
              onClick={handleChangeTimeFrame}
              variant="body2"
            >
              Day
            </Typography>
            <Typography
              className={styles.graphTimeFrameOption}
              id={"week"}
              onClick={handleChangeTimeFrame}
              variant="body2"
            >
              Week
            </Typography>
            <Typography
              className={styles.graphTimeFrameOption}
              id={"month"}
              onClick={handleChangeTimeFrame}
              variant="body2"
            >
              Month
            </Typography>
            <Typography
              className={styles.graphTimeFrameTitle}
              variant="subtitle1"
            >
              Average
            </Typography>
            <Typography
              className={styles.graphTimeFrameOption}
              id={"raw"}
              onClick={handleChangeAverage}
              variant="body2"
            >
              Raw
            </Typography>
            <Typography
              className={styles.graphTimeFrameOption}
              id={"1h"}
              onClick={handleChangeAverage}
              variant="body2"
            >
              1 Hour
            </Typography>
            <Typography
              className={styles.graphTimeFrameOption}
              id={"4h"}
              onClick={handleChangeAverage}
              variant="body2"
            >
              4 Hours
            </Typography>
            <Typography
              className={styles.graphTimeFrameOption}
              id={"8h"}
              onClick={handleChangeAverage}
              variant="body2"
            >
              8 Hours
            </Typography>
          </div>
          <HighchartsReact
            containerProps={{ className: styles.graphContainer }}
            highcharts={Highcharts}
            options={options}
          />
        </>
      )}
    </div>
  );
};

export default DeviceDetailGraph;
