// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bOkI_Ii8WWGyZYaJHcq5 {\n  padding-top: 10px;\n  flex-direction: column;\n  justify-content: center;\n}\n\n.zgQg60kwGE59Lnn68o0n {\n  color: #6a6a6a;\n}\n\n._1i__KrLtKmACLEVCpS_ {\n  width: 100%;\n  height: calc(100% - 10px);\n  display: flex;\n  padding-top: 10px;\n}\n\n.oFkp_bmpAd73YHUfgQmW {\n  display: none !important;\n  /* background-color: black; */\n}\n\n.G51bLyqL0IC_kKWWy35N {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  align-items: center;\n  padding-top: 10px;\n}\n\n.a3ghb21CBID4VKskbyS5 {\n  display: flex;\n  width: 100%;\n  justify-content: end;\n}", "",{"version":3,"sources":["webpack://./src/areas/admin/companies/styles/Components.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,wBAAwB;EACxB,6BAA6B;AAC/B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,oBAAoB;AACtB","sourcesContent":[".companyInfoBodyItem {\n  padding-top: 10px;\n  flex-direction: column;\n  justify-content: center;\n}\n\n.companyInfoBodyContent {\n  color: #6a6a6a;\n}\n\n.listContainer {\n  width: 100%;\n  height: calc(100% - 10px);\n  display: flex;\n  padding-top: 10px;\n}\n\n.listHeaderItem {\n  display: none !important;\n  /* background-color: black; */\n}\n\n.addUserButtonContainer {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  align-items: center;\n  padding-top: 10px;\n}\n\n.submitButtonContainer {\n  display: flex;\n  width: 100%;\n  justify-content: end;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"companyInfoBodyItem": "bOkI_Ii8WWGyZYaJHcq5",
	"companyInfoBodyContent": "zgQg60kwGE59Lnn68o0n",
	"listContainer": "_1i__KrLtKmACLEVCpS_",
	"listHeaderItem": "oFkp_bmpAd73YHUfgQmW",
	"addUserButtonContainer": "G51bLyqL0IC_kKWWy35N",
	"submitButtonContainer": "a3ghb21CBID4VKskbyS5"
};
export default ___CSS_LOADER_EXPORT___;
