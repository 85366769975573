import React, { FunctionComponent, useState } from "react";
import { FormModel } from "../../utils/FormModel";
import Loader from "../loader/Loader";
import ListHeader from "./components/ListHeader";
import { ListItemComponent } from "./components/ListItem";
import styles from "./styles/List.css";

interface Props {
  items: any[];
  title: string;
  primaryField?: string;
  secondaryField?: string;
  onSelect?: (model: FormModel) => void;
}

const List: FunctionComponent<Props> = (props) => {
  const [highlighted, setHighlighted] = useState<FormModel | undefined>();
  const [filter, setFilter] = useState("");

  const handleSelected = (model: FormModel) => {
    setHighlighted(model);
    props.onSelect?.(model);
  };

  const createListItem = (item: any, index: number) => {

    if (!item) return;

    if (!props.primaryField)
      return (
        <ListItemComponent
          key={index}
          primary={item}
          onClick={handleSelected}
        />
      )

    if ((item as any)[props.primaryField].toLowerCase().includes(filter.toLowerCase()))
      return (
        <ListItemComponent
          key={index}
          model={item}
          primary={(item as any)[props.primaryField]}
          secondary={(item as any)[props.secondaryField ?? ""]}
          onClick={handleSelected}
          highlighted={highlighted?.id == item.id}
        />
      );
  };

  return (
    <div className={styles.container}>
      <ListHeader onSearch={setFilter} title={props.title} />
      <div className={styles.listItemContainer}>
        {props.items ? props.items.map(createListItem) : <Loader />}
      </div>
    </div>
  );
};

export default List;
