import { FormModel } from "../../../utils/FormModel";

export class Company extends FormModel {
    
    public id: string = "";
    public name: string = "";
    public contactEmail: string = "";
    public contactName: string = "";
    public location: string = "";
    public description: string = "";
    public projects: Project[] = [];
}

export class Project extends FormModel {
    public name: string = "";
    public systems: SystemReference[] = [];
}

class SystemReference extends FormModel {
    public sid: string = "";
    public nickname: string = "";
}