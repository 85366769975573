import { FormModel } from "../../../utils/FormModel";

export class Correlation extends FormModel {
    public id: string = "";
    public name: string = "";
    public companyId: string = "";
    public start: Date = new Date();
    public end: Date = new Date();
    public average: number = 0;
    public toPresent: boolean = true;
    public components: string[] = []; 
}