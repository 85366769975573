import React, {
  createContext,
  Fragment,
  FunctionComponent,
  useEffect,
  useMemo,
  useState,
} from "react";
import ReactGridLayout from "react-grid-layout";
import { withSize } from "react-sizeme";
import { AppStore } from "../../AppStore";
import Loader from "../loader/Loader";
import { Grid, GridCardExpand } from "./components/Grid";
import GridHeader from "./components/GridHeader";
import styles from "./styles/GridManager.css";

interface GridManagerProps {}

interface IGridContext {
  resizing: boolean;
  toggleGridLock: (toggle: boolean) => void;
  expandCard: (expand: GridCardExpand) => void;
  scaleCard: (expand: GridCardExpand) => void;
}

const initialContext: IGridContext = {
  resizing: false,
  toggleGridLock: (toggle: boolean) => {},
  expandCard: (expand: GridCardExpand) => {},
  scaleCard: (expand: GridCardExpand) => {},
};

interface ResizableGridProps {
  layout: any;
  onLayoutChange: (layout: any) => void;
  size: any;
}

export interface IGridComponent {
  gridIndex?: number;
}

export const ResizableGrid: FunctionComponent<ResizableGridProps> = (props) => {
  return (
    <ReactGridLayout
      width={props.size.width}
      layout={props.layout}
      onLayoutChange={props.onLayoutChange}
      className={`${styles.grid} ${styles.preload} layout`}
      compactType={null}
      rowHeight={20}
      cols={12}
      margin={[15, 15]}
    >
      {props.children}
    </ReactGridLayout>
  );
};

export const GridContext = createContext<IGridContext>(initialContext);

const GridContainer = withSize({ monitorWidth: true })(ResizableGrid);

const GridManager: FunctionComponent<GridManagerProps> = (props) => {
  const [state, actions] = AppStore.grid.use();
  const [drawerState] = AppStore.drawer.use();
  const [scrolling, setScrolling] = useState(false);
  const [animationsBuffered, setAnimationsBuffered] = useState(true);

  const handleToggleGridLock = (toggle) => {
    actions.setLocked(toggle);
  };

  const handleLayoutChanged = (layout) => {
    actions.updateGridLayout(layout);
  };

  const expandCard = (expand: GridCardExpand) => {
    actions.toggleExpandedCard(expand);
  };

  const scaleCard = (card: GridCardExpand) => {
    actions.scaleCard(card);
  };

  const createGridItem = (element: JSX.Element, index: number) => {
    return (
      <div key={index} className={styles.gridItem}>
        {React.cloneElement(element, { ...element.props, gridIndex: index })}
      </div>
    );
  };

  const MemoizedChildren = useMemo(
    () => state.grid?.components.map(createGridItem),
    [state.grid?.id]
  );
  
  const handleScroll = (event) => {
    if (event.currentTarget.scrollTop > 10) setScrolling(true);
    else setScrolling(false);
  };

  const expand = (card: GridCardExpand) => {
    if (!state.grid || card.index == -1) return;

    const expanded = state.grid.layout.find(
      (item) => parseInt(item.i) == card.index
    );

    if (!expanded) return;

    const expandRatio = 12 / expanded.w;
    let height = expanded.h ? Math.round(expanded.h * expandRatio) : 0;

    if (expandRatio == 1) height = height * 1.5;

    height = height + card.scale;

    const newLayout = state.grid.layout.map((item) => {
      if (parseInt(item.i) == card.index) {
        return {
          ...item,
          w: 12,
          h: height,
          x: 0,
        };
      } else if (parseInt(item.i) > card.index)
        return { ...item, y: item.y + (height - expanded.h) };
      else return item;
    });

    handleLayoutChanged(newLayout);
  };

  const [gridContext, setGridContext] = useState<IGridContext>({
    ...initialContext,
    toggleGridLock: handleToggleGridLock,
    expandCard: expandCard,
    scaleCard: scaleCard,
  });

  useEffect(() => {
    let isMounted = true;
    setGridContext({ ...gridContext, resizing: true });
    setTimeout(() => {
      if (isMounted) {
        setAnimationsBuffered(false);
        setGridContext({ ...gridContext, resizing: false });
      }
    }, 400);
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    setGridContext({ ...gridContext, resizing: drawerState.animating });
  }, [drawerState.animating]);

  useEffect(() => {
    if (!state.grid) return;

    const newLayout = state.grid.layout.map((item) => {
      return {
        ...item,
        static: state.locked,
        isDraggable: !state.locked,
      };
    });

    handleLayoutChanged(newLayout);
  }, [state.locked]);

  useEffect(() => {
    actions.setScrolling(scrolling); // Better performance allowing react to handle onScroll state changes
    // before passing to grid state
  }, [scrolling]);

  useEffect(() => {
    expand(state.expandedCard);
  }, [state.expandedCard]);

  useEffect(() => {
    state.scaledCards.forEach((card) => expand(card));
  }, [state.scaledCards]);

  return (
    <GridContext.Provider value={gridContext}>
      <div
        onScroll={handleScroll}
        className={`${styles.body}  ${
          animationsBuffered ? styles.preload : ""
        } ${drawerState.drawerToggled ? styles.openBody : styles.closeBody}`}
      >
        {!animationsBuffered && !state.loading && state.grid ? (
          <Fragment>
            {state.primaryTitle && (
              <GridHeader
                primary={state.primaryTitle}
                secondary={state.secondaryTitle}
                utilities={state.utilities}
              />
            )}
            <GridContainer layout={state.grid.layout} onLayoutChange={() => {}}>
              {MemoizedChildren}
            </GridContainer>
          </Fragment>
        ) : (
          <Loader />
        )}
      </div>
    </GridContext.Provider>
  );
};

export default GridManager;
