import { AxiosRequestConfig } from "axios";
import { API } from "../../api";
import { ApiClient } from "../../utils/ApiClient";
import { StatusRecord } from "./models/StatusRecord";

export class StatusRecordService {
  public static getAll() {
    const requestConfig: AxiosRequestConfig = {
      method: "GET",
      url: API.server.statusRecord.list,
    };

    return ApiClient.request(requestConfig).then((response) => {
      const records: StatusRecord[] = [];
      response.model.forEach((model) =>
        records.push(new StatusRecord().copy(model))
      );
      return records;
    });
  }

  public static getRecentBySystemId(id: string) {
    const requestConfig: AxiosRequestConfig = {
      method: "GET",
      url: API.server.statusRecord.recent.replace("%id%", id),
    };

    return ApiClient.request(requestConfig).then((response) =>
      new StatusRecord().copy(response.model)
    );
  }
}
