import { Typography } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, {
  FunctionComponent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AppStore } from "../../../../AppStore";
import { GridCard } from "../../../../components/gridManager/components/GridCard";
import { IGridComponent } from "../../../../components/gridManager/GridManager";
import { DeviceService } from "../../../../services/device/DeviceService";
import { Device, DeviceQuery } from "../../../../services/device/models/Device";
import { System } from "../../../../services/system/models/System";
import { UploadService } from "../../../../services/upload/UploadService";
import { DownloadGraph } from "../../../shared/components/DownloadGraphUtility";
import styles from "../styles/Components.css";

interface Props extends IGridComponent {}

const Graph: FunctionComponent<Props> = (props) => {
  const [drawerState] = AppStore.drawer.use();
  const [loading, setLoading] = useState(false);
  const [analysisState, analysisActions] = AppStore.analysis.use();
  const [previousChart, setPreviousChart] = useState<any>();
  const [graphedDevices, setGraphedDevices] = useState<Device[]>([]);
  const latestChart = useRef<any>();
  const toggleGraphLegend = useRef<boolean>(true);
  const [options, setOptions] = useState<any>({
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    boost: {
      useGPUTranslations: true,
      // Chart-level boost when there are more than 5 series in the chart
      seriesThreshold: 5,
    },
    yAxis: {},
    xAxis: {
      type: "datetime",
    },
    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "middle",
    },
    time: {
      useUTC: false,
    },
    chart: {
      zoomType: "x",
      renderTo: "container",
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
        events: {
          legendItemClick: (e) => {
            setPreviousChart(e.target.chart);

            if (!e.target.visible) return true;
            toggleGraphLegend.current = !toggleGraphLegend.current;

            const seriesIndex = e.target.index;
            const series = e.target.chart.series;

            for (let i = 0; i < series.length; i++) {
              if (series[i].index != seriesIndex) {
                toggleGraphLegend.current ? series[i].show() : series[i].hide();
              }
            }

            return false;
          },
        },
      },
    },
    series: [],
  });

  const retrieveData = async (
    devices: Device[],
    series: [Date, Date, number]
  ) => {
    const [from, to, average] = series;
    const deviceQueries: DeviceQuery[] = [];

    await Promise.all<any>(
      devices.map((device) => {
        return UploadService.getPrimaryUploadTimeSeriesForDeviceBySystem(
          device.sid,
          device.ssid,
          device.name,
          from,
          to,
          average
        );
      })
    )
      .then(preProcess)
      .then(handleData)
      .catch(console.log);
  };

  const fetchDevices = async (deviceIds: string[]) => {
    await Promise.all<any>(
      deviceIds.map((id) => {
        return DeviceService.getById(id);
      })
    )
      .then(analysisActions.setGraphedDevices)
      .catch(console.log);
  };

  const preProcess = (data: any) => {
    const processed: any = {};
    Object.keys(data).forEach((index) => {
      if (Object.keys(data[index]).length > 0) {
        const device = Object.keys(data[index])[0];
        const deviceUnique = device+data[index][device][0].dataPoint.sid
        const dataPoints = data[index][device];
        processed[deviceUnique] = dataPoints.map((element) => {
          return [
            new Date(Date.parse(element.averagedStartDate)).getTime(),
            parseFloat(element.dataPoint.value),
          ];
        });
      }
    });

    return processed;
  };

  const handleData = (data: any) => {
    if (data) {
      setOptions({
        ...options,
        series: options.series.map((serie) => {
          return {
            name: serie.name,
            data: data[serie.actualUnique] ? data[serie.actualUnique] : [],
            id: serie.actualUnique,
            metadata: serie.metadata,
          };
        }),
      });
    }
  };

  useEffect(() => {
    setLoading(drawerState.animating);
  }, [drawerState.animating]);

  const handleDevices = (devices: Device[]) => {
    // const filtered = devices.filter(
    //   (d) =>
    //     MeasurementType[d.measurementType] == MeasurementType.NUMERIC ||
    //     MeasurementType[d.measurementType] == MeasurementType.OTHER
    // );

    setOptions({
      ...options,
      series: devices.map<any>((device) => {
        return {
          name: `${device.name} (${device.sid}:${device.ssid})`,
          id: device.uniqueName,
          actualUnique: device.actualUniqueName,
          metadata: {
            ssid: device.ssid,
          },
        };
      }),
    });

    // setLoading(false)
  };

  useEffect(() => {
    // setLoading(true);
    handleDevices(analysisState.graphedDevices);
    setGraphedDevices(analysisState.graphedDevices);
  }, [analysisState.graphedDevices]);

  useEffect(() => {
    if (analysisState.graphedDevices.length > 0) {
      retrieveData(analysisState.graphedDevices, analysisState.timeSeries);
    }
  }, [graphedDevices, analysisState.timeSeries]);

  useEffect(() => {
    if (
      analysisState.currentGraph &&
      analysisState.currentGraph.components.length > 0
    )
      fetchDevices(analysisState.currentGraph.components);
  }, [analysisState.currentGraph]);

  useEffect(() => {
    console.log(analysisState.currentGraph)
    if (analysisState.currentGraph)
      retrieveData(analysisState.graphedDevices, analysisState.timeSeries);
  }, []);

  const MemoizedChart = useMemo(() => {
    return (
      <HighchartsReact
        ref={latestChart}
        containerProps={{ className: styles.graphContainer }}
        highcharts={Highcharts}
        options={options}
        updateArgs={[true, true, true]}
      />
    );
  }, [latestChart.current, Highcharts, options]);

  return (
    <GridCard
      loading={loading}
      icon="./icons/antena.png"
      title="Graph"
      // utility={
      //   <DownloadGraph
      //     timeSeries={analysisState.timeSeries}
      //     devices={analysisState.graphedDevices}
      //   />
      // }
    >
      {graphedDevices.length > 0 ? (
        MemoizedChart
      ) : (
        <div className={styles.graphPendingContainer}>
          <Typography>Select a Parameter...</Typography>
        </div>
      )}
    </GridCard>
  );
};

export default Graph;
