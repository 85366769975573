import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import { Button, TextField, Typography } from "@mui/material";
import Highcharts, { seriesType } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { object } from "prop-types";
import React, {
  createRef,
  Fragment,
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useRef } from "react";
import { API } from "../../../api";
import { AppStore } from "../../../AppStore";
import {
  GridCard,
  GridCardUtilityIcon,
} from "../../../components/gridManager/components/GridCard";
import { IGridComponent } from "../../../components/gridManager/GridManager";
import Loader from "../../../components/loader/Loader";
import Popup from "../../../components/popup/Popup";
import {
  convertUTCDateToLocalDate,
  FormatDateToString,
} from "../../../helpers/time";
import { WebSocketContext } from "../../../providers/WebSocketProvider";
import { Device } from "../../../services/device/models/Device";
import { MeasurementType } from "../../../services/device/models/DeviceMeasurement";
import { System } from "../../../services/system/models/System";
import { Upload } from "../../../services/upload/models/Upload";
import { UploadService } from "../../../services/upload/UploadService";

import styles from "../styles/Components.css";
import { DownloadGraph } from "./DownloadGraphUtility";
import { CustomTimeSeries } from "./GraphTimeSeries";

interface Props extends IGridComponent {}

const Graph: FunctionComponent<Props> = (props) => {
  const [systemsState, systemsActions] = AppStore.systems.use();
  const [deviceState, deviceActions] = AppStore.devices.use();
  const [currentSystem, setCurrentSystem] = useState<System | undefined>();
  const [drawerState] = AppStore.drawer.use();
  const [loading, setLoading] = useState(true);
  const [devices, setDevices] = useState<Device[]>([]);
  const [data, setData] = useState<any>();
  const [highlightedDevices, setHighlightedDevices] = useState<Device[]>([]);
  const [previousChart, setPreviousChart] = useState<any>();
  const { service, connected } = useContext(WebSocketContext);
  const { updates, uploads } = service.connections;
  const [averaging, setAveraging] = useState<number>(
    0,
  );
  const [timeFrame, setTimeFrame] = useState<string>(
    "day",
  );
  const [timeSeries, setTimeSeries] = useState<[Date, Date, number]>([
    new Date(),
    new Date(),
    0,
  ]);
  const latestChart = useRef<any>();
  const live = useRef(false);
  const toggleGraphLegend = useRef<boolean>(true);
  const [options, setOptions] = useState<any>({
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    boost: {
      useGPUTranslations: true,
      // Chart-level boost when there are more than 5 series in the chart
      seriesThreshold: 5,
    },
    yAxis: {},
    xAxis: {
      type: "datetime",
    },
    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "middle",
    },
    time: {
      useUTC: false,
    },
    chart: {
      zoomType: "x",
      renderTo: "container",
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
        events: {
          legendItemClick: (e) => {
            setPreviousChart(e.target.chart);

            if (!e.target.visible) return true;
            toggleGraphLegend.current = !toggleGraphLegend.current;

            const seriesIndex = e.target.index;
            const series = e.target.chart.series;

            for (let i = 0; i < series.length; i++) {
              if (series[i].index != seriesIndex) {
                toggleGraphLegend.current ? series[i].show() : series[i].hide();
              }
            }

            return false;
          },
        },
      },
    },
    series: [],
  });

  const handleChangeTimeFrame = (e: any) => {
    const timeframe = e.target.id;

    // const to = new Date();
    // let from = new Date();

    setLoading(true)

    switch (timeframe) {
      case "month":
        {
          setTimeFrame("month");
        }
        break;
      case "week":
        {
          setTimeFrame("week");
        }
        break;
      default:
        {
          setTimeFrame("day");
        }
    }
  }

  const handleChangeAverage = (e: any) => {
    const select = e.target.id;

    setLoading(true);
    let newAveraging = 0;

    switch (select) {
      case "1h":
        newAveraging = 60;
        break;
      case "4h":
        newAveraging = 4*60;
        break;
      case "8h":
        newAveraging = 8*60;
        break;
      default:
    }
    live.current = newAveraging == 0 ? true : false;

    setAveraging(newAveraging)
  };

  const retrieveData = (from: Date, to: Date, average: number) => {
    if (systemsState.highlighted)
    {
      const timeZoneChange = new Date().getTimezoneOffset()*60000;
      UploadService.getPrimaryUploadTimeSeries(
        systemsState.highlighted.sid,
        new Date(from.getTime()),
        new Date(to.getTime()+timeZoneChange),
        averaging
      )
        .then(preProcess)
        .then(handleData)
        .catch(console.log);
    }
  };

  const preProcess = (data: any) => {
    const processed: any = {};

    Object.keys(data).forEach((device) => {
      processed[device] = data[device].map((element) => {
        if (averaging != 0)
        {
          const timeZoneChange = new Date().getTimezoneOffset()*-60000;
          element.averagedStartDate = new Date(Date.parse(element.averagedStartDate)+timeZoneChange).toUTCString();
        }
        return [
          new Date(Date.parse(element.averagedStartDate)).getTime(),
          parseFloat(element.dataPoint.value),
        ];
      });
    });

    return processed;
  };

  const handleData = (data: any) => {
    if (data) {
      setOptions({
        ...options,
        series: options.series.map((serie) => {
          return {
            name: serie.name,
            data: data[serie.id] ? data[serie.id] : [],
            id: serie.id,
            metadata: serie.metadata,
          };
        }),
      });
    }
    if (currentSystem) setLoading(false);
  };

  useEffect(() => {
    setLoading(drawerState.animating);
  }, [drawerState.animating]);

  useEffect(() => {
    initTimeSeries();
    // action on update of movies
}, [timeFrame, averaging]);

  const handleAddUpload = (upload: Upload) => {
    if (
      !live.current ||
      systemsState.highlighted?.sid.toLowerCase() != upload.sid.toLowerCase()
    )
      return;

    const timestamp = new Date(
      Date.parse(upload.dateUploaded as any)
    ).getTime();

    latestChart.current.chart.series.forEach((serie) => {
      const point = upload.dataPoints.find(
        (u) =>
          (u.ssid + ":" + u.name).toLowerCase() ==
          serie.userOptions.id.toLowerCase()
      );

      if (point) {
        serie.addPoint([timestamp, parseFloat(point.value)], true, true);
      }
    });
  };

  const handleDevices = (devices: Device[]) => {
    setDevices(devices);
    setHighlightedDevices(devices);

    const filtered = devices.filter(
      (d) =>
        MeasurementType[d.measurementType] == MeasurementType.NUMERIC ||
        MeasurementType[d.measurementType] == MeasurementType.OTHER
    );

    if (filtered.length > 0) {
      setOptions({
        ...options,
        series: filtered.map<any>((device) => {
          return {
            name: `${device.name} (${device.ssid})`,
            id: device.uniqueName,
            metadata: {
              ssid: device.ssid,
            },
          };
        }),
      });
    }
  };

  const initTimeSeries = () => {
    let [from, ,] = timeSeries;
    let to = systemsState.highlighted
      ? new Date(Date.parse(systemsState.highlighted.lastUploaded))
      : new Date();

    if (timeFrame == "day")
    {
      from.setDate(to.getDate() - 1);
    }
    else if (timeFrame == "week")
    {
      from.setDate(to.getDate() - 7);
    }
    else if (timeFrame == "month")
    {
      from.setMonth(to.getMonth() - 30);
    }


    // if (Math.abs(to.getTime() - from.getTime()) < 1 * 24 * 60 * 60 * 1000)
    // {
    //   // 1 day
    //   from.setDate(to.getDate() - 1);
    // }

    // if (from.getTime() - to.getTime() > 0) 
    // {
    //   to = new Date();
    // }

    live.current = averaging == 0 ? true : false;

    setTimeSeries([from, to, averaging]);
  };

  useEffect(() => {
    if (timeSeries && timeSeries.length == 3) {
      const [from, to,] = timeSeries;

      toggleGraphLegend.current = true
      setHighlightedDevices(devices)
      retrieveData(from, to, averaging);

    }
  }, [timeSeries]);

  useEffect(() => {
    const system = systemsState.highlighted;

    if (system && system.id != currentSystem?.id) {
      setLoading(true);

      deviceActions
        .getCachedParentCollection(system)
        .then(handleDevices)
        .then(initTimeSeries)
        .catch(console.log);

      setCurrentSystem(system);
    }
  }, [systemsState.highlighted]);

  useEffect(() => {
    uploads.on(API.webSockets.commands.systemUpload, handleAddUpload);
  }, []);

  useEffect(() => {
    if (previousChart) {
      const newDevices: Device[] = [];
      latestChart.current.chart.series.forEach((serie) => {
        const device = devices.find((device) => device.uniqueName == serie.userOptions.id);

        if (device && serie.visible) newDevices.push(device);
      }); 

      setHighlightedDevices(newDevices);
      setPreviousChart(undefined);
    }
  }, [previousChart]);

  const MemoizedChart = useMemo(() => {
    return (
      <HighchartsReact
        ref={latestChart}
        containerProps={{ className: styles.graphContainer }}
        highcharts={Highcharts}
        options={options}
        // updateArgs={[true, true, true]}
      />
    );
  }, [latestChart.current, Highcharts, options]);

  return (
    <GridCard
      gridIndex={props.gridIndex}
      loading={loading}
      icon="./icons/antena.png"
      title="System Graph"
      utility={
        <DownloadGraph timeSeries={timeSeries} devices={highlightedDevices} />
      }
    >
      <div className={styles.graphTimeFrameSelector}>
        <Typography className={styles.graphTimeFrameTitle} variant="subtitle1">
          Time Frame
        </Typography>
        <Typography
          className={styles.graphTimeFrameOption}
          id={"day"}
          onClick={handleChangeTimeFrame}
          variant="body2"
        >
          Day
        </Typography>
        <Typography
          className={styles.graphTimeFrameOption}
          id={"week"}
          onClick={handleChangeTimeFrame}
          variant="body2"
        >
          Week
        </Typography>
        <Typography
          className={styles.graphTimeFrameOption}
          id={"month"}
          onClick={handleChangeTimeFrame}
          variant="body2"
        >
          Month
        </Typography>
        <Typography className={styles.graphTimeFrameTitle} variant="subtitle1">
          Average
        </Typography>
        <Typography
          className={styles.graphTimeFrameOption}
          id={"raw"}
          onClick={handleChangeAverage}
          variant="body2"
        >
          Raw
        </Typography>
        <Typography
          className={styles.graphTimeFrameOption}
          id={"1h"}
          onClick={handleChangeAverage}
          variant="body2"
        >
          1 Hour
        </Typography>
        <Typography
          className={styles.graphTimeFrameOption}
          id={"4h"}
          onClick={handleChangeAverage}
          variant="body2"
        >
          4 Hours
        </Typography>
        <Typography
          className={styles.graphTimeFrameOption}
          id={"8h"}
          onClick={handleChangeAverage}
          variant="body2"
        >
          8 Hours
        </Typography>
        <CustomTimeSeries
          timeSeries={timeSeries}
          handleChangeTimeSeries={setTimeSeries}
        />
      </div>
      {MemoizedChart}
    </GridCard>
  );
};

export default Graph;
