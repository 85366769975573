import { DeviceService } from "./DeviceService";
import { Device } from "./models/Device";
import { IModelActions, IModelState, ModelStore } from "../../utils/ModelStore";
import { Company } from "../company/models/Company";
import { DeviceCategory } from "./models/DeviceGroup";



export interface DevicesState extends IModelState<Device> {}

export interface DevicesActions extends IModelActions<Device> {
  getByCompanyByGroup(company: Company, group: DeviceCategory);
}

export class DevicesStore
  extends ModelStore<Device, DevicesState, DevicesActions>
  implements DevicesActions
{
  protected prototype = DevicesStore.prototype;
  protected initialState = {
    collection: [],
    parentCollection: [],
    highlighted: undefined,
    highlightedParent: undefined,
    lastUpdatedCollection: undefined,
    lastUpdatedHighlighted: undefined,
    lastUpdatedParentCollection: undefined,
    cachedCapacity: 30,
    api: DeviceService
  };

  constructor() {
    super();
  }

  public getByCompanyByGroup(company: Company, group: DeviceCategory)
  {
    return DeviceService.getByCompanyByGroup(company.id, group);
  }

}