import { FormModel } from "../../../utils/FormModel";

export class User extends FormModel {
    
    
    public id: string = "";
    public name: string = "";
    public email: string = "";
    public role: string = "User";
    public auth0Id: string = "";
    public companyId: string = "";

    public isAdmin() {
       return UserRole[this.role] == UserRole.PortalAdmin;
    }
}

export enum UserRole {
    User = "User",
    PortalAdmin = "PortalAdmin",
    CompanyAdmin = "CompanyAdmin"
}

export const UserRoleRender: Record<UserRole, string> = {
    "User": "User",
    "PortalAdmin": "Portal Admin",
    "CompanyAdmin": "Company Admin"
}