import { AxiosRequestConfig } from "axios";
import { API } from "../../api";
import { ApiClient } from "../../utils/ApiClient";
import { ChangeEvent } from "./models/Event";

export class EventService {

  public static getByParentId(id: string) {
    return this.getByCompanyId(id);
  }

  public static getAll() {
    const requestConfig: AxiosRequestConfig = {
      method: "GET",
      url: API.server.event.list
    }

    return ApiClient.request(requestConfig).then((response) => {
      const changeEvents: ChangeEvent[] = [];
      response.model.forEach((model) => 
        changeEvents.push(new ChangeEvent().copy(model))
      )
      return changeEvents;
    })
  }

  public static getByCompanyId(id: string) {
    const requestConfig: AxiosRequestConfig = {
      method: "GET",
      url: API.server.company.events.replace("%id%", id)
    }

    return ApiClient.request(requestConfig).then((response) => {
      const changeEvents: ChangeEvent[] = [];
      response.model.forEach((model) => 
        changeEvents.push(new ChangeEvent().copy(model))
      )
      return changeEvents;
    })
  }
}