import { Button } from "@mui/material";
import React, { FunctionComponent, useState } from "react";
import { AppStore } from "../../../../AppStore";
import { Form } from "../../../../components/form/FormElement";
import styles from "../styles/Components.css";
import { User, UserRole, UserRoleRender } from "../../../../services/user/models/User";
import { UserService } from "../../../../services/user/UserService";
import Loader from "react-spinners/BarLoader";

interface Props {
  onSubmit?: () => void;
}

export const AddUserForm: FunctionComponent<Props> = (props) => {
  const [state, actions] = AppStore.users.use();
  const [model] = useState(new User());
  const [loading, setLoading] = useState(false);

  const handleSubmit = (user) => {
    setLoading(true)

    return UserService.addOne(user)
      .then(actions.updateCollection)
      .then(props.onSubmit)
      .catch(console.log)
      .finally(() => setLoading(false))
  };

  return (
    <Form model={model} onSubmit={handleSubmit}>
      <Form.Item field="name">
        <Form.Input fullWidth placeholder="John Doe" label="Name" />
      </Form.Item>
      <Form.Item field="email">
        <Form.Input fullWidth placeholder="john@doe.com" label="Email" />
      </Form.Item>
      <Form.Item field="role">
        <Form.Dropdown
          options={Object.values(UserRole)}
          valueModifier={(value) => UserRoleRender[value]}
          defaultValue={UserRole.User}
          label="Role"
          placeholder="John Doe"
        />
      </Form.Item>
      <Form.Item field="submit">
        <div className={styles.submitButtonContainer}>
          {loading ?
          <Loader />
          :
          <Button variant="contained">Submit</Button>
        }
        </div>
      </Form.Item>
    </Form>
  );
};
