import { AxiosRequestConfig } from "axios";
import { API } from "../../api";
import { ApiClient } from "../../utils/ApiClient";
import { Device, DeviceQuery } from "../device/models/Device";
import { TimeSeriesUpload, Upload } from "./models/Upload";

export class UploadService {

  // static getPrimaryUploadTimeSeriesForDevices(
  //   devices: DeviceQuery[],
  //   from: Date,
  //   to: Date,
  //   average: number
  // ) {
  //   let fixedFrom = new Date(from.getUTCFullYear(), from.getUTCMonth(), from.getUTCDate(), from.getUTCHours(), from.getUTCMinutes(), from.getUTCSeconds());
  //   let fixedTo = new Date;
  //   if (to)
  //   {
  //     fixedTo = new Date(to.getUTCFullYear(), to.getUTCMonth(), from.getUTCDate(), from.getUTCHours(), from.getUTCMinutes(), from.getUTCSeconds());
  //   }
  //   const requestConfig: AxiosRequestConfig = {
  //     method: "GET",
  //     url:
  //       API.server.upload.primary.timeSeries,
  //     params: {
  //       devices: devices,
  //       start: fixedFrom, 
  //       end: fixedTo,
  //       average: average,
  //     },
  //   };

  //   return ApiClient.request(requestConfig).then((response) => {
  //     const uploads: TimeSeriesUpload[] = [];
  //     response.model.forEach((model) =>
  //       uploads.push(new TimeSeriesUpload().copy(model))
  //     );
  //     return uploads;
  //   });
  // }

  static getPrimaryUploadTimeSeriesForDeviceBySystem(
    sid: string,
    ssid: string,
    device: string,
    from: Date,
    to: Date,
    average: number
  ) {
    // let fixedFrom = new Date(from.getUTCFullYear(), from.getUTCMonth(), from.getUTCDate(), from.getUTCHours(), from.getUTCMinutes(), from.getUTCSeconds());
    // let fixedTo = new Date;
    // if (to)
    // {
    //   fixedTo = new Date(to.getUTCFullYear(), to.getUTCMonth(), from.getUTCDate(), from.getUTCHours(), from.getUTCMinutes(), from.getUTCSeconds());
    // }
    const requestConfig: AxiosRequestConfig = {
      method: "GET",
      url:
        API.server.upload.primary.timeSeriesBySID.replace("%sid%", sid) +"/" +ssid +"/" +device,
      params: {
        start: from, 
        end: to,
        average: average,
      },
    };

    return ApiClient.request(requestConfig).then((response) => {
      return response.model;
    });
  }

  // static getRecentPrimaryUpload(sid: string) {
  //   const requestConfig: AxiosRequestConfig = {
  //     method: "GET",
  //     url: API.server.upload.primary.recentBySID.replace("%sid%", sid),
  //     params: {
  //       sid: sid,
  //     },
  //   };

  //   return ApiClient.request(requestConfig).then((response) => {
  //     return new Upload().copy(response.model);
  //   });
  // }

  static getPrimaryUploadsForDevices(
    sid: string,
    devices: string[],
    average: number,
    from: Date,
    to?: Date
  ) {
    // console.log("we are actually asking for these times " + from + " " + to);
    const requestConfig: AxiosRequestConfig = {
      method: "GET",
      url: API.server.upload.primary.devicesBySID.replace("%sid%", sid),
      params: {
        devices: devices,
        start: from,
        end: to,
        average: average,
      },
    };

    return ApiClient.request(requestConfig).then((response) => {
      const uploads: TimeSeriesUpload[] = [];
      response.model.forEach((model) =>
        uploads.push(new TimeSeriesUpload().copy(model))
      );
      return uploads;
    });
  }

  static getPrimaryUploadTimeSeries(
    sid: string,
    from: Date,
    to: Date,
    average: number
  ) {
    // console.log('What I am asking for when doing graph update: ')
    // console.log(from)
    // console.log(to)

    const requestConfig: AxiosRequestConfig = {
      method: "GET",
      url: API.server.upload.primary.timeSeriesBySID.replace("%sid%", sid),
      params: {
        start: from,
        end: to,
        average: average,
      },
    };

    return ApiClient.request(requestConfig).then((response) => {
      
      return response.model;
    });
  }
}
