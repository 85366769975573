import { Button, Divider, Typography } from "@mui/material";
import React, { FunctionComponent } from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../App";
import { AppStore } from "../../../AppStore";
import { DefaultRoutes } from "../../../providers/RouterProvider";
import { UserRoleRender } from "../../../services/user/models/User";
import styles from "../styles/Account.css";

interface Props {}

const Body: FunctionComponent<Props> = (props) => {
  const history = useHistory();
  const [account, actions] = AppStore.account.use();

  const toDashboard = () => history.goBack();

  return (
    <div className={styles.profileContainer}>
      {account.user && (
        <div className={styles.profileBody}>
          <Typography variant="h5">Hello, {account.user.name}</Typography>
          <Divider></Divider>
          <Typography style={{ marginTop: "5px" }} variant="subtitle2">
            Email: {account.user.email}
          </Typography>
          <Typography style={{ marginTop: "5px" }} variant="subtitle2">
            Auth0 ID: {account.user.auth0Id}
          </Typography>
          <Typography style={{ marginTop: "5px" }} variant="subtitle2">
            Role: {UserRoleRender[account.user.role]}
          </Typography>
        </div>
      )}
      <div className={styles.profileFooter}>
        <Button variant="outlined" onClick={toDashboard}>
          Dashboard
        </Button>
      </div>
    </div>
  );
};

export default Body;
