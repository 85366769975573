import { Typography } from "@mui/material";
import React from "react";
import { Grid } from "../../../../components/gridManager/components/Grid";
import EventsList from "../components/EventsList";


const EventsGrid = new Grid();
EventsGrid.id = "Events"
EventsGrid.components = [
  <EventsList />,
];
EventsGrid.layout = [
  {
    x: 0,
    y: 0,
    w: 12,
    h: 24,
    i: "0",
    static: true,
  },
  // {
  //   x: 7,
  //   y: 0,
  //   w: 6,
  //   h: 24,
  //   i: "1",
  //   static: true,
  // }
];

export default EventsGrid;