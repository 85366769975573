import { Device } from "./Device";

interface IDeviceMeasurement<T> {
  process(device: Device | Device[]): T | undefined;
  format(device: Device | Device[]): string | undefined;
}

// export class LocationMeasurement {
//   static process(device: Device): [number, number] | undefined {
//     if (DeviceGroup[device.group] != DeviceGroup.Location) return;

//     const x = parseFloat(device.measurement.split(", ")[0]);
//     const y = parseFloat(device.measurement.split(", ")[1]);

//     return [x, y];
//   }

//   static format(device: Device): string | undefined {
//     if (DeviceGroup[device.group] != DeviceGroup.Location) return;

//     const x = device.measurement.split(", ")[0];
//     const y = device.measurement.split(", ")[1];

//     return `Lat: ${x} Lng: ${y}`;
//   }
// }

class GPSGroupMeasurement implements IDeviceMeasurement<[number, number]> {
  process(devices: Device[]): [number, number] | undefined {
    if (devices.length != 2) return;

    return [
      parseFloat(devices[0].measurement),
      parseFloat(devices[1].measurement),
    ];
  }

  format(devices: Device[]): string | undefined {
    if (devices.length != 2) return;

    return `Lat: ${parseFloat(devices[0].measurement)} Lng: ${parseFloat(
      devices[1].measurement
    )}`;
  }
}

class NumericMeasurement implements IDeviceMeasurement<number> {
  process(device: Device): number {
    const value = parseFloat(device.measurement);

    return value;
  }

  format(device: Device): string | undefined {
    return device.measurement;
  }
}

// class UnitLessMeasurement implements IDeviceMeasurement<[number, number] | undefined> {

//   process(device: Device): [number, number] | undefined {
//     return LocationMeasurement.process(device);
//   }

//   format(device: Device): string | undefined {
//     return LocationMeasurement.format(device);
//   }

// }

export enum MeasurementType {
  OTHER = 0,
  BOOLEAN = 1,
  STATUS_BYTE = 2,
  NUMERIC = 3,
  DATE = 4,
  TIME = 5
}

export enum Units {
  OTHER = 0,
  PPM = 1,
  PPB = 2,
  VOLTAGE = 3,
  BYTE = 4,
  PERCENTAGE = 5,
  CELCIUS = 6,
  FEET = 7,
  KOHMS = 8,
  HPA = 9,
  LAT = 10,
  MBAR = 11,
  DATE = 12,
  TIME = 13,
  DATETIME = 14,
  CCMIN = 15,
  UGM3 = 16,
  MS = 17,
  DEGREESTEMP = 18,
  FAHRENHEIT = 19,
  MMHG = 20,
  LON = 21
}

export const UnitSymbol: Record<Units, string> = {
  0: "OTHER",
  1: "PPM",
  2: "PPB",
  3: "V",
  4: "B",
  5: "%",
  6: "CELSIUS",
  7: "FT",
  8: "KOHM",
  9: "HPA",
  10: "",
  11: "MBAR",
  12: "DATE",
  13: "TIME",
  14: "DATE-TIME",
  15: "CC/MIN",
  16: "UG/M3",
  17: "M/S",
  18: "DEG",
  19: "F",
  20: "MMHG",
  21: ""
};

export const MeasurementExtractor: Record<Units, IDeviceMeasurement<any>> = {
  0: new NumericMeasurement(),
  1: new NumericMeasurement(),
  2: new NumericMeasurement(),
  3: new NumericMeasurement(),
  4: new NumericMeasurement(),
  5: new NumericMeasurement(),
  6: new NumericMeasurement(),
  7: new NumericMeasurement(),
  8: new NumericMeasurement(),
  9: new NumericMeasurement(),
  10: new NumericMeasurement(),
  11: new NumericMeasurement(),
  12: new NumericMeasurement(),
  13: new NumericMeasurement(),
  14: new NumericMeasurement(),
  15: new NumericMeasurement(),
  16: new NumericMeasurement(),
  17: new NumericMeasurement(),
  18: new NumericMeasurement(),
  19: new NumericMeasurement(),
  20: new NumericMeasurement(),
  21: new NumericMeasurement()
};
