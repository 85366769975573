import { AxiosRequestConfig } from "axios";
import { API } from "../../api";
import { ApiClient } from "../../utils/ApiClient";
import { Correlation } from "./Models/Correlation";

export class AnalysisService {

  public static getOne(id: string) {
    const requestConfig: AxiosRequestConfig = {
      method: "GET",
      url: API.server.correlation.single.replace("%id%", id),
    };

    return ApiClient.request(requestConfig).then((response) => {
      return new Correlation().copy(response.model);
    });
  }

  public static getAll() {
    const requestConfig: AxiosRequestConfig = {
      method: "GET",
      url: API.server.correlation.list,
    };

    return ApiClient.request(requestConfig).then((response) => {
      const correlations: Correlation[] = [];
      response.model.forEach((model) => correlations.push(new Correlation().copy(model)));
      return correlations;
    });
  }

  public static getByCompanyId(id: string) {
    const requestConfig: AxiosRequestConfig = {
      method: "GET",
      url: API.server.company.correlations.replace("%id%", id),
    };

    return ApiClient.request(requestConfig).then((response) => {
      const correlations: Correlation[] = [];
      response.model.forEach((model) => correlations.push(new Correlation().copy(model)));
      return correlations;
    });
  }

  public static getByParentId(id: string) {
    return this.getByCompanyId(id);
  }

  static addOne(manifest: any) {
    const requestConfig: AxiosRequestConfig = {
      method: "PUT",
      url: API.server.correlation.list,
      data: manifest,
    };

    return ApiClient.request(requestConfig);
  }

  static deleteOne(correlation: Correlation) {
    console.log(correlation)
    const requestConfig: AxiosRequestConfig = {
      method: "DELETE",
      url: API.server.correlation.list,
      data: { CorrelationId: correlation.id },
    };

    return ApiClient.request(requestConfig);
  }
}
