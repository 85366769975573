import React, { FunctionComponent, useEffect, useState } from "react";
import { GridCard } from "../../../../components/gridManager/components/GridCard";
import Pdf from "react-to-pdf";
import { Button, MenuItem, Select, Typography } from "@mui/material";
import styles from "../styles/Components.css";
import { AppStore } from "../../../../AppStore";
import { CustomTimeSeries } from "../../../shared/components/GraphTimeSeries";
import Popup from "../../../../components/popup/Popup";
import { CreateGraphForm } from "./CreateGraphForm";
import { AnalysisService } from "../../../../services/analysis/AnalysisService";
import { Correlation } from "../../../../services/analysis/Models/Correlation";

interface Props {}

export const GraphNavbar: FunctionComponent<Props> = (props) => {
  const [analysisState, analysisActions] = AppStore.analysis.use();
  const [companyState, companyActions] = AppStore.companies.use();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [graphs, setGraphs] = useState<Correlation[]>([]);
  const [selectedGraphId, setSelectedGraphId] = useState();
  const [loadingGraph, setLoadingGraph] = useState(false);

  const handleOpenCreateModal = () => {
    setShowCreateModal(true);
  };

  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
  };

  const handleOpenDeleteGraphModal = () => {
    if (analysisState.highlighted)
      setShowDeleteModal(true);
  };

  const handleCloseDeleteGraphModal = () => {
    setShowDeleteModal(false);
  };

  const handleSelectedGraph = (event) => {
    setSelectedGraphId(event.target.value);
    analysisActions.setHighlighted(
      graphs.find((g) => g.id == event.target.value)
    );
  };

  const handleDeleteGraph = () => {
    analysisActions
      .deleteHighlighted()
      .then(() =>
        analysisActions.updateParentCollection(companyState.highlighted)
      ).finally(() => handleCloseDeleteGraphModal())
  };

  const handleLoadGraph = () => {
    if (loadingGraph || !selectedGraphId) return;

    // setLoadingGraph(true)

    analysisActions.setCurrentGraph(
      graphs.find((g) => g.id == selectedGraphId)
    );
  };

  const handleChangeTimeFrame = (e: any) => {
    const timeframe = e.target.id;
    const [, , average] = analysisState.timeSeries;

    const to = new Date();
    let from = new Date();

    switch (timeframe) {
      case "month":
        from.setDate(to.getDate() - 30);
        break;
      case "week":
        from.setDate(to.getDate() - 7);
        break;
      default:
        from.setDate(to.getDate() - 1);
    }

    analysisActions.setTimeSeries([from, to, average]);
  };

  const handleChangeAverage = (e: any) => {
    const select = e.target.id;
    const [from, to] = analysisState.timeSeries;

    let [, , average] = analysisState.timeSeries;

    switch (select) {
      case "1h":
        average = 60;
        break;
      case "4h":
        average = 4 * 60;
        break;
      case "8h":
        average = 8 * 60;
        break;
      default:
        average = 0;
    }

    analysisActions.setTimeSeries([from, to, average]);
  };

  const handleGraphs = (graphs: Correlation[]) => {
    const newGraphs: Correlation[] = [];

    graphs.forEach((graph) => {
      newGraphs.push(graph);
    });

    setGraphs(newGraphs);
  };

  const fetchGraphs = (company) => {
    AnalysisService.getByCompanyId(company.id)
      .then(handleGraphs)
      .catch(console.log);
  };

  useEffect(() => {
    if (companyState.highlighted) {
      fetchGraphs(companyState.highlighted);
    }
  }, [companyState.highlighted, analysisState.parentCollection]);

  return (
    <GridCard>
      <div className={styles.graphUtilityBar}>
        <div className={styles.graphLoader}>
          <Select
            onChange={handleSelectedGraph}
            className={styles.loadGraphSelect}
            size="small"
            defaultValue={analysisState.highlighted?.id}
          >
            {graphs.map((g) => (
              <MenuItem className={styles.graphNavBarItem} value={g.id}>
                {g.name}
              </MenuItem>
            ))}
          </Select>
          <Button
            className={styles.loadGraphButton}
            variant="contained"
            size="small"
            onClick={handleLoadGraph}
          >
            Load
          </Button>
          <Button
            className={styles.loadGraphButton}
            variant="contained"
            size="small"
            onClick={handleOpenCreateModal}
          >
            save
          </Button>
          <Button
            className={styles.loadGraphButton}
            variant="contained"
            size="small"
            onClick={handleOpenDeleteGraphModal}
            color="error"
          >
            delete
          </Button>
        </div>
        <div className={styles.graphTimeFrameSelector}>
          <Typography
            className={styles.graphTimeFrameTitle}
            variant="subtitle1"
          >
            Time Frame
          </Typography>
          <Typography
            className={styles.graphTimeFrameOption}
            id={"day"}
            onClick={handleChangeTimeFrame}
            variant="body2"
          >
            D
          </Typography>
          <Typography
            className={styles.graphTimeFrameOption}
            id={"week"}
            onClick={handleChangeTimeFrame}
            variant="body2"
          >
            W
          </Typography>
          <Typography
            className={styles.graphTimeFrameOption}
            id={"month"}
            onClick={handleChangeTimeFrame}
            variant="body2"
          >
            M
          </Typography>
          <Typography
            className={styles.graphTimeFrameTitle}
            variant="subtitle1"
          >
            Average
          </Typography>
          <Typography
            className={styles.graphTimeFrameOption}
            id={"raw"}
            onClick={handleChangeAverage}
            variant="body2"
          >
            Raw
          </Typography>
          <Typography
            className={styles.graphTimeFrameOption}
            id={"1h"}
            onClick={handleChangeAverage}
            variant="body2"
          >
            1H
          </Typography>
          <Typography
            className={styles.graphTimeFrameOption}
            id={"4h"}
            onClick={handleChangeAverage}
            variant="body2"
          >
            4H
          </Typography>
          <Typography
            className={styles.graphTimeFrameOption}
            id={"8h"}
            onClick={handleChangeAverage}
            variant="body2"
          >
            8H
          </Typography>
          <CustomTimeSeries
            timeSeries={analysisState.timeSeries}
            handleChangeTimeSeries={analysisActions.setTimeSeries}
          />
        </div>
      </div>

      <Popup
        visible={showCreateModal}
        onDismiss={handleCloseCreateModal}
        title={`Create Graph`}
      >
        <CreateGraphForm onSubmit={handleCloseCreateModal} />
      </Popup>
      <Popup
        title={`Delete ${analysisState.highlighted?.name}?`}
        visible={showDeleteModal}
        onDismiss={handleCloseDeleteGraphModal}
      >
        <br />
        <div className={styles.submitButtonContainer}>
          <Button variant="outlined" color="error" onClick={handleDeleteGraph}>
            confirm
          </Button>
        </div>
      </Popup>
    </GridCard>
  );
};
