
export class GridLayoutItem {
    x: number
    y: number
    w: number
    h: number
    minH?: number
    maxH?: number
    i: string
    static: boolean
}

export class GridCardExpand {

    index: number = -1
    scale: number = 1;

    constructor(index: number | undefined, scale: number){
        this.index = index ? index : -1;
        this.scale = scale;
    }
}

export class Grid {

    id: string;
    components: JSX.Element[] = [];
    layout: GridLayoutItem[] = [];

    public AddComponent(component: JSX.Element)
    {
        this.components.push(component);
    }

    public AddLayoutItem(item: GridLayoutItem)
    {
        this.layout.push(item);
    }

    public UpdateLayout(layout: any)
    {
        layout = layout;
    }

    public ComponentCount(): number
    {
        return this.components.length;
    }
}
