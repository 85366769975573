import { Typography } from "@mui/material";
import React, { FunctionComponent } from "react";
import { Routes } from "../../App";
import NavigationBar from "../../components/navigationbar/NavigationBar";

interface Props {}

const ClientNavigationBar: FunctionComponent<Props> = () => {


    const helpMenu = () => {
        return <Typography>Please contact techsupport@2btech.io for support</Typography>
    }

    return (
        <NavigationBar sections={[Routes.System, Routes.Company, Routes.Map, Routes.Analysis]} helpMenu={helpMenu()} />
    )
}

export default ClientNavigationBar;