import { Button } from "@mui/material";
import React, { Fragment, FunctionComponent, useEffect, useRef, useState } from "react";
import { AppStore } from "../../../../AppStore";
import { DrawerItem } from "../../../../components/drawer/components/DrawerItem";
import { DeleteUtility } from "../../../../components/gridManager/components/DeleteUtility";
import Popup from "../../../../components/popup/Popup";
import { System } from "../../../../services/system/models/System";
import { AddSystemForm } from "../components/AddSystemForm";
import SystemsGrid from "./Grid";
import styles from "../styles/Components.css";
import { ToggleClientViewUtility } from "../../components/ToggleClientViewUtility";
import { DrawerSection } from "../../../../components/drawer/components/DrawerSection";
import { SmallStatusIndicator } from "../../../shared/components/SystemStatus";

interface Props {}

const Body: FunctionComponent<Props> = (props) => {
  const [, companyActions] = AppStore.companies.use();
  const [, gridActions] = AppStore.grid.use();
  const [, drawerActions] = AppStore.drawer.use();
  const [state, actions] = AppStore.systems.use();
  const systemName = useRef();

  const handleSelected = (system: any) => {
    actions.setHighlighted(system);
    systemName.current = system.sid
  };

  // const handleDeleteSystem = () => {
  //   actions
  //     .deleteHighlighted()
  //     .then(actions.updateCollection)
  //     .catch(console.log);
  // };

  const updateDrawer = () => {
    companyActions.getCachedCollection().then((companies) => {
      actions.getCachedCollection().then((systems) => {
        const sections: DrawerSection[] = [];
        let highlighted: DrawerItem | undefined;
        const allSection = new DrawerSection();
        const allItems: DrawerItem[] = [];
        
        systems.forEach(system => {
          const utilities: JSX.Element[] = [];
          const item = new DrawerItem();

          utilities.push(<SmallStatusIndicator system={system} />);
          item.primary = system.sid;
          item.secondary = system.health;
          item.model = system;
          item.children = utilities;
          item.onClick = handleSelected;

          allItems.push(item)
        })

        allSection.index = 0;
        allSection.name = "All"
        allSection.items = allItems;
        
        sections.push(allSection)

        companies.forEach((company, index) => {
          const section = new DrawerSection();
          const items: DrawerItem[] = [];

          section.index = index + 1;
          section.name = company.name;

          const filtered = systems.filter((s) =>
            s.companyIds.includes(company.id)
          );

          filtered.forEach((system) => {
            const utilities: JSX.Element[] = [];

            utilities.push(<SmallStatusIndicator system={system} />);

            const drawerItem = new DrawerItem();

            drawerItem.primary = system.sid;
            drawerItem.secondary = system.health;
            drawerItem.model = system;
            drawerItem.children = utilities;
            drawerItem.onClick = handleSelected;

            items.push(drawerItem);

            if (state.highlighted && system.id == state.highlighted.id)
              highlighted = drawerItem;

            if (!highlighted && !state.highlighted) {
              highlighted = drawerItem;
            }
          });

          section.items = items;

          sections.push(section);
        });

        if (highlighted) actions.setHighlighted(highlighted.model as System);
        
        drawerActions.setSections(sections);
        drawerActions.setHighlighted(highlighted);
      });
    });
  };

  useEffect(() => {
    gridActions.clear();
    gridActions.initGrid(SystemsGrid);
    gridActions.setUtilities([
      <ToggleClientViewUtility />,
      // <DeleteUtility
      //   requireConfirm
      //   onDelete={handleDeleteSystem}
      //   title={`Are you sure you want to delete this system?`}
      // />,
    ]);
    // drawerActions.setAddModelFormTitle("System")
    drawerActions.setTitle("system");
    drawerActions.setAddModelForm(undefined);

  }, []);

  useEffect(() => {
    updateDrawer();
  }, [state.collection]);

  useEffect(() => {
    if (state.highlighted) {
      companyActions.getCachedCollection().then((collection) => {
        const company = collection.find((company) =>
          state.highlighted?.companyIds.includes(company.id)
        );
        gridActions.setSecondaryTitle(company?.name);
      });
      gridActions.setPrimaryTitle(state.highlighted.sid);
      gridActions.setLoading(false);
    }
  }, [state.highlighted]);

  return <Fragment></Fragment>;
};

export default Body;
