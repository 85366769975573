import React, { FunctionComponent } from "react";
import { ApiProvider } from "./ApiProvider";
import { AuthenticationProvider } from "./AuthenticationProvider";
import { Notifications } from "./Notifications";
import { RouterProvider } from "./RouterProvider";
import { ThemeProvider } from "./ThemeProvider";
import { WebSocketProvider } from "./WebSocketProvider";

interface Props {
  defaultInsecureRoute: string;
  defaultAdminRoute: string;
  defaultSecureRoute: string;
}

const ProviderStack: FunctionComponent<Props> = (props) => {
  return (
    <AuthenticationProvider>
      <WebSocketProvider>
        <ApiProvider>
          <Notifications>
            <ThemeProvider>
              <RouterProvider {...props}>{props.children}</RouterProvider>
            </ThemeProvider>
          </Notifications>
        </ApiProvider>
      </WebSocketProvider>
    </AuthenticationProvider>
  );
};

export { ProviderStack };
