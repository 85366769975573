import React, { Fragment, FunctionComponent, useEffect } from "react";
import "./App.css";
import Profile from "./areas/account/layouts";
import AdminNavigationBar from "./areas/admin/components/AdminNavigationBar";
import CompaniesDashboard from "./areas/admin/companies/layouts";
import EventsDashboard from "./areas/admin/events/layouts";
import AdminSystemDashboard from "./areas/admin/system/layouts";
import SystemsDashboard from "./areas/admin/systems/layouts";
import UsersDashboard from "./areas/admin/users/layouts";
import AnalysisDashboard from "./areas/client/analysis/layouts";
import ClientNavigationBar from "./areas/client/ClientNavigationBar";
import CompanyDashboard from "./areas/client/company/layouts";
import MapDashboard from "./areas/client/map/layouts";
import SystemDashboard from "./areas/client/system/layouts";
import LandingPage from "./areas/landing/layouts";
import Drawer from "./components/drawer/Drawer";
import GridManager from "./components/gridManager/GridManager";
import { ENV } from "./env";
import { ProviderStack } from "./providers/ProviderStack";
import {
  AdminRoute,
  AuthenticatedRoute,
  RoutedSegment,
  UnauthenticatedRoute,
} from "./providers/RouterProvider";
import { UserRole } from "./services/user/models/User";
import { useHealthCheck } from "./utils/Hooks";

export enum Routes {
  System = "/dashboard/system",
  Companies = "/admin/companies",
  Company = "/dashboard/company",
  Landing = "/landing",
  Profile = "/profile",
  Systems = "/admin/systems",
  AdminSystem = "/admin/system",
  Analysis = "/dashboard/analysis",
  Users = "/admin/users",
  Events = "/admin/events",
  Admin = "/admin",
  Login = "/login",
  Map = "/dashboard/map",
  AdminAnalysis = "/admin/analysis"
}

export const RouteRender: Record<Routes, string> = {
  "/dashboard/system": "System",
  "/admin/companies": "Companies",
  "/dashboard/company": "Company",
  "/dashboard/analysis": "Analysis",
  "/landing": "Landing",
  "/profile": "Profile",
  "/admin/systems": "Systems",
  "/admin/system": "System",
  "/admin/users": "Users",
  "/admin/events": "Events",
  "/admin": "Admin",
  "/login": "Login",
  "/dashboard/map": "Map",
  "/admin/analysis": "Analysis"
};

const App: FunctionComponent = (props) => {
  const onSuccessfulHealthCheck = () => {};
  useHealthCheck(onSuccessfulHealthCheck);

  useEffect(() => {
    document.title = ENV.title;
  }, []);

  
  // Render all admin routes when roles reflect admin status
  const AdminRoutes = () => {
    return (
      <Fragment>
        <RoutedSegment
          globalComponents={[AdminNavigationBar, Drawer, GridManager]}
          roles={[UserRole.PortalAdmin]}
          path="/admin"
        >
          <AdminRoute path={Routes.Companies} component={CompaniesDashboard} />
          <AdminRoute path={Routes.Users} component={UsersDashboard} />
          <AdminRoute path={Routes.Systems} component={SystemsDashboard} />
          <AdminRoute path={Routes.Events} component={EventsDashboard} />
          <AdminRoute path={Routes.AdminAnalysis} component={AnalysisDashboard} />

          <AdminRoute path={Routes.AdminSystem} component={AdminSystemDashboard} />
        </RoutedSegment>
        <RoutedSegment
          roles={[UserRole.PortalAdmin]}
        >
          <AuthenticatedRoute path={Routes.Profile} component={Profile} />
        </RoutedSegment>
      </Fragment>
    );
  };

  // Render all authenticated routes when roles reflect company admin or user status
  const AuthenticatedRoutes = () => {
    return (
      <Fragment>
        <RoutedSegment
          globalComponents={[ClientNavigationBar, Drawer, GridManager]}
          roles={[UserRole.CompanyAdmin, UserRole.User]}
          path="/dashboard"
        >
          <AuthenticatedRoute
            path={Routes.System}
            component={SystemDashboard}
          />
          <AuthenticatedRoute
            path={Routes.Company}
            component={CompanyDashboard}
          />
          <AuthenticatedRoute path={Routes.Map} component={MapDashboard} />
          <AuthenticatedRoute
            path={Routes.Analysis}
            component={AnalysisDashboard}
          />
        </RoutedSegment>
        <RoutedSegment
          roles={[UserRole.CompanyAdmin, UserRole.User, UserRole.PortalAdmin]}
        >
          <AuthenticatedRoute path={Routes.Profile} component={Profile} />
        </RoutedSegment>
      </Fragment>
    );
  };

  const UnauthenticatedRoutes = () => {
    return (
      <RoutedSegment>
        <UnauthenticatedRoute path={Routes.Landing} component={LandingPage} />
      </RoutedSegment>
    );
  };

  return (
    <ProviderStack
      defaultSecureRoute={Routes.Map}
      defaultAdminRoute={Routes.Companies}
      defaultInsecureRoute={Routes.Landing}
    >
      <AdminRoutes />
      <AuthenticatedRoutes />
      <UnauthenticatedRoutes />
    </ProviderStack>
  );
};

export { App };
