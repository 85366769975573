import React from "react";
import { Grid } from "../../../../components/gridManager/components/Grid";
import AlertsList from "./AlertsList";
import DeviceDetailGraph from "./Graph";


const DeviceDetailAnalysisGrid = new Grid();
DeviceDetailAnalysisGrid.components = [
 <DeviceDetailGraph />,
 <AlertsList />
];
DeviceDetailAnalysisGrid.layout = [
  {
    x: 0,
    y: 0,
    w: 12,
    h: 13,
    i: "0",
    static: true,
  },
  {
    x: 0,
    y: 13,
    w: 12,
    h: 13,
    i: "1",
    static: true,
  }
];

export default DeviceDetailAnalysisGrid;