import { Button, TextField, Typography } from "@mui/material";
import React, { FunctionComponent, useEffect, useState } from "react";
import { AppStore } from "../../../AppStore";
import Popup from "../../popup/Popup";
import styles from "../styles/Components.css";

interface Props {
  primary: string;
  secondary?: string;
  utilities?: JSX.Element[] | undefined;
}

const GridHeader: FunctionComponent<Props> = (props) => {
  const [state, actions] = AppStore.grid.use();
  const [showEdit, setShowEdit] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [edit, setEdit] = useState(props.primary);

  const handlePrelimHeaderEdit = () => {
    if (state.onEditPrimaryTitle) setShowEdit(true);
  };

  const handleCancelPrelimHeaderEdit = () => {
    setShowEdit(false);
  };

  const handleOpenEditModal = () => {
    if (state.onEditPrimaryTitle) setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };

  const handleEdit = (event) => {
    setEdit(event.target.value);
  };

  const handleSubmit = () => {
    state.onEditPrimaryTitle?.(edit);
    handleCloseEditModal();
  };

  useEffect(() => {
    if (props.primary) setEdit(props.primary);
  }, [props.primary]);

  return (
    <div className={styles.gridHeader}>
      <div>
        <div
          onMouseOver={handlePrelimHeaderEdit}
          onMouseOut={handleCancelPrelimHeaderEdit}
          className={styles.primaryEditContainer}
          onClick={handleOpenEditModal}
        >
          <Typography
            color="white"
            variant="h4"
            className={styles.primaryTitle}
          >
            {props.primary}
          </Typography>
          {showEdit && (
            <img
              style={{ filter: "invert(100%)", marginLeft: "10px" }}
              src="./icons/edit.png"
              width={25}
              height={25}
            />
          )}
        </div>

        <Typography color="white" variant="subtitle1">
          {props.secondary}
        </Typography>
      </div>
      <Popup
        title="Change Nick Name"
        onDismiss={handleCloseEditModal}
        visible={showEditModal}
      >
        <div className={styles.editPopupContainer}>
          <TextField onChange={handleEdit} value={edit} />
          <div className={styles.formSubmitButtonContainer}>
            <Button variant="contained" onClick={handleSubmit}>
              submit
            </Button>
          </div>
        </div>
      </Popup>
      <div className={styles.utilityContainer}>
        {props.utilities?.map((utility, index) =>
        <div className={styles.utilityItem} key={index}>
            {React.cloneElement(utility)}
        </div>
        )}
      </div>
    </div>
  );
};

export default GridHeader;
