import { Button, Checkbox, FormControlLabel } from "@mui/material";
import React, { FunctionComponent, useEffect, useState } from "react";
import Loader from "react-spinners/BarLoader";
import { AppStore } from "../../../../AppStore";
import { Form } from "../../../../components/form/FormElement";
import { FormatDateToString } from "../../../../helpers/time";
import { AnalysisService } from "../../../../services/analysis/AnalysisService";
import { Correlation } from "../../../../services/analysis/Models/Correlation";
import { CompanyService } from "../../../../services/company/CompanyService";
import { Company } from "../../../../services/company/models/Company";
import styles from "../styles/Components.css";

interface Props {
  onSubmit?: () => void;
}

export const CreateGraphForm: FunctionComponent<Props> = (props) => {
  const [companyState, companyActions] = AppStore.companies.use();
  const [state, actions] = AppStore.analysis.use();
  const [model] = useState(new Correlation());
  const [loading, setLoading] = useState(false);
  const [disableEnd, setDisableEnd] = useState(false);

  const handleToggleDisableEnd = (event) => {
    setDisableEnd(event.target.checked);
  };

  const handleSubmit = (correlation) => {
    if (!companyState.highlighted || state.graphedDevices.length < 1)
      return new Promise((resolve, reject) => {});

    const components: string[] = [];

    state.graphedDevices.forEach((d) => components.push(d.id));

    const company = companyState.highlighted;
    correlation.companyId = company.id;
    correlation.components = components;

    setLoading(true);

    return AnalysisService.addOne(correlation)
      .then(() => actions.updateParentCollection(company))
      .then(props.onSubmit)
      .catch(console.log)
      .finally(() => setLoading(false));
  };

  return (
    <Form model={model} onSubmit={handleSubmit}>
      <Form.Item field="name">
        <Form.Input fullWidth placeholder="My Awesome Graph" label="Name" />
      </Form.Item>
      <Form.Item field="start">
        <Form.DateSelect
          defaultValue={FormatDateToString(state.timeSeries[0])}
          label="Start"
        />
      </Form.Item>
      <Form.Item field="toPresent">
        <FormControlLabel
          label="To Present?"
          control={<Checkbox onChange={handleToggleDisableEnd} />}
        />
      </Form.Item>
      <Form.Item field="end">
        <Form.DateSelect
          disabled={disableEnd}
          defaultValue={FormatDateToString(state.timeSeries[1])}
          label="End"
        />
      </Form.Item>
      <Form.Item field="average">
        <Form.Input
          fullWidth
          label="Average (seconds)"
          defaultValue={`${state.timeSeries[2]}`}
          placeholder="0"
        />
      </Form.Item>
      <Form.Item field="submit">
        <div className={styles.submitButtonContainer}>
          {loading ? <Loader /> : <Button variant="contained">Submit</Button>}
        </div>
      </Form.Item>
    </Form>
  );
};
