
import {
  IModelActions,
  IModelState,
  ModelStore
} from "../../utils/ModelStore";
import { StatusRecord } from "./models/StatusRecord";
import { StatusRecordService } from "./StatusRecordService";

export interface StatusRecordsState extends IModelState<StatusRecord> {}

export interface StatusRecordsActions extends IModelActions<StatusRecord> {
}

export class StatusRecordsStore
  extends ModelStore<StatusRecord, StatusRecordsState, StatusRecordsActions>
  implements StatusRecordsActions
{
  protected prototype = StatusRecordsStore.prototype;
  protected initialState = {
    collection: [],
    parentCollection: [],
    highlighted: undefined,
    highlightedParent: undefined,
    lastUpdatedCollection: undefined,
    lastUpdatedHighlighted: undefined,
    lastUpdatedParentCollection: undefined,
    cachedCapacity: 10,
    api: StatusRecordService
  };

  constructor() {
    super();
  }
}