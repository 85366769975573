import { Button } from "@mui/material";
import React, { Fragment, FunctionComponent, useState } from "react";
import { AppStore } from "../../../../AppStore";
import { GridCardUtilityIcon } from "../../../../components/gridManager/components/GridCard";
import List from "../../../../components/list/List";
import Popup from "../../../../components/popup/Popup";
import { ObjectId } from "../../../../helpers/objectid";
import { User } from "../../../../services/user/models/User";
import { UserService } from "../../../../services/user/UserService";
import styles from "../styles/Components.css";

interface Props {}

export const AddUserToCompanyForm: FunctionComponent<Props> = (props) => {
  const [state, actions] = AppStore.users.use();
  const [companyState] = AppStore.companies.use();
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState<any>()
  const [users, setUsers] = useState<(User | undefined)[]>([]);

  const handleCloseForm = () => {
    setShow(false);
  };

  const handleAddUser = () => {
    if (selected && companyState.highlighted)
      UserService
        .addToCompany(selected, companyState.highlighted.id)
        .then(handleCloseForm)
        .then(() => actions.updateParentCollection(companyState.highlighted))
        .then(() => actions.updateCollection())
        .catch(console.log)
  }

  const filterUsers = (users: User[]) => {
    
    return users.map((u) => {
      if (ObjectId.isDefault(u.companyId)) return u;
    });
  }; 

  const handleOpenForm = () => {
    setShow(true);

    actions
      .getCachedCollection()
      .then(filterUsers)
      .then(setUsers)
      .catch(console.log);
  };

  return (
    <Fragment>
      <Popup visible={show} onDismiss={handleCloseForm} title={"Add User"}>
        <List
          items={users}
          title="users"
          primaryField="name"
          secondaryField="email"
          onSelect={setSelected}
        />
        <div className={styles.addUserButtonContainer}>
          <Button onClick={handleAddUser} variant="contained">Add</Button>
        </div>
      </Popup>
      <GridCardUtilityIcon
        src="./icons/plus.png"
        width={20}
        height={20}
        onClick={handleOpenForm}
      />
    </Fragment>
  );
};
