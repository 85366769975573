import React, { FunctionComponent, useEffect, useState } from "react";
import { AppStore } from "../../../../AppStore";
import { GridCard } from "../../../../components/gridManager/components/GridCard";
import { IGridComponent } from "../../../../components/gridManager/GridManager";
import { SystemService } from "../../../../services/system/SystemService";
import styles from "../styles/Components.css";

interface Props extends IGridComponent {}

const ManifestWindow: FunctionComponent<Props> = (props) => {
  const [state, actions] = AppStore.diagnostics.use();
  const [systems, systemsActions] = AppStore.systems.use();
  const [manifest, setManifest] = useState<any>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    if (systems.highlighted)
      SystemService.getManifestById(systems.highlighted.id)
        // .then(handleManifest)
        .then((wrapper) => setManifest(JSON.parse(wrapper.manifest)))
        .then(() => setLoading(false))
        .catch(console.log);
  }, [systems.highlighted]);

  return (
    <GridCard
      gridIndex={props.gridIndex}
      expandable
      loading={loading}
      icon="./icons/manifest.png"
      title="Manifest"
    >
      <pre className={styles.diagnosticWindow}>
        {JSON.stringify(manifest, null, 2)}
      </pre>
    </GridCard>
  );
};

export default ManifestWindow;
