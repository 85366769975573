import {
  ListItem,
  ListItemIcon,
  ListItemText
} from "@mui/material";
import React, { FunctionComponent, useEffect, useState } from "react";
import { AppStore } from "../../../AppStore";
import { FormModel } from "../../../utils/FormModel";
import styles from "../styles/Drawer.css";

export interface DrawerItemProps {
  primary: string;
  secondary: string;
  model: FormModel;
  highlighted?: boolean;
  image?: string;
  children?: JSX.Element[];
  onClick: (model: FormModel) => void;
}

export class DrawerItem implements DrawerItemProps {
  primary: string;
  secondary: string;
  model: FormModel;
  highlighted?: boolean;
  image?: string | undefined;
  children?: JSX.Element[] | undefined;
  onClick: (model: FormModel) => void;
}

export const DrawerItemComponent: FunctionComponent<DrawerItemProps> = (
  props
) => {
  const [state, actions] = AppStore.drawer.use();
  const [highlighted, setHighlighted] = useState(false);

  const handleClick = () => {
    props.onClick(props.model);
  };

  useEffect(() => {
    if (!state.highlighted) return;

    if (state.highlighted.model.id == props.model.id) setHighlighted(true);
    else setHighlighted(false);
    
  }, [state.highlighted]);

  return (
    <ListItem
      button
      onClick={handleClick}
      className={`${styles.drawerItem} ${
        highlighted ? styles.highlightedDrawerItem : ""
      }
          `}
    >
      {props.image && (
        <ListItemIcon>
          <img src={props.image} height={35} width={25} />
        </ListItemIcon>
      )}
      <ListItemText primary={props.primary} secondary={props.secondary} />
      {props.children}
    </ListItem>
  );
};
