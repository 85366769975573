import { Chip, Typography } from "@mui/material";
import L, { LatLng, LatLngBounds, Map } from "leaflet";
import React, {
  createRef,
  FunctionComponent,
  useEffect,
  useState,
} from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../../App";
import { AppStore } from "../../../../AppStore";
import { GridCard } from "../../../../components/gridManager/components/GridCard";
import { Company } from "../../../../services/company/models/Company";
import {
  AssembledSystem,
  System,
  SystemType,
} from "../../../../services/system/models/System";
import { SystemService } from "../../../../services/system/SystemService";
import styles from "../styles/Components.css";

interface Props {}

interface PlotProps {
  plot: PlotObject;
}

class PlotObject {
  public coordinate: LatLng;
  public system: AssembledSystem;
}

const UnitedStatesCenter = new LatLng(37.0902, -95.7129);

const MapElement: FunctionComponent<Props> = (props) => {
  const [state, actions] = AppStore.systems.use();
  const [deviceState, deviceActions] = AppStore.devices.use();
  const [drawerState] = AppStore.drawer.use();
  const [companyState] = AppStore.companies.use();
  const [loading, setLoading] = useState(false);
  const [map, setMap] = useState<Map>();
  const [bounds, setBounds] = useState<LatLngBounds | undefined>();
  const [plots, setPlots] = useState<PlotObject[]>([]);
  const [centered, setCentered] = useState(false);

  const calculateMapCenter = () => {
    if (plots.length > 0 && plots[0]) {
      let [maxX, minX] = [plots[0].coordinate.lat, plots[0].coordinate.lat];
      let [maxY, minY] = [plots[0].coordinate.lng, plots[0].coordinate.lng];

      plots.forEach((plot) => {
        if (plot.coordinate.lat > maxX) maxX = plot.coordinate.lat;

        if (plot.coordinate.lat < minX) minX = plot.coordinate.lat;

        if (plot.coordinate.lng > maxY) maxY = plot.coordinate.lng;

        if (plot.coordinate.lng < minY) minY = plot.coordinate.lng;
      });

      const bounds: LatLngBounds = new LatLngBounds(
        new LatLng(minX, minY),
        new LatLng(maxX, maxY)
      );

      setBounds(bounds);
    }
  };

  const plotSystems = (systems: AssembledSystem[]) => {
    const plots: PlotObject[] = [];

    systems.forEach((system) => {
      if (SystemType[system.type] != SystemType.GPS) return;

      const lat = system.devices.find((d) => d.name.toLowerCase() == "lat");
      const lng = system.devices.find((d) => d.name.toLowerCase() == "lon");

      if (lat && lng) {
        const x = parseFloat(lat.measurement);
        const y = parseFloat(lng.measurement);

        if (x && y) {
          const plot = new PlotObject();

          plot.coordinate = new LatLng(x, y);
          plot.system = system;

          plots.push(plot);
        }
      }
    });

    setPlots(plots);
  };

  const handleSystems = (company: Company) => {
    SystemService.getAssembledSubSystemsByCompany(company.id, SystemType.GPS)
      .then(plotSystems)
      .catch(console.log);
  };

  useEffect(() => {
    map?.attributionControl.remove();
    if (centered && bounds)
      map?.flyToBounds(bounds, {
        animate: false,
        padding: [50, 50],
        maxZoom: 8,
      });
  }, [map]);

  useEffect(() => {
    setCentered(false);
    if (companyState.highlighted && state.parentCollection.length > 0)
      handleSystems(companyState.highlighted);
  }, [companyState.highlighted, state.parentCollection]);

  useEffect(() => {
    setLoading(drawerState.animating);

    if (!map || drawerState.animating) return;

    map.invalidateSize();
  }, [drawerState.animating]);

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        calculateMapCenter();
      }, 200);
    }
  }, [loading]);

  useEffect(() => {
    calculateMapCenter();
  }, [plots]);

  useEffect(() => {
    if (bounds && !centered) {
      map?.flyToBounds(bounds, {
        duration: 0.5,
        padding: [50, 50],
        maxZoom: 8,
      });
      setCentered(true);
    }
    map?.invalidateSize();
  }, [bounds]);

  return (
    <GridCard loading={loading} icon="./icons/location.png" title="System Map">
      <MapContainer
        zoom={5}
        whenCreated={setMap}
        center={UnitedStatesCenter}
        scrollWheelZoom
        className={styles.mapContainer}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {!loading &&
          plots.map((plot, index) => <MapPlot key={index} plot={plot} />)}
      </MapContainer>
    </GridCard>
  );
};

const MapPlot: FunctionComponent<PlotProps> = (props) => {
  const [state, actions] = AppStore.systems.use();
  const [companies] = AppStore.companies.use();
  const history = useHistory();
  const coordinate = props.plot.coordinate;
  const [icon, setIcon] = useState(L.divIcon());
  const [system, setSystem] = useState<System | undefined>();
  const ref = createRef<any>();

  const handleSelected = (e) => {
    actions
      .getCachedParentCollection(companies.highlighted)
      .then((systems) => actions.setHighlighted(findSystem(systems)));
  };

  const toDashboard = () => history.push(Routes.System);

  const handleViewSystem = () => {
    // actions.setHighlighted(system);
    toDashboard();
  };

  const findSystem = (systems: System[]) => {
    return systems.find((s) => s.sid == props.plot.system.sid);
  };

  useEffect(() => {
    if (system) {
      const icon = L.divIcon({
        className: `${styles.mapPlot} ${
          system.isOnline() ? styles.online : styles.offline
        }`,
        iconSize: [30, 30],
      });

      setIcon(icon);
      ref.current.addEventListener("click", handleSelected);

      return () => {
        if (ref.current)
          ref.current.removeEventListener("click", handleSelected);
      };
    }
  }, [system]);

  useEffect(() => {
    if (state.highlighted && state.highlighted.id == system?.id)
      ref.current.openPopup();
    else ref.current.closePopup();
  }, [state.highlighted]);

  useEffect(() => {
    actions
      .getCachedParentCollection(companies.highlighted)
      .then(findSystem)
      .then(setSystem);
  }, [state.collection]);

  return (
    <Marker ref={ref} position={coordinate} icon={icon}>
      <Popup closeButton={false}>
        <div className={styles.plotPopUp}>
          <Typography lineHeight={1} variant="body2">
            SID: {system?.sid}
          </Typography>
          <Typography lineHeight={1} variant="body2">
            Health: {system?.health}
          </Typography>
          <Typography lineHeight={1} variant="body2">
            Latitude: {coordinate.lat} Longitude: {coordinate.lng}
          </Typography>
          <div className={styles.plotPopUpButtonContainer}>
            <Chip
              label="View"
              className={styles.viewSystemChip}
              variant="outlined"
              onClick={handleViewSystem}
              size="small"
              key={1}
            />
          </div>
        </div>
      </Popup>
    </Marker>
  );
};

export default MapElement;
