import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles';
import React, { FunctionComponent } from "react";

interface ProviderProps {}

// Utility to handle API calls and caching
const ThemeProvider: FunctionComponent<ProviderProps> = (props) => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#055D9C"
      }
    },
  });

  return <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>;
};

export { ThemeProvider };
