import React, { FunctionComponent } from "react";
import { AppStore } from "../../../../AppStore";
import {
  GridCardItem,
  GridCardSection,
  GridFormCard
} from "../../../../components/gridManager/components/GridCard";
import { FormatStringToDate } from "../../../../helpers/time";


interface Props {}

const SystemInfo: FunctionComponent<Props> = (props) => {
  const [state, actions] = AppStore.systems.use();

  const handleSubmit = (system) => {
    return new Promise((resolve, reject) => resolve) //SystemService.updateOne(system)
      // .catch(console.log);
  };

  const handleFormatDate = (date: string) => {
    const result = FormatStringToDate(date);

    return result;
  };

  return (
    <GridFormCard
      model={state.highlighted}
      icon="./icons/antena.png"
      title="System Info"
      onSubmit={handleSubmit}
    >
      <GridCardSection title="SID">
        <GridCardItem formElement="input" field="sid" />
      </GridCardSection>
      <GridCardSection title="Health">
        <GridCardItem formElement="input" field="health" />
      </GridCardSection>
      <GridCardSection title="Upload Frequency (seconds)">
        <GridCardItem formElement="input" field="primaryUploadFrequency" />
      </GridCardSection>
      <GridCardSection title="Diagnostic Frequency (seconds)">
        <GridCardItem formElement="input" field="diagnosticUploadFrequency" />
      </GridCardSection>
      <GridCardSection title="Last Uploaded">
        <GridCardItem  field="lastUploaded" valueModifier={handleFormatDate}  />
      </GridCardSection>
      <GridCardSection title="Date Created">
        <GridCardItem  field="dateCreated"  valueModifier={handleFormatDate}/>
      </GridCardSection>
    </GridFormCard>
  );
};

export default SystemInfo;
