import { Button } from "@mui/material";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../App";
import { AppStore } from "../../../AppStore";
import styles from "./styles/Components.css";

interface Props {
 
}

export const ToggleClientViewUtility: FunctionComponent<Props> = (props) => {
  const { location, push } = useHistory();
  const [clientView, setClientView] = useState(false);

  const handleToggle = () => {
    setClientView(!clientView)
  }

  useEffect(() => {
    push(clientView ? Routes.AdminSystem : Routes.Systems)
  }, [clientView])
  
  

  return (
      <Button
        className={styles.gridUtilityButton}
        variant="outlined"
        onClick={handleToggle}
      >
        {clientView ? "admin view" : "client view"}
      </Button>
  );
};
