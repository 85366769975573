import React, {
  Fragment,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { AppStore } from "../../../../AppStore";
import { DrawerItem } from "../../../../components/drawer/components/DrawerItem";
import { DrawerSection } from "../../../../components/drawer/components/DrawerSection";
import { DeleteUtility } from "../../../../components/gridManager/components/DeleteUtility";
import { RouterContext } from "../../../../providers/RouterProvider";
import { System } from "../../../../services/system/models/System";
import { SmallStatusIndicator } from "../../../shared/components/SystemStatus";
import SystemGrid from "../../../shared/layouts/Grid";
import { ToggleClientViewUtility } from "../../components/ToggleClientViewUtility";

interface Props {}

const Body: FunctionComponent<Props> = (props) => {
  const [, gridActions] = AppStore.grid.use();
  const [, companyActions] = AppStore.companies.use();
  const [state, actions] = AppStore.systems.use();
  const [, drawerActions] = AppStore.drawer.use();
  const [, deviceActions] = AppStore.devices.use();
  const [current, setCurrent] = useState<System>();
  const router = useContext(RouterContext);

  const [showWarning, setShowWarning] = useState(false);

  // const handleDeleteSystem = () => {
  //   actions
  //     .deleteHighlighted()
  //     .then(actions.updateCollection)
  //     .catch(console.log);
  // };

  const handleSelected = (system: any) => {
    actions.setHighlighted(system);
  };

  const updateDrawer = () => {
    companyActions.getCachedCollection().then((companies) => {
      actions.getCachedCollection().then((systems) => {
        const sections: DrawerSection[] = [];
        let highlighted: DrawerItem | undefined;
        const allSection = new DrawerSection();
        const allItems: DrawerItem[] = [];

        systems.forEach((system) => {
          const utilities: JSX.Element[] = [];
          const item = new DrawerItem();

          utilities.push(<SmallStatusIndicator system={system} />);
          item.primary = system.sid;
          item.secondary = system.health;
          item.model = system;
          item.children = utilities;
          item.onClick = handleSelected;

          allItems.push(item);
        });

        allSection.index = 0;
        allSection.name = "All";
        allSection.items = allItems;

        sections.push(allSection);

        companies.forEach((company, index) => {
          const section = new DrawerSection();
          const items: DrawerItem[] = [];

          section.index = index;
          section.name = company.name;

          const filtered = systems.filter((s) =>
            s.companyIds.includes(company.id)
          );

          filtered.forEach((system) => {
            const utilities: JSX.Element[] = [];
            const drawerItem = new DrawerItem();

            utilities.push(<SmallStatusIndicator system={system} />);
            drawerItem.primary = system.sid;
            drawerItem.secondary = system.health;
            drawerItem.model = system;
            drawerItem.children = utilities;
            drawerItem.onClick = handleSelected;

            items.push(drawerItem);

            if (state.highlighted && system.id == state.highlighted.id)
              highlighted = drawerItem;

            if (
              router.metadata["system"] &&
              router.metadata["system"].toLowerCase() ==
                system.sid.toLowerCase()
            )
              highlighted = drawerItem;

            if (!highlighted && !state.highlighted) {
              highlighted = drawerItem;
            }
          });

          section.items = items;

          sections.push(section);
        });

        if (highlighted) actions.setHighlighted(highlighted.model as System);

        drawerActions.setSections(sections);
        drawerActions.setHighlighted(highlighted);

        router.clearState();
      });
    });
  };

  useEffect(() => {
    setShowWarning(false);

    gridActions.clear();
    gridActions.initGrid(SystemGrid);
    gridActions.setUtilities([
      <ToggleClientViewUtility />,
      // <DeleteUtility
      //   requireConfirm
      //   onDelete={handleDeleteSystem}
      //   title={`Are you sure you want to delete this system?`}
      // />,
    ]);
  }, []);

  useEffect(() => {
    updateDrawer();
  }, [state.collection]);

  useEffect(() => {
    if (!state.highlighted) return;

    deviceActions.updateParentCollection(state.highlighted);

    companyActions.getCachedCollection().then((collection) => {
      const company = collection.find((company) =>
        state.highlighted?.companyIds.includes(company.id)
      );
      gridActions.setSecondaryTitle(company?.name);
    });
    gridActions.setPrimaryTitle(state.highlighted.sid);
    gridActions.setLoading(false);
  }, [state.highlighted]);

  return <Fragment></Fragment>;
};

export default Body;
