import { TextField, Menu, MenuItem } from "@mui/material";
import React, { FunctionComponent, useState } from "react";
import styles from "../styles/List.css";

interface Props {
  title: string;
  onSearch: (filter: string) => void;
}

const ListHeader: FunctionComponent<Props> = (props) => {
  const [ancorEl, setAncorEl] = useState(null);
  const [openFilter, setOpenFilter] = useState(false);

  const handleOpenFilter = (event) => {
    setAncorEl(event.currentTarget);
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
    setAncorEl(null);
  };

  return (
    <div className={styles.listHeader}>
      <TextField
        fullWidth
        variant="outlined"
        size="small"
        placeholder={`Search for ${props.title}...`}
        className={styles.itemSearchTextField}
        onChange={(event) => props.onSearch(event.target.value)}
      />
      {/* <img
        height={25}
        width={25}
        src="./icons/filter.png"
        onClick={handleOpenFilter}
      />
      <Menu
        anchorEl={ancorEl}
        open={openFilter}
        onClose={handleCloseFilter}
        onClick={handleCloseFilter}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem>Type</MenuItem>
      </Menu> */}
    </div>
  );
};

export default ListHeader;
