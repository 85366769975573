// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wKMAJhlKX37JEtFRC1yA {\n    border-color: white !important;\n  color: white !important;\n  height: 40px;\n}", "",{"version":3,"sources":["webpack://./src/areas/admin/components/styles/Components.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;EAChC,uBAAuB;EACvB,YAAY;AACd","sourcesContent":[".gridUtilityButton {\n    border-color: white !important;\n  color: white !important;\n  height: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gridUtilityButton": "wKMAJhlKX37JEtFRC1yA"
};
export default ___CSS_LOADER_EXPORT___;
