import { Button, Typography } from "@mui/material";
import React, { useContext } from "react";
import { AppStore } from "../../../AppStore";
import Loader from "../../../components/loader/Loader";
import { AuthenticationContext } from "../../../providers/AuthenticationProvider";
import styles from "../styles/LandingPage.css";

const Body = () => {
  const quoteStart = "Instrument Portal";
  const instruction1 = "At this time the data portal is only available for";
  const instruction2 = "PAMs, AQLites, and AQSyncs.";
  const instruction3 = "Please email techsupport@2btech.io with your";
  const instruction4 = "company name and the serial number(s) of your";
  const instruction5 = "instruments to register your PAM, AQLite or AQSync.";

  const { login } = useContext(AuthenticationContext);
  const [state, ] = AppStore.account.use();

  const handleOnClick = () => {
    login();
  };


  return (
    <div className={`${styles.body} "fade-in-container"`}>
      {state.submittingCredentials ? (
        <Loader />
      ) : (
        <>
          <div className={styles.columnLeft}>
            <img
              className={styles.logo}
              height={100}
              width={400}
              src="./icons/logo.jpg"
            />

            <Typography variant="h3" className={styles.title}>
              {quoteStart}
            </Typography>
            <Button
              size="large"
              variant="contained"
              className={styles.button}
              onClick={handleOnClick}
            >
              Login
            </Button>
          </div>
          <div className={styles.columnRight}>
            <div className={styles.disclaimerContainer}>
              <Typography variant="h5" className={styles.text}>
                {instruction1}
              </Typography>
              <Typography variant="h5" className={styles.text}>
                {instruction2}
              </Typography>
              <Typography variant="h5" marginTop={2} className={styles.text}>
                {instruction3}
              </Typography>
              <Typography variant="h5" className={styles.text}>
                {instruction4}
              </Typography>
              <Typography variant="h5" className={styles.text}>
                {instruction5}
              </Typography>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Body;
