import { Auth0Provider, IdToken, useAuth0 } from "@auth0/auth0-react";
import React, { FunctionComponent } from "react";
import { AppStore } from "../AppStore";
import { ENV } from "../env";
import { AccountService } from "../services/account/AccountService";
import { User, UserRole } from "../services/user/models/User";
import { ApiClient } from "../utils/ApiClient";
import { DefaultRoutes } from "./RouterProvider";

interface Props {
  bypass?: User;
}

const initialContext = {
  authenticated: false,
  logout: () => {},
  login: () => {},
  updateSession: (token: IdToken) => {},
};

const AuthenticationContext = React.createContext(initialContext);

const AuthenticationControls: FunctionComponent<Props> = (props) => {
  const {
    isLoading,
    getIdTokenClaims,
    logout,
    loginWithPopup,
    handleRedirectCallback,
  } = useAuth0();
  const [accountState, accountActions] = AppStore.account.use();

  const handleToken = (token: IdToken) => {
    if (!token) {
      if (!authContext.authenticated) return;

      return handleLogout();
    }

    ApiClient.addAuthentication(token.__raw);
    return token.sub ?? "";
  };

  const handleManualLogin = () => {
    accountActions.setLoggingIn(true);
    loginWithPopup()
      .then(handleLogin);
  };

  const handleLogin = () => {
    
    getIdTokenClaims()
      .then(handleToken)
      .then(handleUser)
      .catch((error) => console.log(error))
      .finally(() => {
        accountActions.setLoggingIn(false);
      });
  };

  const handleUser = (id: string) => {
    if (id == undefined) return;

    AccountService.getUserDetails(id)
      .then((user) => {
        accountActions.login(user);
        return user;
      })
      .then((user) => {
        setAuthContext({
          ...authContext,
          authenticated: true,
        });
        // console.log(`Authenticated user: ${user.name}`);
        // console.log(user);
      })
      .catch((error) => {
        console.log("Failed to retreive user information");
      });
  };

  const handleLogout = () => {
    accountActions.setLoggingOut(true);
    logout({ returnTo: window.location.origin + DefaultRoutes.Logout });
    setAuthContext({
      ...authContext,
      authenticated: false,
    });
  };

  const [authContext, setAuthContext] = React.useState({
    authenticated: false,
    login: handleManualLogin,
    logout: handleLogout,
    updateSession: handleToken,
  });

  React.useEffect(() => {
    if (props.bypass) {
      accountActions.login(props.bypass);
      setAuthContext({
        ...authContext,
        authenticated: true,
      });
      return;
    }
  }, []);

  React.useEffect(() => {
    if (!isLoading && !accountState.loggingIn) {
      accountActions.setLoggingIn(true);
      handleLogin();
    }
  }, [isLoading]);

  return (
    <AuthenticationContext.Provider value={authContext}>
      {props.children}
    </AuthenticationContext.Provider>
  );
};

const AuthenticationProvider: FunctionComponent<Props> = (props) => {
  return (
    <Auth0Provider {...ENV.auth0}>
      <AuthenticationControls {...props}>
        {props.children}
      </AuthenticationControls>
    </Auth0Provider>
  );
};

export { AuthenticationProvider, AuthenticationContext };
